import React from 'react'
import "../Assets/css/smallCard2.css";
import Fade from 'react-reveal/Fade';
import {Typography} from "@mui/material";


function SmallCard2(props) {
  return (<>
        {/*WRAPPING ALL IN A ROW*/}
            <div className="smcard2 mx-auto">
                <div className='imgcontainer'>
                    
                <Fade top>
                    {props?.icon?<div className='img'>{props.icon}</div>:
                    <img src={props.Img1} alt="dummy" />}
                </Fade>
                </div>

                <Typography variant={'h6'} style={{fontWeight:"bold"}}  className="bigtext2 d-flex align-items-center px-2 f1 justify-content-center">{props.head1}</Typography>
                {/* <p className="info">{props.text1}</p> */}
            </div>
           </> 
            
  )
}

export default SmallCard2
