import React, {useEffect} from 'react';
// import "../Components/Header.css";
import Card from '../Components/Card';
import SmallCard from '../Components/SmallCard';
import bankcard from "../Assets/img/VC.png";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import world from "../Assets/icons/InC.png";
import d$ from "../Assets/icons/D.png";
import piggy from "../Assets/icons/EN.png"
import LongText from '../Components/LongText';
import PageCards from "../Components/PageCards"
import stock from "../Assets/img/stock.png"
import PageText from '../Components/PageText';
import Footer2 from "../Components/Footer2";
import Footer3 from "../Components/Footer3";
import { useTranslation } from 'react-i18next';



function VictoiCard() {
  const {t} = useTranslation()

  return (
    <>
      <br/>
    <PageCards 
    img={bankcard}
    headtext={t("victoicard_victoicard_texttitle")}
    text={t("victoicard_1stsection_textbody")}
    />
    <PageText 
    headtext={t("victoicard_2stsection_texttiltle")}
    text={t("victoicard_2stsection_textbody")}/>
    <SmallCard 
        Img1={world} 
        Img2={d$} 
        Img3={piggy} 
        head1={t("victoicard_1stsmallbx_texttiltle")}
        head2={t("victoicard_2stsmallbx_texttiltle")}
        head3={t("victoicard_3rtsmallbx_texttiltle")}
        text1={t("victoicard_1stsmallbx_textbody")}
        text2={t("victoicard_2stsmallbx_textbody")}
        text3={t("victoicard_3rtsmallbx_textbody")} />
   
    </>
  
  )
}

export default VictoiCard
