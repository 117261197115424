import classes from '../KYCcomponent/docs.module.css'

import React, { useEffect, useState } from 'react'

export default function Disclaimer({ siteLanguage }) {
      const [data,setdata]=useState([])
      const englishdata = [
            {
                  head: "Disclaimer",
                  body: "<b>Victoi Inc (\"Victoi,\" \"we,\" \"us,\" or \"our\") </b>provides financial services and information for informational purposes only. As a leading fintech platform, we strive to offer valuable tools and resources. However, it is important to understand the scope and limitations of our services as outlined in this disclaimer. By accessing and using Victoi's services, users acknowledge and agree to the terms and conditions detailed herein. "
            },
            {
                  head: "Use of APIs ",
                  body: "Victoi leverages Application Programming Interfaces (APIs) to enhance our service offerings. While these APIs facilitate access to a range of financial services, it is crucial to note that their use does not imply our endorsement or guarantee of the services provided by third-party entities. We are not responsible for the content, accuracy, or reliability of third-party services accessed through our platform."
            },
            {
                  head: "No Investment Advice ",
                  body: "The content available on our website, including but not limited to financial data, analyses, and recommendations, is provided for general informational purposes. It should not be construed as personalized investment advice. Investment decisions made based on our content are solely at the user's discretion and risk. "
            },
            {
                  head: "User Responsibility",
                  body: "Users bear the responsibility for conducting independent research and due diligence prior to engaging in any financial transactions or investments based on the information provided by Victoi. We strongly encourage consulting with qualified financial advisors for personalized advice before making any investment decisions."
            },
            {
                  head: "No Liability ",
                  body: "Victoi Inc disclaims all liability for any reliance on the information available on our website or for actions taken based on such information. We are not liable for any financial losses or damages incurred as a result of using our services or relying on the information we provide."
            },
            {
                  head: "Consultation with Professionals ",
                  body: "We advise our users to seek guidance from licensed financial, legal, or other relevant professionals when considering significant financial decisions."
            },
            {
                  head: "Changes to Information and Services ",
                  body: "Our website's content and services may undergo changes without prior notice. We do not guarantee the accuracy, completeness, or timeliness of the information provided. "
            },
            {
                  head: "Errors and Omissions Disclaimer",
                  body: "Victoi Inc endeavors to ensure the reliability of the information presented on our website and mobile app, but we cannot guarantee its absolute accuracy or completeness. We provide this information \"as is,\" without any express or implied warranties, including warranties of performance, merchantability, or fitness for a particular purpose. Victoi Inc will not be liable for any decisions made or actions taken in reliance on this information, nor for any consequential damages arising therefrom."
            },
            {
                  head: "Contact Us",
                  body: "Victoi is committed to safeguarding user data in compliance with applicable data protection laws. However, the security of data transmitted over the internet cannot be entirely guaranteed. Users are encouraged to familiarize themselves with our Privacy Policy for further information on our data protection practices."
            },
            {
                  body: "For inquiries or concerns regarding this disclaimer or our services, please contact us at <a href='/support'>support@victoi.com.</a>"
            }

      ]
      // const englishdata = [
      //       {
      //             head: "Know Your Customer (KYC) ",
      //             body: "At Victoi, we are dedicated to adhering to the highest standards of legal and regulatory compliance, particularly concerning Know Your Customer (KYC) norms. Our commitment extends to ensuring the integrity of our financial transactions and maintaining the trust of our clients.",
      //             links: [
      //                   {
      //                         head: "Identity Verification",
      //                         body: "<b>Identity Verification:</b> To establish a relationship with Victoi, all customers must undergo a thorough identity verification process. This process requires customers to provide valid and current identification documents such as a National ID Card, Driver’s License, Resident Permit, or Passport. In certain cases, we may also require proof of address, such as utility bills or bank statements."
      //                   },
      //                   {
      //                         head: "Identity Verification",
      //                         body: "<b>Reporting Obligations:</b> Any suspicious activities will be reported to the appropriate legal authorities as per the mandates of US and international AML regulations. This is a critical part of our role in preventing illicit financial activities."
      //                   },
      //                   {
      //                         head: "Identity Verification",
      //                         body: "<b>Customer Cooperation:</b> We expect full cooperation from our customers in our AML efforts. This may include requests for additional documentation or information pertaining to certain transactions."
      //                   },
      //                   {
      //                         head: "Identity Verification",
      //                         body: "<b>Service Refusal and Suspension:</b> Victoi reserves the right to refuse or suspend services to any customer if there is a reasonable suspicion of illegal or fraudulent activities. "
      //                   },
      //                   {
      //                         head: "Identity Verification",
      //                         body: "<b>Record Keeping:</b> In compliance with AML regulations, we maintain detailed records of all customer transactions and verifications. These records are kept secure and confidential, in accordance with our privacy policy and legal requirements."
      //                   },
      //             ]
      //       },
      //       {
      //             head: "Anti-Money Laundering (AML) ",
      //             body: "Victoi is unwavering in its commitment to combatting money laundering, terrorist financing, and other illegal financial activities. ",
      //             links: [
      //                   {
      //                         body: "<b>Transaction Monitoring:</b> We closely monitor all customer transactions for signs of suspicious activity. This includes analyzing transaction patterns, investigating unusual or large transactions, and identifying transactions that do not conform to the customer’s typical business profile. "
      //                   },
      //                   {
      //                         body: "<b>Reporting Obligations:</b> Any suspicious activities will be reported to the appropriate legal authorities as per the mandates of US and international AML regulations. This is a critical part of our role in preventing illicit financial activities. "
      //                   },
      //                   {
      //                         body: "<b>Customer Cooperation:</b> We expect full cooperation from our customers in our AML efforts. This may include requests for additional documentation or information pertaining to certain transactions."
      //                   },
      //                   {
      //                         body: "<b>Service Refusal and Suspension:</b> Victoi reserves the right to refuse or suspend services to any customer if there is a reasonable suspicion of illegal or fraudulent activities."
      //                   },
      //                   {
      //                         body: "<b>Record Keeping:</b> In compliance with AML regulations, we maintain detailed records of all customer transactions and verifications. These records are kept secure and confidential, in accordance with our privacy policy and legal requirements. "
      //                   }
      //             ]
      //       },
      //       {
      //             head: "Your Role in Compliance",
      //             body: "Our customers play a crucial role in maintaining the integrity of our financial ecosystem. We encourage you to remain vigilant and report any suspicious activities or concerns regarding financial transactions. Together, we can ensure a secure, transparent, and compliant financial environment.<br/>For more information or to report a concern, please contact our compliance team at compliance@victoi.com."
      //       },
      //       {
      //             head: "Continual Improvement",
      //             body: "Victoi is committed to regularly reviewing and updating its KYC and AML policies to align with evolving regulations and best practices in the industry. We strive to set the standard for compliance and security in financial services, ensuring a safe and trustworthy platform for all our users."
      //       }
      // ]
      const frenchdata = [
            {
                  head: "Avertissement Juridique ",
                  body: "<b>Victoi Inc (\"Victoi\", \"nous\", \"notre\") </b>offre des services et informations financiers à des fins informatives uniquement. En tant que plateforme fintech leader, nous nous efforçons de fournir des outils et ressources de valeur. Cependant, il est important de comprendre la portée et les limites de nos services comme décrits dans cet avertissement. En accédant et utilisant les services de Victoi, les utilisateurs reconnaissent et acceptent les termes et conditions détaillés ici.  "
            },
            {
                  head: "Utilisation des API",
                  body: "Victoi utilise des Interfaces de Programmation d'Applications (API) pour améliorer nos offres de services. Bien que ces API facilitent l'accès à une gamme de services financiers, il est crucial de noter que leur utilisation n'implique pas notre approbation ou garantie des services fournis par des tiers. Nous ne sommes pas responsables du contenu, de l'exactitude ou de la fiabilité des services de tiers accessibles via notre plateforme. "
            },
            {
                  head: "Absence de Conseil en Investissement",
                  body: "TLe contenu disponible sur notre site web, y compris mais sans s'y limiter, les données financières, analyses et recommandations, est fourni à des fins d'information générale. Il ne doit pas être interprété comme un conseil en investissement personnalisé. Les décisions d'investissement prises sur la base de notre contenu sont exclusivement à la discrétion et aux risques de l'utilisateur."
            },
            {
                  head: "Responsabilité de l'Utilisateur ",
                  body: "Les utilisateurs sont responsables de mener leurs propres recherches et diligences avant de s'engager dans des transactions financières ou des investissements basés sur les informations fournies par Victoi. Nous encourageons fortement à consulter des conseillers financiers qualifiés pour des conseils personnalisés avant de prendre toute décision d'investissement."
            },
            {
                  head: "Non-responsabilité",
                  body: "Victoi Inc décline toute responsabilité pour toute confiance accordée aux informations disponibles sur notre site web ou pour les actions entreprises sur la base de ces informations. Nous ne sommes pas responsables des pertes financières ou dommages subis suite à l'utilisation de nos services ou en se fiant aux informations que nous fournissons."
            },
            {
                  head: "Consultation avec des Professionnels",
                  body: "Nous conseillons à nos utilisateurs de solliciter les conseils de professionnels financiers, juridiques ou d'autres experts pertinents lors de la prise de décisions financières importantes."
            },
            {
                  head: "Changements dans les Informations et Services ",
                  body: "Le contenu et les services de notre site web peuvent subir des changements sans préavis. Nous ne garantissons pas l'exactitude, l'exhaustivité ou l'actualité des informations fournies."
            },
            {
                  head: "Clause de Non-responsabilité pour Erreurs et Omissionsr",
                  body: "Victoi Inc s'efforce de garantir la fiabilité des informations présentées sur notre site web et application mobile, mais nous ne pouvons pas garantir leur exactitude ou exhaustivité absolues. Nous fournissons ces informations \"telles quelles\", sans aucune garantie expresse ou implicite, y compris les garanties de performance, de commercialisation ou d'adéquation à un usage particulier. Victoi Inc ne sera pas responsable des décisions prises ou des actions entreprises en se fiant à ces informations, ni des dommages consécutifs résultant de celles-ci. ."
            },
            {
                  head: "Contactez-Nous",
                  body: "Victoi s'engage à protéger les données des utilisateurs conformément aux lois et règlements applicables en matière de protection des données. Cependant, la sécurité des données transmises sur Internet ne peut être entièrement garantie. Nous encourageons les utilisateurs à se familiariser avec notre Politique de Confidentialité pour plus d'informations sur nos pratiques de protection des données. "
            },
            {
                  body: "Pour toute question ou préoccupation concernant cet avertissement ou nos services, veuillez nous contacter à <a href='/support'>support@victoi.com.</a>"
            }

      ]
      useEffect(()=>{
            setdata(
                  siteLanguage=="ENGLISH"?englishdata:frenchdata
            )
      },[siteLanguage])
      return (
            <div className={classes.container + " mx-auto"}>
                  <h2 className='text-center'><b>{siteLanguage=="ENGLISH"?"Policy":"Avertissement"}</b></h2>
                  {data.map((data) =>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>{data?.head}</div>
                              <div className='f00 my-2' dangerouslySetInnerHTML={{ __html: data?.body }}></div>
                              {data.links && <>
                                    <ul className='f00 mx-3'>
                                          {data.links.map((link) => <li className='my-4 f00' dangerouslySetInnerHTML={{ __html: link.body }}></li>)}
                                    </ul>
                              </>}
                              {/* hello world href */}
                        </div>
                  )}
            </div>
      )
}
