import React from 'react'
import "../Assets/css/pagetext.css"
import Fade from 'react-reveal/Fade'
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function PageText({headtext, text,}) {
    const {t} = useTranslation()
  return (
    <div className={'main4'}>

        <div className=" container ">
                <div className="container4 ">
                    <div className="p-text4">
                        <Fade up>
                        <h1 style={{fontWeight:"bold"}}>
                            {headtext}
                        </h1>
                        <p style={{color:'#98979d'}}>
                            {text}
                        </p>

                        <button className="pagebtn"><Link to={"/Contact"} style={{textDecoration:"none",color:"#050719"}}>{t("request_early_access")}</Link> </button>
                        </Fade>

                    </div>
                
                </div>
            </div>

            
    </div>
  )
}

export default PageText
