import { useEffect } from 'react'
import { randomString } from '../../Pages/Extrapages/Support'
import classes from './expandible.module.css'
import React, { useState } from 'react'

export default function Expandible({reset,setreset, search, icon, header, body }) {
      const [expanded, setexpanded] = useState(header?.trim()==search?.trim())
      const [id,setid] = useState(randomString(10))
      const [onetime,setonetime] = useState(false)
      const [opened,setopened] = useState(false)
     
      
      function toogle() {
            console.log(reset)
            // if(!expanded){
            //      setreset(true)
            // }else{
            //       setreset(false)
            // }
            if(!expanded){
                  // alert('hello')
                  setreset(id)
                  // setreset(true)
                  setexpanded(true)
            }else{
                setopened(true)  
            // setreset('')
            setexpanded(!expanded)
            }
            // setopened(!expanded)
      }
      useEffect(()=>{
            if(reset==id&&expanded){
            //       setexpanded(false)
                  setopened(true)
                  // setreset('')
                 
            }
            else{
                  if(!expanded && (header?.trim()==search?.trim()) && reset!=id && !onetime){
                        setopened(true)
                        setexpanded(true)
                        setreset(id)
                        setonetime(true)
                  }else{
                        // setreset(id)
                  setexpanded(false)
                  setopened(false)
                  }
            }

      },[reset,expanded])
      useEffect(()=>{
            // setid(randomString(10))
            // setTimeout(() => {
                  
            // setopened(header?.trim()==search?.trim())
            // }, 1000);
            let val = header?.trim()==search?.trim()
            // setopened(val)
            // setreset(val)
            // setexpanded(val)
            
            if((header?.trim()==search?.trim()) ){
                  setopened(true)
                  setexpanded(true)
                  setreset(id)
                  // alert('hello')
            }
            // if(search){
            //       // console.clear()
            // console.log(search)
            // console.log(header)
            // console.log(opened)
            // }
      },[search])
      return (<>
            <div className={classes.container + " d-flex align-items-center  py-2"}>
            {/* {icon &&

<img className='mx-2  my-1' onClick={toogle} style={{ width: "20px",height:"25px",position:"relative" }} src={icon} />} */}
                  <div class="accordion accordion-flush w-100 i mx-4" style={{backgroundColor:"inherit"}} id={id} >
                        <div class="accordion-item "  style={{backgroundColor:"inherit"}} >

                              <h2 class="accordion-header  f2 m-0" onClick={toogle} style={{backgroundColor:"inherit"}}  id={id}>
                                    <div class={"f1 d-flex bold  collapsed show align-items-center "+(opened?" show ":"")} style={{backgroundColor:"inherit !important",color:"white",position:"relative"}} type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne"+id} aria-expanded="true" aria-controls="flush-collapseOne">
                                          
                                                {icon &&

                                                      <img className={((opened)?classes.rotate:" " )+' mx-4  my-1'} onClick={toogle} style={{ width: "20px",height:"25px",position:"relative" }} src={icon} />}
                                          
                                           <div className=" text-left bold" style={{backgroundColor:"black !important",textAlign:"left",fontWeight:"700"}}>{header}</div>
                                    </div>
                              </h2>
                              {/* <div id={"flush-collapseOne"+id} class="accordion-collapse  " aria-labelledby={id} data-bs-parent={"#"+id} style={{color:"white"}}>
                                    <div class="accordion-body text-muted ms-4"> {!expanded && <div className=' col  text-start p-0 ' >
                                    {body ? body :
                                          "body text goes here and here and here and here and here and here"}
                              </div>}</div>{!expanded && <div className='   text-start p-0 ' > ...
                        </div>}
                              </div> */}
                              <div id={"flush-collapseOne"+id} class={"accordion-collapse collapse "+(opened?" show ":"")} aria-labelledby={id} data-bs-parent={"#parentContainer"} style={{color:"white"}}>
                                    <div class="accordion-body text-muted ms-5">{body}</div>
                              </div>
                        </div>
                  </div>
            </div>
      </>)
      return (
            <div className={classes.container + " d-flex align-items-start py-2"}>
                  <div className=''>
                        {icon &&

                              <img className='mx-2' onClick={toogle} style={{ width: "30px" }} src={icon} />}
                  </div>
                  <div>

                        <div className='d-flex w-100 ' onClick={toogle} style={{ cursor: "pointer" }}>


                              <div className='w-100'>{header ? header : ""}</div>

                        </div>
                        <div className={expanded?' overflow-hidden text-muted up':"overflow-hidden text-muted down"}>
                              {/* {!expanded && <div className=' col  text-start p-0 ' >
                                    {body ? body :
                                          "body text goes here and here and here and here and here and here"}
                              </div>} */}
                              <div className={expanded?' overflow-hidden text-muted up':"overflow-hidden text-muted down"}>

                                    {body ? body : "body text goes here and here and here and here and here and here"}
                              </div>

                        </div>
                        {!expanded && <div className='   text-start p-0 ' > ...
                        </div>}
                  </div>


            </div>
      )
}
