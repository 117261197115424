import React, { useEffect } from 'react';
// import useFetch from '../Components/ApiClient';
// import showMoreItems from "../Components/ApiClient"
import axios from 'axios';
import { useState } from 'react';
import { Container, FormControl, InputGroup } from 'react-bootstrap';



function Vidoes() {


    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(3);
    const [search, setSearch] = useState("");

    useEffect(() => {
       axios.get("http://localhost:80/victoi/endPoints.php/?=1")
      .then(res => console.log(res));
    }, []);
   
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3)
    }

    console.log(search);
    
  return (
    <div>
        <Container>
            <InputGroup>
                <FormControl 
                onChange={(event) => setSearch(event.target.value)}
                placeholder='search content'/>
            </InputGroup>
            {items.filter((item) => {
                return search.toLowerCase() === "" ? item : item.body.toLowerCase()
                .includes(search);
               })
               .slice(0, visible)
                .map((item) => (
                <div className='card'>
                    <div className='id'>
                        <spam>{item.id}</spam>
                    </div>
                    <p>{item.body}</p>
                </div>
            ))}
            <button onClick={showMoreItems}>show more</button>
        </Container>
        
    </div>
    
  );
};

export default Vidoes


// https://jsonplaceholder.typicode.com/posts