import { Cancel, CancelOutlined, Check, CheckBox, CheckBoxRounded, CheckCircle, CheckCircleOutline, CheckRounded, Close, CloseOutlined, CloseRounded, RadioButtonUnchecked } from '@mui/icons-material'
import classes from './style.module.css'
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";// import Checkbox from '@material-ui/core/Checkbox';



const languages = [
      { name: "ENGLISH" },
      { name: "FRENCH" },
]

export default function SelectLanguage({ cookies,setSitelanguage,saveLanguage,setfirstInit }) {
      const { t, i18n } = useTranslation()

      const changeLanguageHandler = (lang) => {
            i18n.changeLanguage(lang)
      }
      const LanguageComponent = ({ name }) => {
            return <>
                  <div onClick={() => { initializeUserLanguage(name) }} className={classes.languageContainer} key={name}>
                        

                        <div className={classes.languageBox}>
                              <div>{name=="FRENCH"?"Français".toUpperCase():name}</div>
                              <div className={classes.tick}>{cookies.language == name ?<><Check/> </>: ""}</div></div>
                  </div>
            </>
      }
      const initializeUserLanguage = (n) => {
            setSitelanguage(n);
            changeLanguageHandler(n);
            saveLanguage(n);setfirstInit(false)
      }

      return (
            <>
                  <div className={classes.container}>
                        <div className={classes.optionsContainer}>
                              <div className={classes.headText +" w-100  d-flex"}><Cancel onClick={()=>setfirstInit(false)} className='ms-auto me-3'/></div>
                              {/* <div className={classes.languageContainer}>                             
                                    <CheckCircleOutline /><RadioButtonUnchecked />
                                    <div className={classes.languageBox}>hello</div>
                              </div> */}
                              {languages.map((l) =>
                                    <LanguageComponent name={l.name} />

                              )}
                        </div>

                  </div>
            </>
      )
}