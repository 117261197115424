import React, {useEffect, useState} from 'react';
import "./extrapageCss/Contact.css";
import Footer from '../../Components/Footer';
import { Container } from 'react-bootstrap';
import Header from "../../Components/Header";
import Footer3 from "../../Components/Footer3";
import Footer2 from "../../Components/Footer2";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { notify, success } from '../../Components/toaster/toaster';
import { Toaster } from 'react-hot-toast';

function fixstr(str){
    return str.trim().replaceAll("\\", "\\\\").replaceAll("'", "\\'").replaceAll("\"", "\\\"")
     
}
function Contact() {
    const {t} = useTranslation()
    const [userform,setuserform] = useState({
        name:'',
        email:'',
        contact:'',
        message:''
    })
    
    const [submiting,setsubmiting] = useState(false)
    function clearForm(){
    window.location.reload()
    }

        const form = useRef();
 async   function submitform(e){
        e.preventDefault()
    setsubmiting(true)
    setTimeout(() => {
        setsubmiting(false)
    }, 4000);

        let cform = document.getElementById('cform')
        // alert('submiting form')
  //  http://localhost/apiv/test/email/?email_from=from&email_to=to&content_header=header&content_footer=footer&content=this%20is%20content - add notification
    const url = "https://victoi.com.eternalcompanyltd.com/email/"
    // let url = 'http://localhost/apiv/test/email/'
//         alert('submiting form')
//         e.preventDefault()
        let formData = new FormData()


    // formData.append('proxy_server_id', proxy_server_id)
    formData.append('email_from', fixstr(userform?.email))
    formData.append('email_to', 'yanni@victoi.com')
    formData.append('content_header', 
    `From victoi website, new subscriber
    `
    )
    formData.append('content_footer', 
    ''
    )
    formData.append('content', 
    
    `
    A user with name  ${fixstr(userform?.name)}, email ${fixstr(userform?.email)} and conctact ${fixstr(userform?.contact)} has 
    subscribed to our platform with message content:- ${fixstr(userform?.message)}`
    )

    let data = new URLSearchParams(formData).toString()
    console.log(url + "?" + data)
    try {
      const response = await fetch(
        url,
        {
          method: "post",
          body: formData
        }
      );
      const response_1 = await response.text();
      setsubmiting(false)

      console.log(response_1)
      if(response_1.includes('success')){
        success('Email sent')
        setTimeout(() => {
            clearForm()
        }, 4000);
        
      } else{
        notify('failed to send email',4000)
      }
    }catch (e) {
        setsubmiting(false)
                notify(
                    'failed to send email, review your form or check your internet connection'
                )
                console.log('failed to send email',e)
        

        }
        return 
        emailjs
        .sendForm('service_h9xtzr6', 'template_f9s89dd', form.current, {
          publicKey: 'yNUtPYstJdcQoJh',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            alert('message sent')
            window.location.reload()
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
        
        // console.log(new Uri)
       
        // cform.submit()
    }
    function prevd(e){
        alert("form submited")
        e.preventDefault()

    }


  return (
    
    <>
    <Container>
        <section className="d-flex contact ">
            <div className="cont" style={{alignContent:'center'}}>
                <div className="text">
                    <h1>{t("contact_firstSection_textTitle")}</h1>
                    <p>{t("contact_firstSection_textBody")}</p>
                </div>
                <form ref={form} onSubmit={submitform} className="contact-form" id='cform'>
                    <div className="row">
                        <div className="col-12">
                            <label for="fname">{t("contact_secondSection_name")}*</label><br/>
                            <input type="text" onChange={(e)=>setuserform({...userform,name:e.target.value})} id="fname" name="user_name" placeholder={t("contact_placeholders_name")+"......"} className=" inp form-control form-control-sm" required/>
                        </div>
                    </div>
                    <div className="row mobile">
                        <div className="col-6">
                            <label for="number">{t("contact_secondSection_number")}*</label><br/>
                            <input type="text" id="fname" onChange={(e)=>setuserform({...userform,contact:e.target.value})}  name="user_contact" placeholder={t("contact_placeholders_number")+"...."}
                                className="inp form-control form-control-sm" required/>
                        </div>
                        <div className="col-6">
                            <label for="email">{t("contact_secondSection_emailAddr")}*</label><br/>
                            <input type="email" id="email" onChange={(e)=>setuserform({...userform,email:e.target.value})}  name="user_email" required placeholder={t("contact_placeholders_emailAddr")+"....."} className=" inp form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label for="comment">{t("contact_secondSection_message")}*</label><br/>
                            <textarea className=" textarea form-control form-control-sm" onChange={(e)=>setuserform({...userform,message:e.target.value})}  required  rows="5" cols="40" id="comment" name="message" placeholder={t("contact_placeholders_message")+"....."} ></textarea><br/>
                        </div>
                    </div>
                <div className="submitbtn">
                   {!submiting ? <input type="submit" className="vbtn" value={t("contact_secondSection_submit")}/>
                    :<div type="submit" className="vbtn" ><CircularProgress/></div>}
                </div>
                </form>

            </div>
        </section>
        <Toaster/>
    </Container>
    
    </>
  )
}

export default Contact

