import React from 'react';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';


// type toastProps = {
//     background: any
//     color: any
//     message: string
// }


export const notify = (content,duration) => toast.error(content || "Hello world",

{
    duration: duration || 3000,

    // Styling
    style: {
        backgroundColor:"var(--transparent-red)",
        color:"red",
        width: "100%",
        fontSize: "1.2em",
        opacity: "0.5",
        fontWeight:"bold",
        textAlign: 'center'
    },
    className: 'global-toast',
  
    ariaProps: {
        role: 'status',
        'aria-live': 'polite',
    },
}
)

export const success = (content,duration) => toast.success(content ||"Success",
{
    duration: duration || 3000,
    style: {
        backgroundColor:"var(--main-green)",
        color:"var(--main-blue)",
        width: "100%",
        fontSize: "1em",
        opacity: "0.5",
        textAlign: 'center',
        
    },
    className: 'global-toast',

    // Custom Icon
// Change colors of success/error/loading icon
    iconTheme: {
        primary:"var(--main-blue)",
        secondary: 'var(--main-green)',
        // width: "50px",
        // h
        // style={{ width: '50px', height: '50px' }}
    },

    // Aria
    ariaProps: {
        role: 'status',
        'aria-live': 'polite',
    },
}
)

export const docuploadError = () => toast.error("To confirm the registration of the company and the identity of its owner, specific documents are required",
{
    duration: 3000,
    // Styling
    style: {
        backgroundColor:"red",
        color:"var(--white-text)",
        width: "100%",
        fontSize: "4vw",
        opacity: "0.5",
        textAlign: 'center'
    },
    className: 'global-toast',

    // Aria
    ariaProps: {
        role: 'status',
        'aria-live': 'polite',
    },
}
)


const Toast =()=>{
    //toast.type can take success, error, infos.
    
    return (
        <div style={{margin:"20%"}}>
            <button onClick={notify}>Make me a toast</button>
            <Toaster  />
        </div>
    );


}
export default Toast;