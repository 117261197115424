
import classes from "../../Assets/css/LearningCenter.module.css"

import SearchIcon from '@mui/icons-material/Search';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Search } from "../../Components/search";



function LearningCenter() {
      const Card = () => {
            return (
                  <div className={classes.card}>
                        <img src="https://victoi-00.web.app/static/media/car-racing.e2a6faa5490e0c87f1a5.jpg" />
                        <div className={classes.duration}><PlayCircleOutlineIcon style={{ color: "yellowgreen", fontSize: "1em", margin: "auto 3px" }} />4mins - Jan 22</div>

                        <div className={classes.overlay}>
                              <div className={classes.overlayHead}>MY FIRST CRYPTO</div>
                              <div className={classes.overlayBody}>A master class on blockchain

                              </div>
                              <div className={classes.overlayTime}>4 mins-Jan 22</div>
                        </div>
                  </div>

            )
      }
      return (
            //                     --------- REMOVE BACKGROUND AND ADJUST MARGIN ------
            <div>
                  <div className={classes.container} style={{ margin: "100px 0", color: "white" }}>
                        <div className={classes.section1}>

                              <div className={classes.headerSection}>
                                    <div className={classes.headerSecHead}>
                                          LEARNING CENTER
                                    </div>
                                    <div className={classes.headerSecText}>
                                          Everything you need to know about crypto and more
                                    </div>
                              </div>

                              <div className={classes.imageSection}>
                                    <div className={classes.imageContainer}>
                                          <img src="https://victoi-00.web.app/static/media/car-racing.e2a6faa5490e0c87f1a5.jpg" />

                                          <div className={classes.new}>NEW</div>
                                          <div className={classes.overlay}>
                                                <div className={classes.overlayHead}>MY FIRST CRYPTO</div>
                                                <div className={classes.overlayBody}>We understand that crypto can be hard
                                                      at first especially for beginner. We put together
                                                      short, easy to understand tutorials to help
                                                      make is easier
                                                </div>
                                                <div className={classes.overlayTime}>4 mins-Jan 22</div>
                                          </div>
                                    </div>

                              </div>

                              <Search />



                        </div>
                        <div className={classes.section2}>
                              <div className={classes.cardsContainer}>
                                    <Card />
                                    <hr />
                                    <Card />
                                    <hr className={classes.hr} />
                                    <Card />
                                    <hr className={classes.hr} />
                                    <Card />
                                    <hr className={classes.hr} />
                                    <Card />
                                    <hr className={classes.hr} />
                                    <Card />
                              </div>
                        </div>
                        <div className={classes.viewMore} style={{ color: '#8fff00' }}>view more</div>

                  </div>
            </div>
      )
}
export default LearningCenter;