import React from 'react';
import barcode from"../Assets/icons/QRCode.png";
import apple from "../Assets/icons/Apple.png";
import android from "../Assets/icons/Google.png";
import happy from "../Assets/img/homeImage.png"
import homeImage from "../Assets/img/homeImage.png"
import {Box, Container, Typography} from "@mui/material";
import 'bootstrap/dist/css/bootstrap.css';
import Fade from 'react-reveal/Fade';
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import "../Assets/css/firstcard.css";
import { useTranslation, initReactI18next } from "react-i18next";


function FirstCard() {
  const { t } = useTranslation();

  return (

    <>
        <Box style={{paddingTop:'27%',marginBottom:'15%',paddingLeft:'10%'}} className={'cont-class container-fluid' }>
            <Box className={'row'}>
                <Box className={'col-md-8 text-section'}>
                    <Typography className={'main-text my-3 h6'} variant={'h3'} style={{fontWeight:'bold'}}>{t('homepage_section1_textTitle')}</Typography>
                    <Typography className=' my-3' variant={'p'} style={{color:'#98979d'}}>{t("homepage_section1_textBody")}</Typography>
                    <Box className={'row mt-3'}>

                        <Box className={'col-7'} style={{width: '100%'}}>

                        <Box className="d-flex flex-row ">
                            <Box className={'col-2'} style={{display:"none"}}>
                                <img width={'100%'}  src={barcode} alt="barcode" />
                            </Box>
                            <Box className={'col-6'} style={{minWidth: "190px"}}>
                                <Box className={"first-card-text"} style={{marginTop:"10px"}}>
                                    <NavLink to={"/Contact"}> <Button style={{backgroundColor:'var(--green)', color:'#050719'}}>{t("homepage_section1_button")}</Button></NavLink>
                                </Box>
                                <Box className="d-flex flex-row"  >
                                    <Box className="p-2" style={{display:"none"}}><img src={apple} width={'100%'} alt="apple" /></Box>
                                    <Box className="p-2" style={{display:"none"}}><img src={android} width={'100%'} alt="Google play store" /></Box></Box>

                              </Box>



                        </Box>
                    </Box>


                    </Box>
                </Box>

                <Box className={'col-6 imgSection'} style={{position:'absolute',left:'46%', top:'15%'}}>
                {/* <Box className={'col-6 imgSection'}  style={{width: '50%',position:'absolute',left:'46%', top:'15%'}}> */}
                    <Fade top>
                        <img src={homeImage} className={'img'}  width={'90%'} alt="happy people" />
                    </Fade>

                </Box>

            </Box>
        </Box>

        {/*

            <Box sx={{marginBottom:"5%"}} className="container-fluid container5">
                <div   className="f<Typography variant={'p'} style={{color:'#98979d'}}>We bridge borders and facilitate payments</Typography>irstext row homeText">
                    <Box className=" col htext5"><Typography variant={'h1'} style={{fontWeight:'bold'}}>JOIN THE REVOLUTION</Typography>
                        <Box className="ptext5"></Box>
                        <Box className="applink">
                            <div>
                                <img className="barcode" src={barcode} alt="barcode" />
                            </div>
                            <div className="appstore">
                                <Typography variant={'p'} className={'show'} style={{color:'#98979d',fontWeight:"bold"}}>Request early access</Typography>
                                <div className="apps">
                                    <img src={apple} alt="apple" />
                                    <img src={android} alt="Google play store" />
                                </div>
                            </div>
                        </Box>
                    </Box>
                </div>
                <Box  className="firstimg col ">
                    <div className={'mainImg'} >
                        <Fade top>
                            <img src={homeImage} className={'img-fluid'}  alt="happy people" />
                        </Fade>

                    </div>

                </Box>
            </Box>
            */}



    </>
    
  )
}

export default FirstCard
