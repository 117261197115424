import "./extrapageCss/A-video-thumbnail.css"
import racing from "../../Assets/img/car-racing.jpg";
import racing1 from "../../Assets/img/car-racing.jpg";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function VideoThumbnails() {
    return (<>
        <div className="A-video-thumbnail-container container-fluid">
            <div className="A-top-heading">LEARNING CENTER</div>
            <div className="A-top-txt">Everything you need to know about crypto and more</div>
            <div className="A-main-video container">
                <img src={racing} alt="car racing" className="car-racing" />
                <div className="A-new">NEW</div>
                <div className="A-caption container-fluid">
                    <div className="A-caption-head">MY FIRST CRYPTO</div>
                    <div className="A-caption-txt">
                        We understand that crypto can be hard especially for beginners. We put together short, easy to
                        understand tutorials to make it easy
                    </div>
                    <div className="A-caption-duration">Duration goes here</div>
                </div>
            </div>
            <div className="A-input-field">
                INPUT FIELD GOES HERE
            </div>
            <div className="container-fluid">
                <div className="A-desktop-thumbnail-collection container-fluid">
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    
                </div>
                <div className="A-mobile-thumbnail-collection container-fluid">
                    <div className="A-thumbnail row">
                        <div className="A-image">
                            <img src={racing1} alt="car racing" className="car-racing" />
                            <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="A-thumbnail row">
                        <div className="A-image">
                             <img src={racing1} alt="car racing" className="car-racing" />
                             <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="A-thumbnail row">
                        <div className="A-image">
                            <img src={racing1} alt="car racing" className="car-racing" />
                            <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="A-thumbnail row">
                        <div className="A-image">
                            <img src={racing1} alt="car racing" className="car-racing" />
                            <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="A-thumbnail row">
                        <div className="A-image">
                            <img src={racing1} alt="car racing" className="car-racing" />
                            <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="A-thumbnail row">
                        <div className="A-image">
                            <img src={racing1} alt="car racing" className="car-racing" />
                            <div className="A-duration">Duration goes here</div>
                        </div>
                        <div className="A-txt">
                            <div className="A-caption-head">WHAT IS BLOCKCHAIN</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                        </div>
                    </div>
                </div>
                {/*WHEN THE USER CLICKS THE LINK BELOW BELOW, IT SHOULD NOT CHANGE THE OVERFLOW VALUE OF THE
                ABOVE CONTAINER, IT SHOULD SIMPLY CHANGE THE HEIGHT OF THE CONTAINER TO 100%, HIDE THE "view more" 
                link, AND CHANGE THE DISPLAY OF THE "Back to top" button TO BLOCK */}
                <div className="view-more">View more videos</div>

                {/* WHEN THE USER CLICKS THE BUTTON BELOW, IT SHOULD SIMPLY CHANGE THE HEIGHT OF THE CONTAINER BACK TO 60vw,
                , HIDE THE "Back to top" button, AND DISPLAY THE "view more" link AGAIN */}
                <button type="button">Back to top</button>

            </div>
        </div>
    </>)
}