import { useState } from "react"
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import fb from '../../../Assets/icons/FB.png'
import wh from '../../../Assets/icons/W.png'
import tg from '../../../Assets/icons/TG.png'
import m from '../../../Assets/icons/MA.png'
import "./style.css"
import classes from './blog.module.css'
import { CopyAll, Facebook, Instagram, IosShare, Mail, NavigateBefore, NavigateNext, Share, Twitter, WhatsApp } from "@mui/icons-material"
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect } from "react"
import { Blog2 } from "../blogs"
import Helmet from 'react-helmet'
import {
      EmailShareButton,
      FacebookShareButton,

      InstapaperShareButton,

      TelegramShareButton,

      WhatsappShareButton,
} from "react-share";
export

      function removeTags(str) {
      if ((!str) || (str === ''))
            return '';
      else
            str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '');
}
export default function Blogs() {
      const navigate = useNavigate()
      const [reqblog, setreqblog] = useSearchParams()
      const [dialogstate, setDialogstate] = useState(false)
      const [blogs, setblogs] = useState([])
      const [position, setposition] = useState()
      const [selectedblog, setselectedblog] = useState()
      function toggleDialog() {
            setDialogstate(!dialogstate)
      }
      if (!useLocation().state) {
            if (reqblog.get('blog')) {

                  // alert("blogid: "+reqblog.get('blog'))
                  navigate("/blog", { replace: false, state: { blogid: reqblog.get('blog') } })
            } else {

                  navigate("/blog", { replace: false })
                  // window.location.href = "/blog"
            }
      }
      function next() {
            // window.scrollTo(0,0)
            console.log("next: ")
            console.log(selectedblog?.blogs)
            console.log(selectedblog)
            if (blogs[position + 1]) {
                  console.log(selectedblog.next)
                  setselectedblog(blogs[position + 1])
                  setposition(position + 1)
            }
      }
      function prev() {
            // window.scrollTo(0,0)
            console.log("prev: ")

            if (blogs[position - 1]) {
                  console.log(selectedblog.prev)
                  setselectedblog(blogs[position - 1])
                  setposition(position - 1)
            }
      }

      useEffect(() => {
            console.clear()
            console.log(blog)
            setblogs(blog?.blogs)
            setposition(blog?.position)
            setselectedblog(blog?.blogs[blog?.position])
      }, [])
      useEffect(() => {
            window.scrollTo(0, 0)
      }, [selectedblog])
      const [blog] = useState(useLocation().state)
      const Social = ({ s }) => {
            return (
                  <NavLink to={s.link} key={s.name}>
                        <div className={classes.blogLink}>
                              {/* {s.name}afsafa */}
                              {s.name === "facebook" ? <Facebook /> : ""}
                              {s.name === "twiter" ? <Twitter /> : ""}
                              {s.name === "instagram" ? <Instagram /> : ""}
                        </div>
                  </NavLink>
            )
      }
      return (
            <div className=" d-flex align-items-center bg-second  flex-column w-100 h-100" style={{ paddingTop: "100px", marginBottom: "25vw" }}>
                  <Helmet>
                        <title>Blog post</title>
                        <meta 
                              name="description"
                              content={removeTags(selectedblog?.title)}
                              />
                              <link rel="icon" href={selectedblog?.image} />
                              <link rel="apple-touch-icon" href={selectedblog?.image} />
                  </Helmet>
                  <div className={classes.blogContainer + "  mt-3 my-1 bg-first shadow " + classes.shadow} >
                        <div className={classes.blogImg}>
                              <img src={selectedblog?.image} loading='lazy' />

                        </div>
                        <div className={classes.blogBody}>
                              <div className={classes.blogTitle + "  h4 mx-3 my-3"} dangerouslySetInnerHTML={{ __html: selectedblog?.title }} />
                              <div className={classes.blogDate + " text-muted mx-3  my-1 mb-5"}>{selectedblog?.date}</div>

                              <div className={classes.blogDesc + " f2 mx-3 my-1 mt-5 "} dangerouslySetInnerHTML={{ __html: selectedblog?.description }} />
                              {/* <div onClick={toggleDialog} className={classes.blogLinks + " f1 btn  " + classes.shareBtn + " text-muted h3 my-4"}>

                                    
                                    <div>Share</div>
                                    <Share className={classes.shareBtn + " mx-3"} style={{ fontSize: "1.5em", color: " var(--green)" }} />
                              </div> */}
                              <div className="mx-auto my-5 mb-5" >
                                    <div className="d-flex text-center   justify-content-center  align-items-center my-1"     >
                                          Share
                                          <Share className=" mx-2 f00" />
                                    </div>
                                    <div>
                                          <div className={classes.social + " mx-auto f01"}>
                                                {/* <a href={"https://www.facebook.com/sharer/sharer.php?u=" + "https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id} target="_blank">
                                                     
                                                </a> */}
                                                <FacebookShareButton
                                                      url={"https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id}
                                                      quote={removeTags(selectedblog?.title)}
                                                      hashtag={"#victoi blog"}
                                                      
                                                      imageUrl={selectedblog?.image}
                                                      image={selectedblog?.image}
                                                > <img src={fb} />

                                                </FacebookShareButton>
                                                {/* <a href={"https://telegram.me/share/url?text=" + "https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id} target="_blank">
                                                      <img src={tg} />
                                                </a> */}
                                                <TelegramShareButton

                                                      url={"https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id}
                                                      quote={removeTags(selectedblog?.title)}
                                                      hashtag={"#victoi blog"}
                                                      imageUrl={selectedblog?.image}
                                                >
                                                      <img src={tg} />
                                                </TelegramShareButton>

                                                {/* <a target="_blank" href={"https://wa.me?text=" + "https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id+"&image="+selectedblog?.image}>
                                                      <img src={wh} />
                                                      </a> */}
                                                <WhatsappShareButton

                                                      url={"https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id}
                                                      quote={removeTags(selectedblog?.title)}
                                                      hashtag={"#victoi blog"}
                                                      imageUrl={selectedblog?.image}
                                                >
                                                      <img src={wh} />
                                                </WhatsappShareButton>

                                                {/* <a  onClick={()=>{navigator.clipboard.writeText("https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id);alert("Copied to clipboard, paste link in mail")}} href={"mailto:fordevlpment@gmail.com?text=" + "https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id} target="_blank">
                                                      <Mail/>
                                                </a> */}
                                                <EmailShareButton

                                                      url={"https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id}
                                                      quote={removeTags(selectedblog?.title)}
                                                      subject={selectedblog?.title}
                                                      body={"https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id}
                                                      hashtag={"#victoi blog"}
                                                >

                                                      <img src={m} />

                                                </EmailShareButton>
                                                <div onClick={() => { navigator.clipboard.writeText("https://www.victoi.com/blog/blogpost?blog=" + selectedblog?.id); alert("Copied to clipboard") }}><CopyAll style={{ color: "blue" }} color="blue" /></div>
                                          </div>
                                    </div>
                              </div>

                        </div>

                  </div>
                  <div className={classes.buttons + " f00 d-flex mt-5"} onClick={() => window.scrollTo(0, 0)}>
                        <button className=" mx-4  d-flex justify-content-center align-items-center py-1 " onClick={prev} disabled={selectedblog?.prev ? false : true}><NavigateBefore className="me-2" /><div>Previous</div> </button >
                        <button className=" mx-5  d-flex justify-content-center align-items-center py-1 " onClick={next} disabled={selectedblog?.next ? false : true}><div className="">Next</div> <NavigateNext className=" ms-3" /></button >
                  </div>
                  <div className="row specialcase mb-5">
                        <div className="col-6 specialcase mt-4 " onClick={() => { setselectedblog(blogs[position - 1]); setposition(position - 1) }}>
                              {selectedblog?.prev && <Blog2 navigation={false} blog={blogs[position - 1]} />}
                        </div>
                        <div className="col-6 specialcase mt-4" onClick={() => { setselectedblog(blogs[position + 1]); setposition(position + 1) }}>
                              {selectedblog?.next && <Blog2 navigation={false} blog={blogs[position + 1]} />}
                        </div>
                  </div>
                  <div className={classes.dialog}>

                        <Dialog className={classes.dialog} onClick={() => setDialogstate(false)} open={dialogstate}>
                              <div className="d-flex align-items-center" style={{ color: "var(--green)" }}>
                                    Share to
                                    <IosShare className="mx-4" />
                              </div>
                              <div>
                                    <div className={classes.social + " f01 compliance@victoi.com"}>
                                          <div><img src={fb} /></div>
                                          <div><img src={wh} /></div>
                                          <div><img src={wh} /></div>
                                          <div><CopyAll style={{ color: "blue" }} color="blue" /></div>
                                    </div>
                              </div>
                        </Dialog>
                  </div>
            </div>
      )
}