import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MaximizeIcon from '@mui/icons-material/Maximize';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../Assets/css/A-navbar-css.css";
import "../Assets/css/menu.css";
import card from "../Assets/icons/A-victoi-card.png";
import {Link} from "react-router-dom";
import crypto from "../Assets/icons/A-invest-crypto.png";
import stock from "../Assets/icons/A-invest-stocks.png";
import bills from "../Assets/icons/A-pay-bills.png";
import transfer from "../Assets/icons/TP.png";
import {Box, Grid, Typography} from "@mui/material";
import Logo from "../Assets/Logos/FCG_LG.png";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";
import { FaBars } from "react-icons/fa";
import AppleModal from "./A-modal";
import lines from "../Assets/icons/lines.png";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: -65,
        width: 1000,
        backgroundColor:'#050719',


        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '10px 0',
        },'&.hover':{
            color:"#ffffff",
        }

    },
}));


export  const CustomizedMenus=()=> {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [col,setCol]=useState(" ");
    const [status,colStatus] = useState(true)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        colStatus(false);
        if (event.currentTarget){
            setCol("#8fff00");
        }else {
            setCol("#ffffff")
        }

    };

    const handleClose = () => {
        setAnchorEl(null);

    };

    return (
        <div style={{backgroundColor: "#050719"}} className={'drop A-modal-container container-fluid'} >


            <Button onClick={handleClick} className="navbar-toggler-icon toggler-color" ><img src={lines} alt={'line'} width={'80%'}/></Button>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >

                <AppleModal/>






            </StyledMenu></div>

    );
}