import Header from "./Header";
import Footer from "./Footer";
import gift from "../Assets/img/7.gif"
import "../Assets/css/empty.css"
import Footer3 from "./Footer3";
import {useEffect} from "react";
import { Button } from "@mui/material";
export const ErrorPage=()=>{
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return(
        <>
            <div className={'container d-flex flex-column justify-content-center empty-top'} style={{marginTop:'50px'}}>
                <div className={"col-sm-6"}>
                    <img src={gift} width={'100%'} alt={"gift"}/>

                </div>

                <h1 style={{fontWeight:'bold',fontSize:"2em"}}>COMING SOON....</h1>

            <Button style={{border:"1px solid #8fff00",color:"#8fff00",marginTop:"20px",fontWeight:"bold"}}><a href="https://www.victoi.com/" style={{textDecoration:"none",color:"#8fff00"}}>Go To HomePage</a> </Button>

            </div>
        </>

    )


}