import React, {useEffect} from 'react';
import "./extrapageCss/Videoplaying.css";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Videos from "../../Components/Videos";
import Footer3 from "../../Components/Footer3";

function VideoPlaying() {

  return (
    <>
    <Videos/>
    </>
  )
}

export default VideoPlaying

