import React from 'react'
import "../Assets/css/pageCards.css"
import Fade from 'react-reveal/Fade';

function PageCards({img, headtext, text}) {
  return (
    <div style={{marginTop:"100px"}}>
         <div className="main3">
        <div className="container">

            <div className="image3 "  style={{marginTop:"0px"}}>
                <Fade left>
                    <img src={img} alt="Victoi card"/>
                </Fade>

            </div>
            <Fade right>
            <div className="text3 row-6  ">
                <h1 className="headtext3" style={{fontWeight:"bold"}}>
                    {headtext}
                </h1>
                <p className="maintext3" style={{color:'#98979d'}}>
                    {text}
                </p>
            </div>
            </Fade>
        </div>
    </div>

      
    </div>
  )
}

export default PageCards
