import React from "react";
import "../Assets/css/leftimageCardFour.css";
import stock from "../Assets/img/stock.png"
import '../Assets/css/card3.css';
import victoircard from "../Assets/img/VC.png"
import Fade from 'react-reveal/Fade';
import { Box } from "@mui/material";
import MaximizeIcon from '@mui/icons-material/Maximize';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CardFour = ({ headtext1,headtext2,text,special,img,switchPlace,page}) => {
    const {t} = useTranslation()
    return (
        <div >
            <div className={switchPlace?" limgContainer2":"limgContainer"}  >
                <Fade  right={!switchPlace} left={switchPlace}>
                    <div className={ special?"left special":"left"}>

                        <img src={img} alt="Victoi card" />

                    </div>
                </Fade>
                <div className={"right"}>
                    <Fade right={switchPlace} left={!switchPlace}>
                        <div className="headText">
                        {headtext1} <br/> {headtext2}
                        </div>

                        <p className="maintext">
                        {text} 
                        </p>
                        <p className={switchPlace?"linkbt pspecial":"linkbt"}>
                           <Link to={page} style={{textDecoration:"none",color:'var(--green)'}}>-{t("homepage_section5_link")}</Link>
                        </p>
                    </Fade>
                </div>


                {/*<Box className="text2 textMain">*/}
                {/*    <Fade right>*/}
                {/*    <h2 className="headtext">*/}
                {/*        Your Money! <br/> Spend like a boss*/}
                {/*    </h2>*/}

                {/*    <p className="maintext v-main" style={{}}>*/}
                {/*        Use Victoi multiple and single use virtual VISA debit cards to shop on millions of websites globally. We keep it simple.  <br/> Your Money, Your way!.*/}
                {/*    </p>*/}
                {/*    <p className="linkbt">*/}
                {/*        -Learn More*/}
                {/*    </p>*/}
                {/*    </Fade>*/}
                {/*</Box>*/}
                {/*<div className="image2" >*/}
                {/*    <div className={'col-6 border'} style={{width:"100%"}}>*/}
                {/*        <Fade left>*/}
                {/*            <img  src={stock} alt="Victoi card"/>*/}

                {/*        </Fade>*/}
                {/*    </div>*/}

                {/*</div>*/}



            </div>

        </div>
    );

}