import React, { useEffect, useState } from "react";
import "./extrapageCss/Support.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import started from "../Extrapages/icons/GT.png";
import crypto from "../../Assets/icons/A-invest-crypto.png";
import manage from "../Extrapages/icons/MA.png";
import invest from "../Extrapages/icons/I.png";
import transfer from "../../Assets/icons/TP.png";
import exchange from "../../Assets/icons/A-transfers-payments.png";
import deposit from "../Extrapages/icons/DW.png";
import vcard from "../Extrapages/icons/VC.png";
import general from "../Extrapages/icons/G.png";
import pointer from "../Extrapages/icons/Pointer.png";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import searchicon from "../Extrapages/icons/search.png";
import SmallCard from "../../Components/SmallCard";
import SmallCard2 from "../../Components/SmallCard2";
import gt from "../Extrapages/icons/GT.png";
import i from "../Extrapages/icons/I.png";
import ma from "../Extrapages/icons/MA.png";
import dw from "../Extrapages/icons/DW.png";
import vc2 from "../Extrapages/icons/VC2.png";
import g from "../Extrapages/icons/G.png";
import Footer3 from "../../Components/Footer3";
import Expandible from "../../Components/expandible/expandible";
import { Fade } from "react-reveal";
import {
  AccountBalance,
  AccountBox,
  ArrowBack,
  AssuredWorkload,
  BackHand,
  CallToAction,
  CandlestickChart,
  Clear,
  ContactMail,
  CreditCard,
  CurrencyBitcoin,
  CurrencyExchange,
  Email,
  Gavel,
  HelpOutline,
  Mail,
  NoAccounts,
  Paid,
  PersonOff,
  Policy,
  PsychologyAlt,
  Redeem,
  Security,
  Sell,
  Troubleshoot,
  Verified,
  YoutubeSearchedFor,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export function randomString(lenght) {
  let res = "";
  const strArray = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefgjijklmnopqrstuvwxyz";
  if (!Number(lenght) || lenght < 1) {
    return "";
  }
  for (let i = 0; i < lenght; i++) {
    const element = strArray[Math.floor(Math.random() * (strArray.length - 1))];
    res += element;
  }
  return res;
}
function Support({ siteLanguage }) {
  const { t } = useTranslation();

  // ENGLISH DATA
  const cardsenglish = [
    {
      img: dw,
      icon: <PsychologyAlt />,
      head: " General Questions ",
      text: "hafa ",
    },
    {
      img: general,
      icon: <Security />,
      head: "Account Setup and Security ",
      text: "gone ",
    },
    {
      img: transfer,
      icon: <Paid />,
      head: "Money Transfer ",
      text: "bla bla ",
    },
    {
      img: exchange,
      icon: <CurrencyExchange />,
      head: "Currency Exchange ",
      text: "bla bla ",
    },
    {
      img: gt,
      icon: <AccountBalance />,
      head: "Virtual Bank Accounts",
      text: "bla bla ",
    },
    {
      img: crypto,
      icon: <CurrencyBitcoin />,
      head: "Crypto Currencies  ",
      text: "",
    },
    {
      img: vc2,
      icon: <CreditCard />,
      head: "Debit Cards ",
      text: "",
    },
    {
      img: invest,
      icon: <CandlestickChart />,
      head: "Stock Trading ",
      text: "",
    },
    {
      img: gt,
      icon: <Verified />,
      head: "Account Verification",
      text: "",
    },
    {
      img: gt,
      icon: <Sell />,
      head: "Fees",
      text: "",
    },
    {
      img: gt,
      icon: <Gavel />,
      head: "Tax",
      text: "",
    },
    {
      img: gt,
      icon: <AssuredWorkload />,
      head: "Withdrawals and Deposits ",
      text: "",
    },
    {
      img: gt,
      icon: <Redeem />,
      head: "Referrals and Rewards   ",
      text: "",
    },
    {
      img: gt,
      icon: <Policy />,
      head: "Legal and Compliance ",
      text: "",
    },
    {
      img: gt,
      icon: <Troubleshoot />,
      head: "Troubleshooting  ",
      text: "",
    },
    {
      img: gt,
      icon: <PersonOff />,
      head: "Account Suspension ",
      text: "",
    },
    {
      img: gt,
      icon: <NoAccounts />,
      head: "Account Termination",
      text: "",
    },
    {
      img: gt,
      icon: <HelpOutline />,
      head: "Web Support",
      text: "",
    },
    {
      img: gt,
      icon: <Email />,
      head: "Email Support",
      text: "",
    },
    {
      img: gt,
      icon: <ContactMail />,
      head: "Contact",
      text: "",
    },
  ];

  const [cards, setCards] = useState(cardsenglish);

  const qaenglish = [
    {
      icon: pointer,
      card: cards[0],
      head: "What is Victoi?  ",
      body: " Victoi is a fintech startup that offers a wide range of financial services, including money transfer, currency exchange, virtual currency accounts, virtual Visa debit cards, fractional stock trading, cryptocurrency services and wallets.  ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "How can I use victoi?  ",
      body: "Victoi operates a mobile app, providing users with access to various financial services. Users can create an account, complete necessary verifications, and then use the platform to perform transactions, trade stocks, exchange currencies, and manage their finances.  ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Is Victoi a licensed and regulated financial service?  ",
      body: "Yes, Victoi is either licensed directly with regulators or via its partner (service providers) in accordance with the financial regulations of the regions where it operates. We prioritize the security and compliance of our services to ensure the safety of our users' funds and data.   ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Where is Victoi available?  ",
      body: " Victoi is currently available in select regions, and we are continually expanding our services to reach more users worldwide. Please check our website or app for the most up-to-date information on our availability.  ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "How can I contact Victoi's customer support?   ",
      body: "You can contact Victoi's customer support team through our website or app. We offer multiple support channels, including live chat (in app) and email. Our dedicated support team is available to assist you with any questions or issues you may have. You may contact customer support by filling out the contact form on our website.   ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "How do I create an account on Victoi?  ",
      body: "To create an account on Victoi, download our app, and follow the registration process. You'll need to provide basic personal information, complete KYC verification, and set up security features like a password and multi-factor authentication (MFA). You can register using either your phone number or email.  An android or iOS enabled device with internet connection is required to access victoi.   ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "What security measures does Victoi have in place to protect my account?  ",
      body: "Victoi employs state-of-the-art security measures, including data encryption, regular security audits, and robust identity verification processes (KYC). We also offer multi-factor authentication (MFA) for added account security and peace of mind. ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "What is KYC?",
      body: 'KYC stands for "Know Your Customer" or "Know Your Client." It is a process that Victoi uses to verify the identity of its customers or clients. KYC is an essential component of anti-money laundering (AML) and counter-terrorism financing (CTF) regulations designed to prevent financial fraud, money laundering, and other illicit activities.   ',
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Does victoi support multi-factor authentication?",
      body: "Yes, multi-factor authentication (MFA) is enabled by default on all Victoi accounts. In order to protect your account, data and funds, users cannot opt for MFA.  ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "What should I do if I forget my password or username? ",
      body: 'If you forget your password or username, you can use the "Forgot Password" or "Forgot Username" feature on the login page to reset your password or recover your username. Follow the instructions provided to regain access to your account.  ',
      images: [],
    },
    {
      icon: pointer,
      card: cards[1],
      head: "How can I update my personal information on Victoi?",
      body: 'You can update your personal information, such as your email address, phone number, or residential address, by logging into your Victoi account and navigating to the "Profile" or "Settings" section. Make the necessary changes and save your updates.  ',
      images: [],
    },
    {
      icon: pointer,
      card: cards[2],
      head: "How can I send money using Victoi?  ",
      body: 'Sending money on Victoi is easy. Simply log in, select the "Money Transfer" option, provide the recipient\'s details, choose the payment method, and confirm the transaction. Victoi users can send money to other victoi users, mobile wallets, domestic and international bank accounts.  ',
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Can I send or request money from non victoi users?",
      body: "Yes, you can send or request money from non victoi (Unregistered) users using their email or phone.  When using this option, the recipient will be invited to register with Victoi within 14 days and complete the transaction. After 14 days, the transaction will be cancelled, and funds (if any) returned to the sender's account.   ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "How can I request money with victoi?  ",
      body: "To request money, sign into your victoi account, select recipient, click on “Request”, and follow the steps listed below.   ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[2],
      head: "What are the fees for sending money through Victoi?  ",
      body: "Victoi's fee structure may vary depending on the specific transaction details, including the currencies involved and the transfer amount. You can find detailed information on our fee schedule within the app or on our website. Full transaction details including fees will be disclosed to you prior to validating each transaction.   ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "What currencies can I send and receive with Victoi?",
      body: "Victoi supports a wide range of currencies for money transfers. You can check the list of supported currencies within the app or on our website. The list is updated regularly.",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Are there any transfer limits in Victoi?  ",
      body: "Transfer limits may vary depending on your account type, verification level, and local regulations. You can find information on your specific transfer limits in your Victoi account settings.  ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "How long does it take for a money transfer to be completed?  ",
      body: "The time it takes for a money transfer to be completed can vary based on factors like the receiving bank, the destination country, and the chosen transfer method. Typically, transfers within the same currency are processed faster than international transfers. You can find estimated transfer times within the app or on our website. Approximate processing time will be display on each transaction’s preview page.",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "How can I exchange currencies on Victoi",
      body: 'Exchanging currencies on Victoi is straightforward. Log in, select the "Currency Exchange" option, choose the currencies you want to exchange, enter the amount, choose payment method, and confirm the exchange. Follow the on-screen instructions to complete the transaction.  ',
      images: [],
    },
    {
      icon: pointer,
      card: cards[3],
      head: "What exchange rates does Victoi offer?  ",
      body: "Victoi offers competitive exchange rates for currency conversion. Our rates are updated regularly to ensure transparency in currency exchange transactions. We apply a margin (spread) to keep rates stable.   ",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Are there any fees for currency exchange?  ",
      body: "Yes, there may be fees associated with currency exchange on Victoi. The specific fees depend on the currencies involved, the exchange rate, and the amount being exchanged. You can review the fee details within the app or on our website.  Exact exchange rates and fees will be shown on each transactions preview page prior to confirmation.   ",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Can I set up exchange rate alerts on Victoi? ",
      body: "Yes, you can set up exchange rate alerts on Victoi to receive notifications when your desired exchange rate is reached. This feature helps you make informed decisions regarding currency exchange.  ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Is there a minimum amount for currency exchange?",
      body: "There may be a minimum amount required for currency exchange on Victoi. This amount may depend on the currencies involved and limits set by our partners. You can check the minimum exchange amount within the app or on our website.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "What is a virtual IBAN, and how does it work with Victoi?",
      body: "A virtual IBAN, or International Bank Account Number, is a unique identifier assigned to your Victoi account. It allows you to send and receive payments in various currencies. Victoi provides you with a virtual IBAN, which acts as your personal international bank account, enabling seamless cross-border transactions.",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "How do I get a virtual IBAN with Victoi?   ",
      body: "Getting a virtual IBAN with Victoi is simple. When you sign up for a Victoi account, we automatically provide you with a virtual IBAN associated with your account.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Can I receive payments in multiple currencies using my virtual IBAN?  ",
      body: "Absolutely! Your Victoi virtual IBAN supports receiving payments in various currencies. This flexibility allows you to transact with international clients and partners conveniently.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "How can I send payments using my virtual IBAN?   ",
      body: "You can use your Victoi virtual IBAN to send payments just like a regular bank account. Simply provide your virtual IBAN details to the recipient, and they can initiate the payment.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Are there any fees associated with using my virtual IBAN?  ",
      body: " Victoi strives to keep fees competitive and transparent. While there may be some fees associated with specific transactions, we aim to provide cost-effective solutions for international payments.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Can I withdraw funds from my virtual IBAN to my local bank account?   ",
      body: "Yes, you can transfer funds from your Victoi virtual IBAN to your linked local bank account. This feature provides the convenience of accessing your international funds locally.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Is my virtual IBAN secure?   ",
      body: "Yes, security is a top priority at Victoi. Your virtual IBAN is protected by robust security measures to safeguard your financial information and transactions.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "What are virtual Bank currency accounts, and how do they work on Victoi?   ",
      body: "Virtual bank accounts on Victoi are digital accounts that allow you to hold and manage multiple currencies within the platform. You can use these accounts to hold balances, send and receive payments in   ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "How long does it take to receive funds in my virtual IBAN from international sources?   ",
      body: "The time it takes to receive funds in your virtual IBAN may vary depending on the sender's bank and the chosen payment method. However, we strive to make international transactions as swift as possible.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Can I use my virtual IBAN for business purposes?   ",
      body: "Absolutely! Your Victoi virtual IBAN is suitable for both personal and business use. It's a versatile tool for sending and receiving payments in different currencies for various purposes.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Is there a limit to the number of virtual IBANs I can have with Victoi?   ",
      body: "Currently, Victoi provides one virtual IBAN per user account. However, we continually assess and update our features to better meet our users' needs.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "What cryptocurrencies can I trade on Victoi?  ",
      body: " Victoi offers a diverse range of cryptocurrencies for trading, including popular options like Bitcoin (BTC), Ethereum (ETH), and stablecoins like USDT and USDC. We continually expand our cryptocurrency offerings to provide you with a wide selection.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "How do I start trading cryptocurrencies on Victoi?  ",
      body: "  Getting started with cryptocurrency trading on Victoi is straightforward. Sign up for an account, complete the necessary verification, and deposit funds. Then, you can start trading by selecting your preferred cryptocurrency pair and placing orders on our platform.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Is cryptocurrency trading on Victoi secure?  ",
      body: "Yes, security is paramount at Victoi. We employ advanced security measures to protect your digital assets and ensure a secure trading environment. Our team works diligently to safeguard your investments.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Are there fees associated with cryptocurrency trading on Victoi?  ",
      body: "  Yes, like most cryptocurrency exchanges, Victoi charges trading fees. These fees may vary depending on the specific cryptocurrency pair and trading volume. Please refer to our fee schedule for detailed information.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Can I trade cryptocurrencies 24/7 on Victoi?  ",
      body: "Yes, Victoi provides 24/7 access to cryptocurrency markets. You can trade at any time, allowing flexibility in managing your crypto investments.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Can I withdraw cryptocurrencies to an external wallet from my Victoi account?  ",
      body: "  Absolutely! You can withdraw your cryptocurrencies to an external wallet of your choice. This provides an added layer of security for your digital assets.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Do you offer educational resources for cryptocurrency trading beginners?  ",
      body: "  Yes, Victoi is committed to educating our users. We provide educational resources, including articles and guides, to help beginners understand the fundamentals of cryptocurrency trading.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "How do I stay updated on cryptocurrency market trends and news?  ",
      body: "  We offer real-time market data and news updates within our platform. You can also explore external sources for in-depth cryptocurrency market analysis and insights.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: " Is there a minimum investment amount for cryptocurrency trading on Victoi?  ",
      body: "   The minimum investment amount may vary depending on the specific cryptocurrency and market conditions. You can check the minimum order size for each trading pair on our platform.  ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "What support channels are available if I have questions about cryptocurrency trading on Victoi?",
      body: " We offer customer support via various channels, including live chat, email, and phone support. Our dedicated team is here to assist you with any questions or concerns you may have about cryptocurrency trading on Victoi.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "What is a virtual debit card, and how does it work with Victoi?  ",
      body: "A virtual debit card is a digital representation of a physical debit card. It allows you to make online purchases and transactions securely. With Victoi, you can use a virtual debit card to shop online, pay bills, and more, just like a physical card.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "How do I obtain a virtual debit card from Victoi?  ",
      body: "When you sign up for a Victoi account, you will automatically receive a virtual debit card. You can find your virtual card details in your account dashboard.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Can I use my virtual debit card for in-store purchases?  ",
      body: "Virtual debit cards are primarily designed for online transactions. However, you can use some digital wallet apps that support card provisioning to make contactless payments in physical stores.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "What is a physical debit card from Victoi, and how can I get one?  ",
      body: "A physical debit card is a tangible card that you can use for both online and in-store purchases. Victoi is working to provide physical debit cards to our users soon. You will be able to request one through your Victoi account once they become available.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Are there fees associated with using Victoi's virtual and physical debit cards?  ",
      body: "We strive to keep our fee structure competitive and transparent. While there may be fees associated with specific card-related transactions, such as ATM withdrawals or card replacements, we aim to provide cost-effective solutions.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Can I link my virtual or physical debit card to other payment apps or platforms?  ",
      body: "Yes, you can often link your virtual and physical debit cards to various payment apps and platforms that support card integration. This allows you to use your Victoi debit card across a range of services.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "How do I activate my physical debit card from Victoi once I receive it?  ",
      body: "Once you receive your physical debit card, you will typically follow the activation instructions provided with the card.   ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "How can I get a virtual Visa debit card from Victoi?  ",
      body: 'To obtain a virtual Visa debit card from Victoi, log in to your account, navigate to the "Cards" section, and follow the steps to request a virtual card. Once issued, you can use it for online and in-store purchases.  ',
      image: [],
    },
    {
      icon: pointer,
      card: cards[6],
      head: "What are the benefits of using a virtual debit card from Victoi?  ",
      body: "Virtual debit cards from Victoi offer convenience, security, and flexibility. They can be used for online shopping, subscription services, and other transactions without the need for a physical card. Plus, you can link them to different currency accounts for seamless spending.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Can I link my virtual debit card to Apple Wallet and Google Wallet?  ",
      body: "Yes, from victoi app, you can add your multi-use virtual card to Apple wallet and Google wallet in a few easy steps.    ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Can I use my virtual or physical debit card for international transactions?  ",
      body: "Yes, you can use your Victoi debit cards for international transactions, both online and in-store, where cards are accepted. Please note that some fees may apply to international transactions, so it's advisable to check our fee schedule.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "What security measures are in place to protect my virtual and physical debit cards with Victoi?  ",
      body: "Victoi takes security seriously. We implement encryption and other security protocols to protect your card information and transactions. Additionally, you can set up card controls and notifications for added security.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "How do I report a lost or stolen virtual or physical debit card from Victoi?  ",
      body: "In the event of a lost or stolen card, you should immediately contact our customer support team to report the issue. They will guide you through the necessary steps to protect your account and order a replacement card if needed.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "How does fractional stock trading work on Victoi?   ",
      body: " Fractional stock trading on Victoi allows you to buy and sell fractions of company shares, making it easier to invest with smaller amounts of capital. You can choose the stock you want to invest in, specify the amount you wish to purchase, and execute the trade.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "What stocks and markets are available for trading on Victoi?  ",
      body: "Victoi offers access to a wide range of stocks from various markets and exchanges. You can explore the available stocks and markets within the app or on our website.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Are there any fees for trading fractional stocks?  ",
      body: 'Yes, there may be fees associated with trading fractional stocks on Victoi. These fees can include commissions and spreads. You can review the fee details in the "Trading" section of your Victoi account.  ',
      images: [],
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Can I set up alerts for stock price changes?  ",
      body: "Yes, you can set up alerts for stock price changes on Victoi. This feature allows you to receive notifications when the price of a specific stock reaches a certain level, helping you make timely investment decisions.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "What is fractional stock trading, and how does it work on Victoi?  ",
      body: "Fractional stock trading allows you to buy and own a fraction of a share in a publicly traded company, rather than purchasing a whole share. On Victoi, this means you can invest in companies with high share prices by buying a portion of their stock, making it more accessible to investors with varying budgets.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Is my investment in fractional shares on Victoi secure?  ",
      body: "Victoi prioritizes security and employs advanced measures to protect your investments. We use encryption and security protocols to safeguard your account and assets.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Can I purchase fractional shares of any publicly traded company on Victoi?  ",
      body: "While we aim to provide a wide range of fractional shares, the availability may vary depending on the companies listed on our platform. We continuously expand our offerings to include more options for our users.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "How do I get started with fractional stock trading on Victoi?  ",
      body: "To begin fractional stock trading on Victoi, sign up for an account, complete the necessary verification, and fund your account. Then, you can start exploring the available fractional shares and make your investments.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Is there educational support available for fractional stock trading beginners?  ",
      body: "Victoi provides educational resources, including articles and guides, to help beginners understand the fundamentals of fractional stock trading. We encourage you to explore these resources to enhance your trading knowledge.  ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "How can I withdraw funds from my stock trading account?  ",
      body: 'You can withdraw funds from your stock trading account by navigating to the "Withdraw" or "Funds" section of your Victoi account. Follow the provided instructions to initiate a withdrawal.  ',
    },
    {
      icon: pointer,
      card: cards[8],
      head: "What is currency exchange, and how does it work on Victoi?  ",
      body: " Currency exchange, also known as forex or FX, is the process of converting one currency into another at an agreed-upon exchange rate. On Victoi, you can exchange currencies to facilitate international transactions or take advantage of favorable exchange rates.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Which currencies can I exchange on Victoi?  ",
      body: "  Victoi offers a wide range of currencies for exchange, allowing you to convert between different currencies as needed for your financial transactions.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: " How do I initiate a currency exchange on Victoi?  ",
      body: " To exchange currencies on Victoi, log in to your account, navigate to the currency exchange section, and follow the instructions provided. Specify the currencies you want to exchange, the amount, and confirm the transaction.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Are there fees associated with currency exchange on Victoi?  ",
      body: " Yes, currency exchange may involve fees, and these fees can vary depending on the specific currencies and exchange rates. You can review our fee schedule for detailed information.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Can I set up alerts for favorable exchange rates on Victoi?  ",
      body: " Yes, Victoi provides exchange rate alerts, allowing you to monitor currency rates and receive notifications when your preferred exchange rate is reached. This feature helps you make informed decisions for your currency exchanges.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "How long does it take for currency exchange transactions to be completed on Victoi?  ",
      body: " The processing time for currency exchange transactions may vary depending on factors such as the selected currencies and market conditions. Victoi aims to process exchanges as swiftly as possible, providing you with timely access to your converted funds.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Can I lock in an exchange rate in advance for a future transaction on Victoi?  ",
      body: " Victoi offers forward contracts or limit orders that allow you to lock in an exchange rate for a future transaction. This feature helps you manage currency risk and secure favorable rates in advance.  ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Is my currency exchange information and transactions secure on Victoi?  ",
      body: " Yes, Victoi prioritizes the security of your currency exchange information and transactions. We use encryption and security protocols to protect your data and provide a secure trading environment.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Can I exchange large sums of currency on Victoi?  ",
      body: " Victoi caters to a wide range of users, including those who may require larger currency exchanges. The platform is designed to handle various transaction sizes, and you can reach out to our support team for assistance with large transactions.  ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Is there educational support available for currency exchange beginners on Victoi?  ",
      body: "  Yes, Victoi provides educational resources, including articles and guides, to help beginners understand the basics of currency exchange. We encourage you to explore these resources to enhance your understanding of stock trading.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Why do I need to complete KYC verification?   ",
      body: "KYC (Know Your Customer) verification is required by financial regulations to confirm the identity of users and prevent fraudulent activity and money laundering. Completing KYC helps ensure the security of our platform.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "What is KYC, and why is it important on Victoi?  ",
      body: " KYC stands for \"Know Your Customer,\" and it's a process that helps us verify the identity of our users. It's essential to ensure the security and integrity of our platform, prevent fraud, and comply with regulatory requirements.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "How do I complete the KYC verification process on Victoi?  ",
      body: 'To complete KYC verification, log in to your Victoi account and navigate to the "Verification" or "KYC" section. Follow the provided instructions, which typically involve submitting identification documents and verifying your identity.  ',
    },
    {
      icon: pointer,
      card: cards[9],
      head: "How long does it take to verify my account?  ",
      body: "The time it takes to verify your account may vary depending on the volume of verification requests and the accuracy of the information provided. Typically, the verification process is completed within a few business days.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "What documents are required for KYC verification?  ",
      body: "The specific documents required for KYC verification may vary by region and local regulations. Typically, you will need to provide government-issued identification documents such as a passport, driver's license, or ID card, as well as proof of address documents.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "How do I initiate the KYC verification process on Victoi?  ",
      body: "To start the KYC process, log in to your Victoi account and navigate to the KYC verification section. Follow the instructions provided to submit your identification documents and other required information.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "How long does the KYC verification process usually take on Victoi?  ",
      body: "The processing time for KYC verification may vary, but we strive to review and verify user accounts as quickly as possible. You can check the status of your verification within your Victoi account.   ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Is my personal information secure during the KYC process on Victoi?  ",
      body: "Yes, your personal information is handled with the utmost security and privacy. We employ robust encryption and data protection measures to safeguard your data throughout the KYC process.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "What is AML, and why does Victoi implement AML procedures?  ",
      body: 'AML stands for "Anti-Money Laundering," and it\'s a set of regulations and procedures designed to detect and prevent money laundering and other illicit financial activities. Implementing AML measures is crucial for maintaining the integrity of our platform and complying with legal requirements.  ',
    },
    {
      icon: pointer,
      card: cards[9],
      head: "What are the consequences of not complying with KYC and AML requirements on Victoi?  ",
      body: "Non-compliance with KYC and AML procedures may result in account restrictions, suspensions, or closure. It's important to complete the verification process and adhere to AML guidelines to ensure continued access to our platform.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Can I update my KYC information on Victoi if there are changes to my identification or personal details?  ",
      body: "Yes, you can typically update your KYC information by contacting our customer support team and providing the necessary documentation for the changes. Keeping your KYC details up to date is essential for account accuracy and compliance.  ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Where can I find more information about Victoi's KYC and AML policies and procedures?  ",
      body: "Detailed information about Victoi's KYC and AML policies and procedures can be found in our Terms of Service and Privacy Policy. Additionally, our customer support team is available to assist you with any specific questions or concerns related to KYC and AML compliance.  ",
    },
    {
      icon: pointer,
      card: cards[10],
      head: "Where can I find a complete list of Victoi's fees and charges?   ",
      body: 'You can find a complete list of Victoi\'s fees and charges on our website or within the app. Navigate to the "Our Fees" section to access this information.  ',
    },
    {
      icon: pointer,
      card: cards[10],
      head: "Are there any hidden fees on Victoi?  ",
      body: "Victoi is committed to transparency, and we do not have hidden fees. All applicable fees are clearly disclosed in our fee schedule, which you can review before initiating transactions.  ",
    },
    {
      icon: pointer,
      card: cards[10],
      head: "How are exchange rates determined for currency exchange?  ",
      body: "Exchange rates for currency exchange on Victoi are determined based on market rates and may include a small spread to cover operational costs. Our goal is to provide competitive rates while ensuring the stability of our service.  ",
    },
    {
      icon: pointer,
      card: cards[11],
      head: "Does Victoi provide tax reporting for my transactions?  ",
      body: "Yes, Victoi provides transaction history and reports that can be used for tax purposes. You can access these reports within your Victoi account.  ",
    },
    {
      icon: pointer,
      card: cards[11],
      head: "How can I access my transaction history for tax purposes?  ",
      body: 'To access your transaction history for tax purposes, log in to your Victoi account and navigate to the "Transaction History" or "Reports" section. You can generate reports and export transaction data for your records.  ',
    },
    {
      icon: pointer,
      card: cards[11],
      head: "What tax implications should I be aware of when using Victoi?  ",
      body: "Tax implications may vary depending on your location and the nature of your financial activities on Victoi. It is advisable to consult with a tax professional or accountant to understand your specific tax obligations.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "How can I deposit funds into my Victoi account?   ",
      body: 'You can deposit funds into your Victoi account by selecting the "Deposit" option within the app or website. We support various deposit methods, including bank transfers, credit/debit card payments, Mobile wallets, and cryptocurrency deposits.  ',
    },
    {
      icon: pointer,
      card: cards[12],
      head: "What deposit and withdrawal methods does Victoi offer for funding and accessing my account?  ",
      body: "Victoi provides multiple options for depositing and withdrawing funds, including bank accounts, debit cards, and mobile wallets. These versatile methods allow you to manage your account with convenience.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "How do I make a deposit into my Victoi account using a bank account?  ",
      body: "To deposit funds from your bank account, log in to your Victoi account, navigate to the deposit section, and follow the instructions provided. You will need to link your bank account and specify the amount you wish to deposit.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Can I use my debit card to fund my Victoi account?  ",
      body: "Yes, you can fund your Victoi account using a debit card. Simply go to the deposit section of your account, select the debit card option, and follow the steps to complete the transaction.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Are there any fees associated with depositing funds into my Victoi account?  ",
      body: " While Victoi aims to keep fees competitive and transparent, some provider or financial institution may check fees when depositing to victoi. You can check with your financial institution and/or victoi for applicable fees.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "How long does it take for deposited funds to be credited to my Victoi account?  ",
      body: " The processing time for deposits may vary depending on the selected method. Bank transfers may take a few business days, while debit card deposits are usually credited more quickly. You can check the estimated processing times within your Victoi account.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Can I withdraw funds from my Victoi account to my bank account or debit card?  ",
      body: " Yes, you can withdraw funds from your Victoi account to your linked bank account or debit card. To initiate a withdrawal, visit the withdrawal section of your account and follow the provided instructions.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Are there withdrawal fees for transferring funds from my Victoi account to my bank account or debit card?  ",
      body: "Withdrawal fees may apply to specific withdrawal methods, depending on the chosen financial institution or payment provider. You can find detailed information about withdrawal fees in our fee schedule.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Can I use mobile wallets to deposit and withdraw funds on Victoi?  ",
      body: " Yes, you can use mobile wallets as a convenient deposit and withdrawal method on Victoi. Simply choose the mobile wallet option when making a deposit or initiating a withdrawal and follow the provided instructions.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "How can I check the status of my deposits and withdrawals on Victoi?  ",
      body: " You can easily track the status of your deposits and withdrawals within your Victoi account. We provide real-time updates and notifications to keep you informed about the progress of your transactions.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Is my financial information secure when using deposit and withdrawal methods on Victoi?  ",
      body: " Yes, Victoi prioritizes the security of your financial information. We employ advanced encryption and security measures to protect your account and transactions, ensuring a safe and secure experience.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "What withdrawal methods are available on Victoi?  ",
      body: "Victoi offers multiple withdrawal methods, including bank transfers, card withdrawals, and cryptocurrency withdrawals. You can choose the method that best suits your preferences.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Are there any withdrawal limits?  ",
      body: "Withdrawal limits may vary based on your account type, verification level, and local regulations. You can review your specific withdrawal limits within your Victoi account settings.  ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "How long does it take for withdrawals to be processed?  ",
      body: "The processing time for withdrawals on Victoi can vary depending on the withdrawal method and the destination. Bank transfers and card withdrawals may take a few business days, while cryptocurrency withdrawals are typically processed more quickly.  ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "Does Victoi offer a referral program?   ",
      body: "Yes, Victoi offers a referral program that allows you to refer friends and earn rewards for successful referrals. You can find details about our referral program within the app or on our website.  ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "How can I refer friends to Victoi and earn rewards?  ",
      body: "To refer friends to Victoi, log in to your account and navigate to the “Earn” section. You can generate referral links or codes to share with your contacts. When someone signs up using your referral link and meets the referral criteria, you'll earn rewards.  ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "What rewards can I earn through the referral program?  ",
      body: "The rewards offered through the referral program may include cash bonuses, discounts on fees, or other incentives. The specific rewards and eligibility criteria are outlined in the referral program details on our website and within the app.  ",
    },
    {
      icon: pointer,
      card: cards[14],
      head: "What are Victoi's terms of service and privacy policy?   ",
      body: "Victoi's terms of service and privacy policy outline the terms and conditions of using our platform and how we handle user data. You can review these documents on our website or within the app.  ",
    },
    {
      icon: pointer,
      card: cards[14],
      head: "How does Victoi comply with anti-money laundering (AML) regulations?  ",
      body: "Victoi complies with AML regulations by implementing robust KYC procedures, monitoring transactions for suspicious activity, and reporting any suspicious transactions to relevant authorities. Our AML compliance measures are in line with industry standards and local regulations.  ",
    },
    {
      icon: pointer,
      card: cards[14],
      head: "What is Victoi's policy on fraudulent activity and scams?  ",
      body: "Victoi has a strict policy against fraudulent activity and scams. We take all necessary measures to detect and prevent fraudulent transactions and activities on our platform. If you suspect any fraudulent activity, please report it to our customer support team immediately.  ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "What should I do if I encounter technical issues on the Victoi platform?   ",
      body: " If you encounter technical issues on the Victoi platform, you can reach out to our customer support team for assistance. We have a dedicated support team ready to help you resolve any technical problems.  ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "How can I reset my two-factor authentication if I lose access to it?  ",
      body: "If you lose access to your two-factor authentication (2FA) method, such as a mobile app or hardware token, you can contact our customer support team for assistance in resetting your 2FA and regaining access to your account.  ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "Is Victoi available on mobile devices? What are the supported platforms?  ",
      body: "Yes, Victoi is available on mobile devices. You can download the Victoi app from the App Store (iOS) or Google Play Store (Android) to access our services. Additionally, you can use the Victoi website on desktop and mobile browsers.  ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: " Why was my Victoi account temporarily suspended?  ",
      body: " Temporary account suspension may occur due to various reasons, including security concerns, suspected fraudulent activity, non-compliance with our Terms of Service, or pending investigation into account-related issues.  ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "How long does a temporary account suspension typically last on Victoi?  ",
      body: " The duration of a temporary account suspension varies depending on the specific circumstances and the actions required to resolve the issue. It can range from a few hours to several days.  ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Can I still access my account while it's temporarily suspended?  ",
      body: " In most cases, you can still access your Victoi account while it's temporarily suspended, but with restricted functionalities. You may be able to view your account information and contact customer support for assistance.  ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "How can I resolve a temporary account suspension on Victoi?  ",
      body: " To resolve a temporary account suspension, follow the instructions provided in the email notification or account dashboard message. This may involve completing additional verification steps or addressing the specific issue that led to the suspension.  ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Can I appeal a temporary account suspension decision on Victoi?  ",
      body: " Yes, you can typically appeal a temporary account suspension decision by contacting our customer support team. Provide any relevant information or documentation to support your case, and our team will review it accordingly.  ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Why was my Victoi account permanently terminated?  ",
      body: " Permanent account termination may occur for severe violations of our Terms of Service, repeated non-compliance with platform policies, or illegal activities on the platform. Victoi takes these measures to protect its users and maintain a safe environment.  ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Is there any way to reverse a permanent account termination on Victoi?  ",
      body: " In most cases, permanent account termination decisions are final. However, you can contact our customer support team to discuss your situation and inquire about the possibility of account reinstatement. It will depend on the specific circumstances.  ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Can I create a new account on Victoi if my previous account was permanently terminated?  ",
      body: " Creating a new account after a permanent termination is typically against our policies. Users are encouraged to address account issues through proper channels and seek resolution rather than attempting to create a new account.  ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Are there fees associated with temporary account suspension or permanent account termination on Victoi?  ",
      body: " Victoi does not typically charge fees for these actions. However, if the suspension or termination is related to specific actions or non-compliance issues, there may be associated costs.  ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Where can I find more information about Victoi's policies and procedures related to account suspension and termination?  ",
      body: " You can review our Terms of Service and Privacy Policy for detailed information about Victoi's policies and procedures, including those related to account suspension and termination. If you have specific questions or concerns, our customer support team is available to assist you.  ",
    },
    {
      icon: pointer,
      card: cards[18],
      head: " How can I access in-app chat support on Victoi?  ",
      body: ' In-app chat support is available to Victoi users within the mobile app or web platform. Simply navigate to the "Customer Support" section, and you\'ll find options to initiate a chat conversation with our support team.  ',
    },
    {
      icon: pointer,
      card: cards[18],
      head: "What are the operating hours of in-app chat support?  ",
      body: "Our in-app chat support is typically available during our standard operating hours, which may vary by region. You can check the chat availability within the app for the most up-to-date information.  ",
    },
    {
      icon: pointer,
      card: cards[18],
      head: " How long does it usually take to receive a response in the in-app chat?  ",
      body: " We strive to provide timely responses to in-app chat inquiries. The response time may vary based on the volume of requests, but our goal is to assist you as quickly as possible.  ",
    },
    {
      icon: pointer,
      card: cards[19],
      head: " How can I contact Victoi's email support?  ",
      body: "  You can reach our email support team by sending your inquiries or concerns to https://support@victoi.com. Please provide detailed information to help us assist you effectively.  ",
    },
    {
      icon: pointer,
      card: cards[19],
      head: " What is the typical response time for email inquiries?  ",
      body: " Our email support team aims to respond to inquiries within a reasonable timeframe, usually within 24 to 48 hours (about 2 days). Please note that response times may vary depending on the complexity of the request.  ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: " How can I use the website contact form to reach Victoi's support team?  ",
      body: ' You can access the website contact form by visiting our official website at www.victoi.com. Look for the "Contact Us" section, where you can fill out the form with your name, email, subject, and message.  ',
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Is the website contact form available 24/7?  ",
      body: "The website contact form is typically accessible 24/7, allowing you to submit inquiries or requests at any time. However, response times may vary based on when the inquiry is received.  ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "What types of inquiries or issues can I contact Victoi's support team about?  ",
      body: " You can contact our support team for a wide range of inquiries, including account-related questions, technical issues, transaction assistance, and general assistance with using the Victoi platform.  ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Is customer support available in multiple languages?  ",
      body: " Victoi aims to provide support in multiple languages to accommodate our diverse user base. The availability of languages may vary based on your region.  ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Where can I find additional resources or self-help guides if I have a common question or issue?  ",
      body: " Victoi provides a Help Center or FAQ section within the app and on our website, where you can find answers to common questions and access helpful guides. These resources can often address common inquiries without the need to contact support.  ",
    },
  ];
  const [qa, setQa] = useState(qaenglish);
  const popularenglish = [
    qaenglish[2],
    qaenglish[4],
    qaenglish[13],
    qaenglish[7],
    qaenglish[12],
    qaenglish[17],
  ];

  // FRENCH DATA
  const cardsfrench = [
    {
      img: gt,
      icon: <PsychologyAlt />,
      head: "Questions Général",
      text: "hafa ",
    },
    {
      img: gt,
      icon: <Security />,
      head: "Configuration et Sécurité",
      text: "gone ",
    },
    {
      img: gt,
      icon: <Paid />,
      head: "Transfert d'argent",
      text: "bla bla ",
    },
    {
      img: gt,
      icon: <CurrencyExchange />,
      head: "Change De Devises",
      text: "bla bla ",
    },
    {
      img: gt,
      icon: <AccountBalance />,
      head: "Comptes bancaires virtuels ",
      text: "bla bla ",
    },
    {
      img: gt,
      icon: <CurrencyBitcoin />,
      head: "Crypto Monnais",
      text: "",
    },
    {
      img: gt,
      icon: <CreditCard />,
      head: "Cartes De Debit",
      text: "",
    },
    {
      img: gt,
      icon: <CandlestickChart />,
      head: "Négociation D'actions",
      text: "",
    },
    {
      img: gt,
      icon: <Verified />,
      head: "Vérification",
      text: "",
    },
    {
      img: gt,
      icon: <Sell />,
      head: "Frais",
      text: "",
    },
    {
      img: gt,
      icon: <Gavel />,
      head: "Fiscalité et déclaration ",
      text: "",
    },
    {
      img: gt,
      icon: <AssuredWorkload />,
      head: "Retraits et dépôts  ",
      text: "",
    },
    {
      img: gt,
      icon: <Redeem />,
      head: "Parrainages et récompenses ",
      text: "",
    },
    {
      img: gt,
      icon: <Policy />,
      head: "Juridique et conformité ",
      text: "",
    },
    {
      img: gt,
      icon: <Troubleshoot />,
      head: "Dépannage ",
      text: "",
    },
    {
      img: gt,
      icon: <PersonOff />,
      head: "Suspension Temporaire",
      text: "",
    },
    {
      img: gt,
      icon: <NoAccounts />,
      head: "Résiliation Définitive",
      text: "",
    },
    {
      img: gt,
      icon: <HelpOutline />,
      head: "Assistance - chat ",
      text: "",
    },
    {
      img: gt,
      icon: <Mail />,
      head: "Assistance - E-mail",
      text: "",
    },
    {
      img: gt,
      icon: <ContactMail />,
      head: "Assistance - Web ",
      text: "",
    },
  ];
  const qafrench = [
    {
      icon: pointer,
      card: cards[0],
      head: "Qu'est-ce que Victoi? ",
      body: "Victoi is a fintech startup that offers a wide range of financial services, including money transfer, currency exchange, virtual currency accounts, virtual Visa debit cards, fractional stock trading, cryptocurrency services and wallets.  Victoi est une start-up fintech qui propose une large gamme de services financiers, notamment le transfert d'argent, le change de devises, les comptes en monnaie virtuelle, les cartes de débit Visa virtuelles, le trading d'actions fractionnées, les services de crypto-monnaie et les portefeuilles. ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Comment puis-je utiliser victoi?",
      body: "Victoi exploite une application mobile qui permet aux utilisateurs d'accéder à divers services financiers. Les utilisateurs peuvent créer un compte, effectuer les vérifications nécessaires, puis utiliser la plateforme pour effectuer des transactions, échanger des actions, échanger des devises et gérer leurs finances. ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Est-ce que Victoi est un service financier agréé et réglementé? ",
      body: "Oui, Victoi est soit agréé directement auprès des régulateurs, soit par l'intermédiaire de ses partenaires (prestataires) conformément à la réglementation financière des régions où elle opère. Nous accordons la priorité à la sécurité et à la conformité de nos services afin d'assurer la sécurité des fonds et des données de nos utilisateurs.",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Où Victoi est-il disponible? ",
      body: " Victoi est actuellement disponible dans certaines régions, et nous élargissons continuellement nos services pour atteindre plus d'utilisateurs dans le monde entier. Veuillez consulter notre site Web ou notre application pour obtenir les informations les plus récentes sur nos disponibilités. ",
    },
    {
      icon: pointer,
      card: cards[0],
      head: "Comment puis-je contacter le support client de Victoi? ",
      body: "Vous pouvez contacter l'équipe d'assistance à la clientèle de Victoi via notre site Web ou notre application. Nous proposons plusieurs canaux d'assistance, notamment le chat en direct (dans l'application) et l'e-mail. Notre équipe d'assistance dédiée est à votre disposition pour répondre à toutes vos questions ou problèmes. Vous pouvez contacter le service client en remplissant le formulaire de contact sur notre site Web.  ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Comment créer un compte sur Victoi ? ",
      body: "Pour ouvrir un compte sur Victoi, téléchargez notre application et suivez les étapes du processus d'inscription. Vous devrez renseigner quelques informations personnelles de base, passer par une vérification CDD (Connaissance De votre Client) et mettre en place des mesures de sécurité, comme un mot de passe et une authentification à plusieurs facteurs (AMF). L'inscription peut être effectuée avec votre numéro de téléphone ou votre adresse e-mail. Pour accéder à Victoi, vous aurez besoin d'un appareil compatible Android ou iOS et d'une connexion Internet. ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Quelles sont les mesures de sécurité mises en place par Victoi pour protéger mon compte ? ",
      body: "Victoi met en œuvre des mesures de sécurité de pointe pour assurer la protection de votre compte. Cela inclut le cryptage des données pour sécuriser vos informations personnelles et financières, ainsi que la réalisation régulière d'audits de sécurité pour identifier et rectifier toute vulnérabilité. De plus, nous appliquons des processus rigoureux de Connaissance De votre Client (CDD) pour vérifier l'identité de nos utilisateurs. Pour renforcer davantage la sécurité, nous offrons également l'authentification à plusieurs facteurs (AMF), garantissant une sécurité accrue et une tranquillité d'esprit pour nos clients. ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Qu'est-ce que le CDD ? ",
      body: "Le CDD, pour « Connaissance De votre Client » (traduit de l'anglais « Know Your Customer » ou « Know Your Client »), est un processus utilisé par Victoi pour vérifier l'identité de ses clients. Ce processus est un élément crucial des réglementations en matière de Lutte Contre le Blanchiment des Capitaux (LCB) et de Financement du Terrorisme (FT). Il est conçu pour prévenir la fraude financière, le blanchiment d'argent et d'autres activités illicites.  ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Victoi prend-il en charge l'authentification multifacteur? ",
      body: "Oui, l'authentification multifacteur (AMF) est activée par défaut sur tous les comptes Victoi. Afin de protéger votre compte, vos données et vos fonds, les utilisateurs ne peuvent pas opter pour l'authentification multifacteur. ",
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Que dois-je faire si j'oublie mon mot de passe ou mon nom d’utilisateur?  ",
      body: "Si vous oubliez votre mot de passe ou votre nom d'utilisateur, vous pouvez utiliser la fonction « Mot de passe oublié » ou « Nom d'utilisateur oublié » sur la page de connexion pour réinitialiser votre mot de passe ou récupérer votre nom d'utilisateur. Suivez les instructions fournies pour retrouver l'accès à votre compte. ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[1],
      head: "Comment puis-je mettre à jour mes informations personnelles sur Victoi?",
      body: "Vous pouvez mettre à jour vos informations personnelles, telles que votre adresse e-mail, votre numéro de téléphone ou votre adresse résidentielle, en vous connectant à votre compte Victoi et en accédant à la section « Profil » ou « Paramètres ». Apportez les modifications nécessaires et enregistrez vos mises à jour. ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Comment puis-je envoyer de l'argent avec Victoi ?  ",
      body: "Envoyer de l'argent sur Victoi est facile. Il vous suffit de vous connecter, de sélectionner l'option « Transfert d'argent », de fournir les coordonnées du destinataire, de choisir le mode de paiement et de confirmer la transaction. Les utilisateurs de Victoi peuvent envoyer de l'argent à d'autres utilisateurs de Victoi, à des portefeuilles mobiles, à des comptes bancaires nationaux et internationaux.  ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Puis-je envoyer ou demander de l'argent à des utilisateurs non victoi? ",
      body: "Oui, vous pouvez envoyer ou demander de l'argent à des utilisateurs non vaccinés (non enregistrés) en utilisant leur adresse e-mail ou leur téléphone.  Lors de l'utilisation de cette option, le destinataire sera invité à s'inscrire auprès de victoi dans un délai de 14 jours et à compléter la transaction. Après 14 jours, la transaction sera annulée et les fonds (le cas échéant) seront retournés sur le compte de l'expéditeur. ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Comment puis-je demander de l'argent avec victoi? ",
      body: "Pour demander de l'argent, connectez-vous à votre compte victoi, sélectionnez le destinataire, cliquez sur « Demander » et suivez les étapes ci-dessous. ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Quels sont les frais pour envoyer de l'argent via Victoi? ",
      body: "La structure des frais de Victoi peut varier en fonction des détails spécifiques de la transaction, y compris les devises concernées et le montant du transfert. Vous trouverez des informations détaillées sur notre grille tarifaire dans l'application ou sur notre site web. Tous les détails de la transaction, y compris les frais, vous seront divulgués avant de valider chaque transaction. ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Quelles devises puis-je envoyer et recevoir avec Victoi? ",
      body: "Victoi prend en charge un large éventail de devises pour les transferts d'argent. Vous pouvez consulter la liste des devises prises en charge dans l'application ou sur notre site Web. La liste est mise à jour régulièrement.   ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Y a-t-il des limites de transfert sur Victoi?  ",
      body: "Les limites de transfert peuvent varier en fonction de votre type de compte, de votre niveau de vérification et des réglementations locales. Vous pouvez trouver des informations sur vos limites de transfert spécifiques dans les paramètres de votre compte Victoi.  ",
    },
    {
      icon: pointer,
      card: cards[2],
      head: "Combien de temps faut-il pour qu'un transfert d'argent soit effectué? ",
      body: "Le temps nécessaire pour effectuer un transfert d'argent peut varier en fonction de facteurs tels que la banque destinataire, le pays de destination et la méthode de transfert choisie. En règle générale, les virements dans la même devise sont traités plus rapidement que les virements internationaux. Vous pouvez trouver des temps de transfert estimés dans l'application ou sur notre site Web. Le temps de traitement approximatif sera affiché sur la page d'aperçu de chaque transaction. ",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Comment puis-je échanger des devises sur Victoi? ",
      body: "L'échange de devises sur Victoi est simple. Connectez-vous, sélectionnez l'option « Change de devises », choisissez les devises que vous souhaitez échanger, entrez le montant, choisissez le mode de paiement et confirmez l'échange. Suivez les instructions à l'écran pour terminer la transaction.  ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Quels sont les taux de change proposés par Victoi? ",
      body: "Victoi offre des taux de change compétitifs pour la conversion de devises. Nos tarifs sont mis à jour régulièrement afin d'assurer la transparence des opérations de change. Nous appliquons une marge (spread) pour maintenir la stabilité des taux. ",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Y a-t-il des frais pour le change de devises?  ",
      body: "Oui, il peut y avoir des frais associés au change de devises sur Victoi. Les frais spécifiques dépendent des devises concernées, du taux de change et du montant échangé. Vous pouvez consulter les détails des frais dans l'application ou sur notre site Web.  Les taux de change exacts et les frais seront affichés sur la page d'aperçu de chaque transaction avant la confirmation. ",
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Puis-je configurer des alertes de taux de change sur Victoi?",
      body: "Oui, vous pouvez configurer des alertes de taux de change sur Victoi pour recevoir des notifications lorsque le taux de change souhaité est atteint. Cette fonctionnalité vous aide à prendre des décisions éclairées concernant le change de devises. ",
      images: [],
    },
    {
      icon: pointer,
      card: cards[3],
      head: "Y a-t-il un montant minimum pour le change de devises? ",
      body: "Il peut y avoir un montant minimum requis pour le change de devises sur Victoi. Ce montant peut dépendre des devises concernées et des limites fixées par nos partenaires. Vous pouvez vérifier le montant minimum d'échange dans l'application ou sur notre site Web. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Qu'est-ce qu'un IBAN (Numéro de Compte Bancaire International) virtuel et comment fonctionne-t-il avec Victoi? ",
      body: " Un IBAN virtuel, ou Numéro de Compte Bancaire International, est un identifiant unique attribué à votre compte Victoi. Il vous permet d'envoyer et de recevoir des paiements dans différentes devises. Victoi met à votre disposition un IBAN virtuel, qui fait office de compte bancaire international personnel, permettant des transactions transfrontalières transparentes. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Comment obtenir un IBAN virtuel avec Victoi?   ",
      body: "Obtenir un IBAN virtuel avec Victoi est simple. Lorsque vous créez un compte Victoi, nous vous fournissons automatiquement un IBAN virtuel associé à votre compte.  ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Puis-je recevoir des paiements dans plusieurs devises à l'aide de mon IBAN virtuel? ",
      body: "Absolument! Votre IBAN virtuel Victoi prend en charge la réception de paiements dans différentes devises. Cette flexibilité vous permet d'effectuer facilement des transactions avec des clients et des partenaires internationaux. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Comment puis-je envoyer des paiements à l'aide de mon IBAN virtuel? ",
      body: "Vous pouvez utiliser votre IBAN virtuel Victoi pour envoyer des paiements comme un compte bancaire ordinaire. Il vous suffit de fournir les détails de votre IBAN virtuel au destinataire pour qu'il puisse initier le paiement. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Y a-t-il des frais associés à l'utilisation de mon IBAN virtuel?  ",
      body: " Victoi s'efforce de maintenir des frais compétitifs et transparents. Bien qu'il puisse y avoir des frais associés à des transactions spécifiques, nous visons à fournir des solutions rentables pour les paiements internationaux. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Puis-je retirer des fonds de mon IBAN virtuel vers mon compte bancaire local?  ",
      body: "Oui, vous pouvez transférer des fonds de votre IBAN virtuel Victoi vers votre compte bancaire local lié. Cette fonctionnalité vous permet d'accéder à vos fonds internationaux localement. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Mon IBAN virtuel est-il sécurisé?  ",
      body: "Oui, la sécurité est une priorité absolue chez Victoi. Votre IBAN virtuel est protégé par des mesures de sécurité robustes pour protéger vos informations financières et vos transactions. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Qu'est-ce qu'un compte bancaire virtuel et comment fonctionne-t-il sur Victoi? ",
      body: "Les comptes bancaires virtuels sur Victoi sont des comptes numériques qui vous permettent de détenir et de gérer plusieurs devises au sein de la plateforme. Vous pouvez utiliser ces comptes pour conserver des soldes, envoyer et recevoir des paiements en ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Combien de temps faut-il pour recevoir des fonds dans mon IBAN virtuel de sources internationales? ",
      body: "Le délai de réception des fonds sur votre IBAN virtuel peut varier en fonction de la banque de l'expéditeur et du mode de paiement choisi. Cependant, nous nous efforçons de rendre les transactions internationales aussi rapides que possible. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Puis-je utiliser mon IBAN virtuel à des fins professionnelles?  ",
      body: "Absolument! Votre IBAN virtuel Victoi convient aussi bien à un usage personnel qu'professionnel. Il s'agit d'un outil polyvalent permettant d'envoyer et de recevoir des paiements dans différentes devises à des fins diverses. ",
    },
    {
      icon: pointer,
      card: cards[4],
      head: "Y a-t-il une limite au nombre d'IBAN virtuels que je peux avoir avec Victoi?  ",
      body: "Actuellement, Victoi fournit un IBAN virtuel par compte utilisateur. Cependant, nous évaluons et mettons continuellement à jour nos fonctionnalités pour mieux répondre aux besoins de nos utilisateurs. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Quelles crypto-monnaies puis-je trader sur Victoi? ",
      body: " Victoi propose une gamme variée de crypto-monnaies pour le trading, y compris des options populaires comme Bitcoin (BTC), Ethereum (ETH) et des stablecoins comme USDT et USDC. Nous élargissons continuellement nos offres de crypto-monnaies pour vous offrir une large sélection. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Comment commencer à trader des crypto-monnaies sur Victoi? ",
      body: " Se lancer dans le trading de crypto-monnaies sur Victoi est simple. Créez un compte, effectuez la vérification nécessaire et déposez des fonds. Ensuite, vous pouvez commencer à trader en sélectionnant votre paire de crypto-monnaies préférée et en passant des ordres sur notre plateforme. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Le trading de crypto-monnaies sur Victoi est-il sécurisé? ",
      body: "  Oui, la sécurité est primordiale chez Victoi. Nous employons des mesures de sécurité avancées pour protéger vos actifs numériques et garantir un environnement de trading sécurisé. Notre équipe travaille avec diligence pour protéger vos investissements. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Y a-t-il des frais associés au trading de crypto-monnaies sur Victoi? ",
      body: "  Oui, comme la plupart des plateformes d'échange de crypto-monnaies, Victoi facture des frais de trading. Ces frais peuvent varier en fonction de la paire de crypto-monnaies et du volume de trading. Veuillez vous référer à notre grille tarifaire pour plus d'informations. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Puis-je trader des crypto-monnaies 24h/24 et 7j/7 sur Victoi? ",
      body: "  Oui, Victoi fournit un accès 24h/24 et 7j/7 aux marchés des crypto-monnaies. Vous pouvez trader à tout moment, ce qui vous permet de gérer vos investissements en cryptomonnaies. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Puis-je retirer des crypto-monnaies vers un portefeuille externe depuis mon compte Victoi? ",
      body: "  Absolument! Vous pouvez retirer vos crypto-monnaies vers un portefeuille externe de votre choix. Cela fournit une couche de sécurité supplémentaire pour vos actifs numériques. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Proposez-vous des ressources éducatives pour les débutants en trading de crypto-monnaies? ",
      body: "  Oui, Victoi s'engage à éduquer ses utilisateurs. Nous fournissons des ressources éducatives, y compris des articles et des guides, pour aider les débutants à comprendre les principes fondamentaux du trading de crypto-monnaies. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Comment puis-je me tenir au courant des tendances et des actualités du marché des crypto-monnaies? ",
      body: "  Nous offrons des données de marché en temps réel et des mises à jour d'actualités au sein de notre plateforme. Vous pouvez également explorer des sources externes pour obtenir des analyses et des informations approfondies sur le marché des crypto-monnaies. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Y a-t-il un montant minimum d'investissement pour le trading de crypto-monnaies sur Victoi? ",
      body: "  Le montant minimum d'investissement peut varier en fonction de la crypto-monnaie spécifique et des conditions du marché. Vous pouvez vérifier la taille minimale de l'ordre pour chaque paire de trading sur notre plateforme. ",
    },
    {
      icon: pointer,
      card: cards[5],
      head: "Quels sont les canaux d'assistance disponibles si j'ai des questions sur le trading de crypto-monnaies sur Victoi? ",
      body: "Nous offrons une assistance à la clientèle via divers canaux, notamment le chat en direct, l'assistance par e-mail et par téléphone. Notre équipe dédiée est là pour vous aider avec toutes les questions ou préoccupations que vous pourriez avoir concernant le trading de crypto-monnaies sur Victoi. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Qu'est-ce qu'une carte de débit virtuelle et comment fonctionne-t-elle avec Victoi? ",
      body: "Une carte de débit virtuelle est une représentation numérique d'une carte de débit physique. Il vous permet d'effectuer des achats et des transactions en ligne en toute sécurité. Avec Victoi, vous pouvez utiliser une carte de débit virtuelle pour faire des achats en ligne, payer des factures, etc., tout comme une carte physique. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Comment puis-je obtenir une carte de débit virtuelle auprès de Victoi? ",
      body: "Lorsque vous créez un compte Victoi, vous recevez automatiquement une carte de débit virtuelle. Vous pouvez trouver les détails de votre carte virtuelle dans le tableau de bord de votre compte. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Puis-je utiliser ma carte de débit virtuelle pour des achats en magasin? ",
      body: "Les cartes de débit virtuelles sont principalement conçues pour les transactions en ligne. Cependant, vous pouvez utiliser certaines applications de portefeuille numérique qui prennent en charge le provisionnement des cartes pour effectuer des paiements sans contact dans les magasins physiques.  ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Qu'est-ce qu'une carte de débit physique de Victoi et comment puis-je en obtenir une? ",
      body: "Une carte de débit physique est une carte tangible que vous pouvez utiliser pour vos achats en ligne et en magasin. Victoi s'efforce de fournir prochainement des cartes de débit physiques à nos utilisateurs. Vous pourrez en demander un via votre compte Victoi dès qu'ils seront disponibles. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Y a-t-il des frais associés à l'utilisation des cartes de débit virtuelles et physiques de Victoi? ",
      body: "Nous nous efforçons de maintenir notre structure tarifaire compétitive et transparente. Bien qu'il puisse y avoir des frais associés à des transactions spécifiques liées à la carte, telles que les retraits aux guichets automatiques ou les remplacements de cartes, nous visons à fournir des solutions rentables. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Puis-je lier ma carte de débit virtuelle ou physique à d'autres applications ou plateformes de paiement? ",
      body: "Oui, vous pouvez souvent lier vos cartes de débit virtuelles et physiques à diverses applications et plateformes de paiement qui prennent en charge l'intégration des cartes. Cela vous permet d'utiliser votre carte de débit Victoi dans toute une gamme de services. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Comment puis-je activer ma carte de débit physique de Victoi une fois que je l'ai reçue? ",
      body: "Une fois que vous avez reçu votre carte de débit physique, vous suivrez généralement les instructions d'activation fournies avec la carte. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Comment puis-je obtenir une carte de débit Visa virtuelle de Victoi? ",
      body: "Pour obtenir une carte de débit Visa virtuelle auprès de Victoi, connectez-vous à votre compte, accédez à la section « Cartes » et suivez les étapes pour demander une carte virtuelle. Une fois émis, vous pouvez l'utiliser pour des achats en ligne et en magasin. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Quels sont les avantages d'utiliser une carte de débit virtuelle de Victoi? ",
      body: "Les cartes de débit virtuelles de Victoi offrent commodité, sécurité et flexibilité. Ils peuvent être utilisés pour les achats en ligne, les services d'abonnement et d'autres transactions sans avoir besoin d'une carte physique. De plus, vous pouvez les lier à des comptes dans différentes devises pour des dépenses transparentes. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Puis-je lier ma carte de débit virtuelle à Apple Wallet et Google Wallet? ",
      body: "Oui, à partir de l'application victoi, vous pouvez ajouter votre carte virtuelle multi-usage à Apple Wallet et Google Wallet en quelques étapes faciles. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Puis-je utiliser ma carte de débit virtuelle ou physique pour effectuer des transactions internationales? ",
      body: "Oui, vous pouvez utiliser vos cartes de débit Victoi pour les transactions internationales, en ligne et en magasin, où les cartes sont acceptées. Veuillez noter que certains frais peuvent s'appliquer aux transactions internationales, il est donc conseillé de consulter notre grille tarifaire. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Quelles sont les mesures de sécurité mises en place pour protéger mes cartes de débit virtuelles et physiques avec Victoi? ",
      body: "Victoi prend la sécurité très au sérieux. Nous mettons en œuvre des protocoles de cryptage et d'autres protocoles de sécurité pour protéger les informations et les transactions de votre carte. De plus, vous pouvez configurer des contrôles de carte et des notifications pour plus de sécurité. ",
    },
    {
      icon: pointer,
      card: cards[6],
      head: "Comment signaler la perte ou le vol d'une carte de débit virtuelle ou physique de Victoi ? ",
      body: "En cas de perte ou de vol d'une carte, vous devez immédiatement contacter notre équipe d'assistance à la clientèle pour signaler le problème. Ils vous guideront à travers les étapes nécessaires pour protéger votre compte et commanderont une carte de remplacement si nécessaire. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Comment fonctionne le trading fractionné d'actions sur Victoi? ",
      body: "Le trading fractionné d'actions sur Victoi vous permet d'acheter et de vendre des fractions d'actions de la société, ce qui facilite l'investissement avec de plus petits montants de capital. Vous pouvez choisir l'action dans laquelle vous souhaitez investir, spécifier le montant que vous souhaitez acheter et exécuter la transaction. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Quelles sont les actions et les marchés disponibles pour le trading sur Victoi? ",
      body: "Victoi offre l'accès à une large gamme d'actions provenant de divers marchés et bourses. Vous pouvez explorer les actions et les marchés disponibles dans l'application ou sur notre site Web. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Y a-t-il des frais pour le trading d'actions fractionnées? ",
      body: "Oui, il peut y avoir des frais associés à la négociation d'actions fractionnées sur Victoi. Ces frais peuvent inclure des commissions et des spreads. Vous pouvez consulter les détails des frais dans la section « Trading » de votre compte Victoi. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Puis-je configurer des alertes en cas de variation du cours de l’action? ",
      body: "Oui, vous pouvez mettre en place des alertes pour les changements de cours de bourse sur Victoi. Cette fonctionnalité vous permet de recevoir des notifications lorsque le prix d'une action spécifique atteint un certain niveau, ce qui vous aide à prendre des décisions d'investissement en temps opportun. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Qu'est-ce que le trading fractionné d'actions et comment fonctionne-t-il sur Victoi? ",
      body: "La négociation fractionnée d'actions vous permet d'acheter et de posséder une fraction d'une action d'une société cotée en bourse, plutôt que d'acheter une action entière. Sur Victoi, cela signifie que vous pouvez investir dans des entreprises dont le cours de l'action est élevé en achetant une partie de leurs actions, ce qui les rend plus accessibles aux investisseurs ayant des budgets variables. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Mon investissement en fractions d'actions sur Victoi est-il sécurisé ? ",
      body: "Victoi privilégie la sécurité et utilise des mesures avancées pour protéger vos investissements. Nous utilisons des protocoles de cryptage et de sécurité pour protéger votre compte et vos actifs. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Puis-je acheter des fractions d'actions d'une société cotée en bourse sur Victoi ? ",
      body: "Bien que nous visions à fournir une large gamme de fractions d'actions, la disponibilité peut varier en fonction des sociétés répertoriées sur notre plateforme. Nous élargissons continuellement nos offres pour inclure plus d'options pour nos utilisateurs. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Comment se lancer dans le trading fractionné d'actions sur Victoi? ",
      body: "Pour commencer le trading de fractions d'actions sur Victoi, créez un compte, effectuez la vérification nécessaire et approvisionnez votre compte. Ensuite, vous pouvez commencer à explorer les fractions d'actions disponibles et faire vos investissements. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Existe-t-il un support pédagogique pour les débutants en trading d'actions fractionnées? ",
      body: "Victoi fournit des ressources éducatives, y compris des articles et des guides, pour aider les débutants à comprendre les principes fondamentaux du trading fractionné d'actions. Nous vous encourageons à explorer ces ressources pour améliorer vos connaissances en matière de trading. ",
    },
    {
      icon: pointer,
      card: cards[7],
      head: "Comment puis-je retirer des fonds de mon compte de trading d’actions? ",
      body: "Vous pouvez retirer des fonds de votre compte de trading d'actions en accédant à la section « Retrait » ou « Fonds » de votre compte Victoi. Suivez les instructions fournies pour initier un retrait. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Qu'est-ce que le change de devises et comment fonctionne-t-il sur Victoi? ",
      body: "Le change de devises, également connu sous le nom de forex ou FX, est le processus de conversion d'une devise en une autre à un taux de change convenu. Sur Victoi, vous pouvez échanger des devises pour faciliter les transactions internationales ou profiter de taux de change avantageux. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Quelles devises puis-je échanger sur Victoi? ",
      body: "Victoi propose une large gamme de devises à échanger, vous permettant de convertir entre différentes devises selon vos besoins pour vos transactions financières. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: " Comment initier un échange de devises sur Victoi? ",
      body: "Pour échanger des devises sur Victoi, connectez-vous à votre compte, accédez à la section de change de devises et suivez les instructions fournies. Spécifiez les devises que vous souhaitez échanger, le montant et confirmez la transaction. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Y a-t-il des frais associés au change de devises sur Victoi? ",
      body: "Oui, le change de devises peut impliquer des frais, et ces frais peuvent varier en fonction des devises et des taux de change spécifiques. Vous pouvez consulter notre grille tarifaire pour plus d'informations. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Puis-je mettre en place des alertes pour des taux de change avantageux sur Victoi? ",
      body: "Oui, Victoi fournit des alertes de taux de change, ce qui vous permet de surveiller les taux de change et de recevoir des notifications lorsque votre taux de change préféré est atteint. Cette fonctionnalité vous aide à prendre des décisions éclairées pour vos échanges de devises. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Combien de temps faut-il pour que les opérations de change soient effectuées sur Victoi? ",
      body: "Le délai de traitement des opérations de change peut varier en fonction de facteurs tels que les devises sélectionnées et les conditions du marché. Victoi s'efforce de traiter les échanges le plus rapidement possible, en vous offrant un accès rapide à vos fonds convertis. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Puis-je bloquer un taux de change à l'avance pour une future transaction sur Victoi? ",
      body: "Victoi propose des contrats à terme ou des ordres à cours limité qui vous permettent de bloquer un taux de change pour une transaction future. Cette fonctionnalité vous aide à gérer le risque de change et à obtenir des taux favorables à l'avance. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Mes informations de change et mes transactions sont-elles sécurisées sur Victoi? ",
      body: "Oui, Victoi accorde la priorité à la sécurité de vos informations et transactions de change. Nous utilisons des protocoles de cryptage et de sécurité pour protéger vos données et fournir un environnement de trading sécurisé. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Puis-je échanger de grosses sommes d'argent sur Victoi? ",
      body: "Victoi s'adresse à un large éventail d'utilisateurs, y compris ceux qui peuvent avoir besoin d'échanges de devises plus importants. La plateforme est conçue pour gérer différentes tailles de transactions, et vous pouvez contacter notre équipe d'assistance pour obtenir de l'aide pour les transactions importantes. ",
    },
    {
      icon: pointer,
      card: cards[8],
      head: "Existe-t-il un support pédagogique pour les débutants en change de devises sur Victoi? ",
      body: "Oui, Victoi fournit des ressources éducatives, y compris des articles et des guides, pour aider les débutants à comprendre les bases du change de devises. Nous vous encourageons à explorer ces ressources pour améliorer votre compréhension de la négociation d'actions. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Pourquoi dois-je effectuer une vérification CDD ? ",
      body: "La vérification CDD (Connaissance Du Client) est requise par la réglementation financière pour confirmer l'identité des utilisateurs et prévenir les activités frauduleuses ainsi que le blanchiment d'argent. La réalisation du CDD permet d'assurer la sécurité de notre plateforme. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Qu'est-ce que le CDD, et pourquoi est-il important sur Victoi ? ",
      body: "Le CDD, abréviation de « Connaissance Du Client », est un processus qui nous aide à vérifier l'identité de nos utilisateurs. Il est essentiel pour garantir la sécurité et l'intégrité de notre plateforme, prévenir la fraude et se conformer aux exigences réglementaires. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Comment effectuer le processus de vérification CDD sur Victoi ? ",
      body: "Pour réaliser la vérification CDD, connectez-vous à votre compte Victoi et rendez-vous dans la section « Vérification » ou « CDD ». Suivez les instructions données, qui incluent généralement la présentation de documents d'identité et la vérification de votre identité. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Combien de temps faut-il pour vérifier mon compte ? ",
      body: "Le délai nécessaire à la vérification de votre compte peut varier selon le volume de demandes de vérification et l'exactitude des informations fournies. En général, le processus de vérification est complété en quelques jours ouvrables. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Quels sont les documents requis pour la vérification CDD ? ",
      body: "Les documents spécifiques requis pour la vérification CDD peuvent varier selon les régions et les réglementations locales. Généralement, vous devrez fournir des documents d'identité officiels tels qu'un passeport, un permis de conduire ou une carte d'identité nationale, ainsi que des justificatifs de domicile. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Comment initier le processus de vérification CDD sur Victoi ? ",
      body: "Pour commencer le processus CDD, connectez-vous à votre compte Victoi et accédez à la section de vérification CDD. Suivez les instructions fournies pour soumettre vos documents d'identité et autres informations nécessaires. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Combien de temps dure généralement le processus de vérification CDD sur Victoi ? ",
      body: "Le temps de traitement de la vérification CDD peut varier, mais nous nous efforçons d'examiner et de vérifier les comptes des utilisateurs dans les plus brefs délais. Vous pouvez consulter l'état de votre vérification dans votre compte Victoi. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Mes informations personnelles sont-elles sécurisées pendant le processus CDD sur Victoi ? ",
      body: "Oui, vos informations personnelles sont traitées avec la plus grande sécurité et confidentialité. Nous utilisons des mesures solides de cryptage et de protection des données pour sauvegarder vos informations tout au long du processus CDD. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Qu'est-ce que la lutte contre le blanchiment d'argent et pourquoi Victoi met-elle en œuvre des procédures de LCB ? ",
      body: "LCB est l'abréviation de « Lutte Contre le Blanchiment d'argent », et il s'agit d'un ensemble de réglementations et de procédures destinées à détecter et prévenir le blanchiment d'argent et d'autres activités financières illicites. La mise en œuvre de mesures de LCB est cruciale pour maintenir l'intégrité de notre plateforme et se conformer aux obligations légales. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Quelles sont les conséquences du non-respect des exigences CDD et LCB sur Victoi ? ",
      body: "Le non-respect des procédures CDD et LCB peut entraîner des restrictions, suspensions ou fermetures de comptes. Il est important de terminer le processus de vérification et de respecter les directives de LCB pour garantir un accès continu à notre plateforme. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Puis-je mettre à jour mes informations CDD sur Victoi en cas de modification de mon identification ou de mes données personnelles ? ",
      body: "Oui, vous pouvez généralement mettre à jour vos informations CDD en contactant notre équipe d'assistance clientèle et en fournissant la documentation nécessaire pour les modifications. La mise à jour de vos informations CDD est essentielle pour l'exactitude et la conformité de votre compte. ",
    },
    {
      icon: pointer,
      card: cards[9],
      head: "Où puis-je trouver plus d'informations sur les politiques et procédures CDD et LCB de Victoi ? ",
      body: "Vous trouverez des informations détaillées sur les politiques et procédures de Victoi en matière de Connaissance Du Client (CDD) et de Lutte Contre le Blanchiment d'argent (LCB) dans nos conditions d'utilisation et notre politique de confidentialité. De plus, notre équipe d'assistance clientèle est disponible pour répondre à toutes vos questions ou préoccupations spécifiques liées à la conformité CDD et LCB. ",
    },
    {
      icon: pointer,
      card: cards[10],
      head: "Où puis-je trouver la liste complète des frais et charges de Victoi? ",
      body: "Vous pouvez trouver une liste complète des frais et charges de Victoi sur notre site Web ou dans l'application. Accédez à la section « Nos frais » pour accéder à ces informations. ",
    },
    {
      icon: pointer,
      card: cards[10],
      head: "Y a-t-il des frais cachés sur Victoi? ",
      body: "Victoi s'engage à faire preuve de transparence et n'a pas de frais cachés. Tous les frais applicables sont clairement indiqués dans notre barème des frais, que vous pouvez consulter avant d'initier des transactions. ",
    },
    {
      icon: pointer,
      card: cards[10],
      head: "Comment les taux de change sont-ils déterminés pour le change de devises? ",
      body: "Les taux de change pour le change de devises sur Victoi sont déterminés sur la base des taux du marché et peuvent inclure un petit écart pour couvrir les coûts opérationnels. Notre objectif est d'offrir des tarifs compétitifs tout en assurant la stabilité de notre service.’ ",
    },
    {
      icon: pointer,
      card: cards[11],
      head: "Est-ce que Victoi fournit des déclarations fiscales pour mes transactions?  ",
      body: "Oui, Victoi fournit l'historique des transactions et des rapports qui peuvent être utilisés à des fins fiscales. Vous pouvez accéder à ces rapports au sein de votre compte Victoi.  ",
    },
    {
      icon: pointer,
      card: cards[11],
      head: "Comment puis-je accéder à l'historique de mes transactions à des fins fiscales? ",
      body: "Pour accéder à l'historique de vos transactions fiscales, connectez-vous à votre compte Victoi et accédez à la section « Historique des transactions » ou « Rapports ». Vous pouvez générer des rapports et exporter des données de transaction pour vos dossiers. ",
    },
    {
      icon: pointer,
      card: cards[11],
      head: "Quelles sont les incidences fiscales auxquelles je dois faire attention lorsque j'utilise Victoi? ",
      body: "Les incidences fiscales peuvent varier en fonction de votre localisation et de la nature de vos activités financières sur Victoi. Il est conseillé de consulter un fiscaliste ou un comptable pour comprendre vos obligations fiscales particulières. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Comment puis-je déposer des fonds sur mon compte Victoi?   ",
      body: "Vous pouvez déposer des fonds sur votre compte Victoi en sélectionnant l'option « Dépôt » dans l'application ou le site Web. Nous prenons en charge diverses méthodes de dépôt, notamment les virements bancaires, les paiements par carte de crédit/débit, les portefeuilles mobiles et les dépôts de crypto-monnaies. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Quelles sont les méthodes de dépôt et de retrait proposées par Victoi pour l'approvisionnement et l'accès à mon compte? ",
      body: "Victoi offre plusieurs options pour déposer et retirer des fonds, y compris des comptes bancaires, des cartes de débit et des portefeuilles mobiles. Ces méthodes polyvalentes vous permettent de gérer votre compte en toute commodité. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Comment puis-je effectuer un dépôt sur mon compte Victoi à l'aide d'un compte bancaire? ",
      body: "Pour déposer des fonds à partir de votre compte bancaire, connectez-vous à votre compte Victoi, accédez à la section Dépôt et suivez les instructions fournies. Vous devrez lier votre compte bancaire et spécifier le montant que vous souhaitez déposer. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Puis-je utiliser ma carte de débit pour approvisionner mon compte Victoi? ",
      body: "Oui, vous pouvez approvisionner votre compte Victoi à l'aide d'une carte de débit. Il vous suffit de vous rendre dans la section des dépôts de votre compte, de sélectionner l'option de carte de débit et de suivre les étapes pour effectuer la transaction. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Y a-t-il des frais associés au dépôt de fonds sur mon compte Victoi? ",
      body: "Bien que Victoi s'efforce de maintenir des frais compétitifs et transparents, certains fournisseurs ou institutions financières peuvent vérifier les frais lors du dépôt sur victoi. Vous pouvez vérifier auprès de votre institution financière et/ou de victoi les frais applicables. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Combien de temps faut-il pour que les fonds déposés soient crédités sur mon compte Victoi? ",
      body: "Le délai de traitement des dépôts peut varier en fonction de la méthode choisie. Les virements bancaires peuvent prendre quelques jours ouvrables, tandis que les dépôts par carte de débit sont généralement crédités plus rapidement. Vous pouvez consulter les délais de traitement estimés dans votre compte Victoi. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Puis-je retirer des fonds de mon compte Victoi vers mon compte bancaire ou ma carte de débit? ",
      body: "Oui, vous pouvez retirer des fonds de votre compte Victoi vers votre compte bancaire ou votre carte de débit lié. Pour effectuer un retrait, rendez-vous dans la section de retrait de votre compte et suivez les instructions fournies. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Y a-t-il des frais de retrait pour transférer des fonds de mon compte Victoi vers mon compte bancaire ou ma carte de débit? ",
      body: "Des frais de retrait peuvent s'appliquer à des méthodes de retrait spécifiques, en fonction de l'institution financière ou du fournisseur de paiement choisi. Vous trouverez des informations détaillées sur les frais de retrait dans notre grille tarifaire. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Puis-je utiliser des portefeuilles mobiles pour déposer et retirer des fonds sur Victoi? ",
      body: "Oui, vous pouvez utiliser les portefeuilles mobiles comme méthode pratique de dépôt et de retrait sur Victoi. Il vous suffit de choisir l'option de portefeuille mobile lorsque vous effectuez un dépôt ou que vous effectuez un retrait et de suivre les instructions fournies. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Comment puis-je vérifier l'état de mes dépôts et retraits sur Victoi? ",
      body: "Vous pouvez facilement suivre l'état de vos dépôts et retraits au sein de votre compte Victoi. Nous fournissons des mises à jour et des notifications en temps réel pour vous tenir informé de l'avancement de vos transactions. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Mes informations financières sont-elles sécurisées lorsque j'utilise les méthodes de dépôt et de retrait sur Victoi? ",
      body: "Oui, Victoi accorde la priorité à la sécurité de vos informations financières. Nous utilisons des mesures de cryptage et de sécurité avancées pour protéger votre compte et vos transactions, garantissant ainsi une expérience sûre et sécurisée. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Quelles sont les méthodes de retrait disponibles sur Victoi? ",
      body: "Victoi propose plusieurs méthodes de retrait, notamment les virements bancaires, les retraits par carte et les retraits de crypto-monnaies. Vous pouvez choisir la méthode qui correspond le mieux à vos préférences. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Y a-t-il des limites de retrait? ",
      body: "Les limites de retrait peuvent varier en fonction de votre type de compte, de votre niveau de vérification et des réglementations locales. Vous pouvez consulter vos limites de retrait spécifiques dans les paramètres de votre compte Victoi. ",
    },
    {
      icon: pointer,
      card: cards[12],
      head: "Combien de temps faut-il pour que les retraits soient traités? ",
      body: "Le délai de traitement des retraits sur Victoi peut varier en fonction du mode de retrait et de la destination. Les virements bancaires et les retraits par carte peuvent prendre quelques jours ouvrables, tandis que les retraits de crypto-monnaies sont généralement traités plus rapidement. ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "Est-ce que Victoi offre un programme de parrainage? ",
      body: "Oui, Victoi propose un programme de parrainage qui vous permet de parrainer des amis et de gagner des récompenses pour les parrainages réussis. Vous pouvez trouver des détails sur notre programme de parrainage dans l'application ou sur notre site Web. ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "Comment puis-je parrainer des amis sur Victoi et gagner des récompenses? ",
      body: "Pour parrainer des amis sur Victoi, connectez-vous à votre compte et accédez à la section « Gagner ». Vous pouvez générer des liens de parrainage ou des codes à partager avec vos contacts. Lorsqu'une personne s'inscrit à l'aide de votre lien de parrainage et répond aux critères de parrainage, vous gagnez des récompenses. ",
    },
    {
      icon: pointer,
      card: cards[13],
      head: "Quelles récompenses puis-je gagner grâce au programme de parrainage? ",
      body: "Les récompenses offertes dans le cadre du programme de parrainage peuvent inclure des primes en espèces, des réductions sur les frais ou d'autres incitations. Les récompenses spécifiques et les critères d'éligibilité sont décrits dans les détails du programme de parrainage sur notre site Web et dans l'application. ",
    },
    {
      icon: pointer,
      card: cards[14],
      head: "Quelles sont les conditions d'utilisation et la politique de confidentialité de Victoi? ",
      body: "Les conditions d'utilisation et la politique de confidentialité de Victoi décrivent les conditions générales d'utilisation de notre plateforme et la manière dont nous traitons les données des utilisateurs. Vous pouvez consulter ces documents sur notre site Web ou dans l'application. ",
    },
    {
      icon: pointer,
      card: cards[14],
      head: "Comment Victoi respecte-t-elle les réglementations en matière de Lutte Contre le Blanchiment d'Argent (LCB) ? ",
      body: "Victoi adhère aux réglementations de Lutte Contre le Blanchiment d'Argent en instaurant des procédures rigoureuses de Connaissance Du Client (CDD), en effectuant une surveillance minutieuse des transactions pour identifier toute activité inhabituelle et en rapportant les opérations suspectes aux autorités appropriées. Nos pratiques de conformité en LCB sont en accord avec les standards de l'industrie et les réglementations en vigueur au niveau local. ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "Quelle est la politique de Victoi en matière d'activités frauduleuses et d’escroqueries? ",
      body: "Victoi a une politique stricte contre les activités frauduleuses et les escroqueries. Nous prenons toutes les mesures nécessaires pour détecter et prévenir les transactions et activités frauduleuses sur notre plateforme. Si vous soupçonnez une activité frauduleuse, veuillez la signaler immédiatement à notre équipe d'assistance à la clientèle. ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "Que dois-je faire si je rencontre des problèmes techniques sur la plateforme Victoi? ",
      body: "Si vous rencontrez des problèmes techniques sur la plateforme Victoi, vous pouvez contacter notre équipe d'assistance à la clientèle pour obtenir de l'aide. Nous disposons d'une équipe d'assistance dédiée prête à vous aider à résoudre tout problème technique. ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "Comment puis-je réinitialiser mon authentification à deux facteurs si j'en perds l'accès? ",
      body: "Si vous perdez l'accès à votre méthode d'authentification à deux facteurs, telle qu'une application mobile ou un jeton matériel, vous pouvez contacter notre équipe d'assistance à la clientèle pour obtenir de l'aide afin de réinitialiser votre authentification à 2 facteurs et de retrouver l'accès à votre compte. ",
    },
    {
      icon: pointer,
      card: cards[15],
      head: "Victoi est-il disponible sur les appareils mobiles? Quelles sont les plates-formes prises en charge? ",
      body: "Oui, Victoi est disponible sur les appareils mobiles. Vous pouvez télécharger l'application Victoi depuis l'App Store (iOS) ou le Google Play Store (Android) pour accéder à nos services. De plus, vous pouvez utiliser le site Web de Victoi sur les navigateurs de bureau et mobiles. ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Pourquoi mon compte Victoi a-t-il été temporairement suspendu? ",
      body: "La suspension temporaire du compte peut survenir pour diverses raisons, notamment des problèmes de sécurité, une activité frauduleuse présumée, le non-respect de nos Conditions d'utilisation ou une enquête en cours sur des problèmes liés au compte. ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Combien de temps dure généralement une suspension temporaire de compte sur Victoi? ",
      body: "La durée d'une suspension temporaire du compte varie en fonction des circonstances spécifiques et des mesures nécessaires pour résoudre le problème. Elle peut aller de quelques heures à plusieurs jours. ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Puis-je toujours accéder à mon compte pendant qu'il est temporairement suspendu? ",
      body: "Dans la plupart des cas, vous pouvez toujours accéder à votre compte Victoi pendant qu'il est temporairement suspendu, mais avec des fonctionnalités restreintes. Vous pourrez peut-être consulter les informations de votre compte et contacter le service client pour obtenir de l'aide. ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Comment puis-je résoudre une suspension temporaire de compte sur Victoi? ",
      body: "Pour résoudre une suspension temporaire du compte, suivez les instructions fournies dans la notification par e-mail ou dans le message du tableau de bord du compte. Il peut s'agir d'effectuer des étapes de vérification supplémentaires ou de résoudre le problème spécifique qui a conduit à la suspension. ",
    },
    {
      icon: pointer,
      card: cards[16],
      head: "Puis-je faire appel d'une décision de suspension temporaire de compte sur Victoi? ",
      body: "Oui, vous pouvez généralement faire appel d'une décision de suspension temporaire de votre compte en contactant notre équipe d'assistance à la clientèle. Fournissez toute information ou documentation pertinente à l'appui de votre cas, et notre équipe l'examinera en conséquence. ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Pourquoi mon compte Victoi a-t-il été définitivement résilié? ",
      body: "La résiliation définitive du compte peut survenir en cas de violations graves de nos conditions d'utilisation, de non-respect répété des politiques de la plateforme ou d'activités illégales sur la plateforme. Victoi prend ces mesures pour protéger ses utilisateurs et maintenir un environnement sûr. ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Existe-t-il un moyen d'annuler une résiliation définitive de compte sur Victoi? ",
      body: "Dans la plupart des cas, les décisions de résiliation définitive du compte sont définitives. Cependant, vous pouvez contacter notre équipe d'assistance à la clientèle pour discuter de votre situation et vous renseigner sur la possibilité de rétablissement de compte. Cela dépendra des circonstances particulières. ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Puis-je créer un nouveau compte sur Victoi si mon compte précédent a été définitivement résilié? ",
      body: "La création d'un nouveau compte après une résiliation définitive va généralement à l'encontre de nos politiques. Les utilisateurs sont encouragés à résoudre les problèmes de compte par les canaux appropriés et à rechercher une solution plutôt que d'essayer de créer un nouveau compte. ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Y a-t-il des frais associés à la suspension temporaire ou à la résiliation définitive du compte sur Victoi? ",
      body: "Victoi ne facture généralement pas de frais pour ces actions. Toutefois, si la suspension ou la résiliation est liée à des mesures spécifiques ou à des problèmes de non-conformité, il peut y avoir des coûts associés. ",
    },
    {
      icon: pointer,
      card: cards[17],
      head: "Où puis-je trouver plus d'informations sur les politiques et procédures de Victoi relatives à la suspension et à la résiliation de compte? ",
      body: "Vous pouvez consulter nos Conditions d'utilisation et notre Politique de confidentialité pour obtenir des informations détaillées sur les politiques et procédures de Victoi, y compris celles relatives à la suspension et à la résiliation de compte. Si vous avez des questions ou des préoccupations spécifiques, notre équipe d'assistance à la clientèle est disponible pour vous aider. ",
    },
    {
      icon: pointer,
      card: cards[18],
      head: " Comment puis-je accéder à l'assistance par chat dans l'application sur Victoi? ",
      body: "L'assistance par chat dans l'application est disponible pour les utilisateurs de Victoi au sein de l'application mobile ou de la plate-forme Web. Il vous suffit de vous rendre dans la section « Support client » et vous trouverez des options pour lancer une conversation par chat avec notre équipe d'assistance. ",
    },
    {
      icon: pointer,
      card: cards[18],
      head: "Quelles sont les heures d'ouverture de l'assistance par chat dans l’application? ",
      body: "Notre assistance par chat intégrée à l'application est généralement disponible pendant nos heures d'ouverture standard, qui peuvent varier selon les régions. Vous pouvez vérifier la disponibilité du chat dans l'application pour obtenir les informations les plus récentes. ",
    },
    {
      icon: pointer,
      card: cards[18],
      head: " Combien de temps faut-il généralement pour recevoir une réponse dans le chat intégré à l’application? ",
      body: "Nous nous efforçons de fournir des réponses rapides aux demandes de chat dans l'application. Le temps de réponse peut varier en fonction du volume de demandes, mais notre objectif est de vous aider le plus rapidement possible. ",
    },
    {
      icon: pointer,
      card: cards[19],
      head: " Comment puis-je contacter l'assistance par e-mail de Victoi? ",
      body: "Vous pouvez contacter notre équipe d'assistance par e-mail en support@victoi.com envoyant vos demandes ou vos préoccupations. Veuillez fournir des informations détaillées pour nous aider à vous aider efficacement. ",
    },
    {
      icon: pointer,
      card: cards[19],
      head: "Quel est le temps de réponse habituel pour les demandes par e-mail? ",
      body: "Notre équipe d'assistance par e-mail s'efforce de répondre aux demandes dans un délai raisonnable, généralement dans les 24 à 48 heures. Veuillez noter que les délais de réponse peuvent varier en fonction de la complexité de la demande. ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: " Comment puis-je utiliser le formulaire de contact du site web pour joindre l'équipe d'assistance de Victoi? ",
      body: "Vous pouvez accéder au formulaire de contact du site Web en visitant notre site officiel à l'adresse www.victoi.com. Recherchez la section « Contactez-nous », où vous pouvez remplir le formulaire avec votre nom, votre adresse e-mail, votre objet et votre message. ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Le formulaire de contact du site est-il disponible 24h/24 et 7j/7? ",
      body: "Le formulaire de contact du site Web est généralement accessible 24 heures sur 24 et 7 jours sur 7, ce qui vous permet de soumettre des demandes de renseignements ou des demandes à tout moment. Cependant, les délais de réponse peuvent varier en fonction de la date de réception de la demande. ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Pour quels types de demandes ou de problèmes puis-je contacter l'équipe d'assistance de Victoi? ",
      body: "Vous pouvez contacter notre équipe d'assistance pour un large éventail de questions, y compris les questions relatives au compte, les problèmes techniques, l'assistance aux transactions et l'assistance générale à l'utilisation de la plateforme Victoi. ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Le support client est-il disponible en plusieurs langues? ",
      body: "Victoi vise à fournir une assistance en plusieurs langues pour répondre aux besoins de notre base d'utilisateurs diversifiée. La disponibilité des langues peut varier en fonction de votre région. ",
    },
    {
      icon: pointer,
      card: cards[20],
      head: "Où puis-je trouver des ressources supplémentaires ou des guides d'auto-assistance si j'ai une question ou un problème courant? ",
      body: "Victoi fournit un centre d'aide ou une section FAQ dans l'application et sur notre site Web, où vous pouvez trouver des réponses aux questions courantes et accéder à des guides utiles. Ces ressources peuvent souvent répondre aux demandes courantes sans qu'il soit nécessaire de contacter l'assistance. ",
    },
  ];
  const popularfrench = [
    qafrench[12],
    qafrench[7],
    qafrench[13],
    qafrench[17],
    qafrench[2],
    qafrench[4],
  ];

  const [reset, setreset] = useState("");
  const [search, setsearch] = useState("");
  const [head, sethead] = useState("");
  const [suggest, setsuggest] = useState(true);
  const [qabody, setqabody] = useState([]);
  const [relatedkeywords, setrelatedkeywords] = useState("");
  const [popular, setPopular] = useState(popularenglish);

  // language switching
  useEffect(() => {
    setCards(siteLanguage == "ENGLISH" ? cardsenglish : cardsfrench);
    setQa(siteLanguage == "ENGLISH" ? qaenglish : qafrench);
    setPopular(siteLanguage == "ENGLISH" ? popularenglish : popularfrench);
    // alert(siteLanguage)
  }, [siteLanguage, search]);

  useEffect(() => {
    // let temp = qa.filter((q) => { return (q.body.toLowerCase().includes(search.toLowerCase()) || q.head.toLowerCase().includes(search.toLowerCase())) || q?.card?.head?.toLowerCase()?.includes(search.toLowerCase()) })
    let temp = qa.filter((q) => {
      return (
        q.head.toLowerCase().includes(search.toLowerCase()) ||
        q?.card?.head?.toLowerCase()?.includes(search.toLowerCase())
      );
    });
    let headercheck = true;
    // console.log(qa)
    let prevheader = "";
    setqabody([]);
    for (let i = 0; i < temp.length; i++) {
      headercheck = true;
      const element = temp[i];
      // console.log("-----------------")
      // console.log(element?.card?.head)
      // console.log(prevheader)
      if (prevheader != element?.card?.head) {
        prevheader = element?.card?.head;
        setqabody((p) => [...p, { q: element, headercheck: true }]);
        headercheck = true;
      } else {
        headercheck = false;
        setqabody((p) => [...p, { q: element, headercheck: false }]);
      }
    }
  }, [search, siteLanguage]);
  /** filters an array to the lenght of the specified limit */
  function limitArray(array, limit) {
    if (!Number(limit) || !limit) {
      return array;
    }
    let newArr = [];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (i < limit) {
        newArr.push(element);
      }
    }
    return newArr;
  }
  function returnWithColor(text) {
    let textimage = text.toLowerCase();
    let searchimage = search.toLocaleLowerCase();
    let start = "";
    let end = "";
    let middle = "";
    let result = "";
    if (textimage.includes(searchimage)) {
      start = text.substr(0, textimage.indexOf(searchimage));
      middle =
        "<span>" +
        text.substr(textimage.indexOf(searchimage), searchimage.length) +
        "  </span>";
      end = text.substr(textimage.indexOf(searchimage) + searchimage.length);
      // adding spaces
      end = search[search.length - 1] == " " ? ` <div></div> ` + end : end;
      start =
        text[textimage.indexOf(searchimage) - 1] == " "
          ? start + "<div></div>"
          : start;
      end =
        text[textimage.indexOf(searchimage) + searchimage.length] == " "
          ? `<div></div>  ` + end
          : end;
      return start + middle + " " + end;
      // replace(search.toLocaleLowerCase(),'<span>'+search+'</span>')
    }
    return "";
  }
  function advancedSearch(array, sentence) {
    let newArr = [];

    //splitting sentence into word array
    const words = sentence.trim().toLowerCase().split(" ");

    //comparing and recreating array
    for (let i = 0; i < array.length; i++) {
      let matches = [];
      let count = 0;
      const element = array[i];
      for (let ii = 0; ii < words.length; ii++) {
        // ignoring short text (three letters)
        const element2 = words[ii];
        if (
          element.head.toLowerCase().includes(element2) &&
          element2.length >= 4
        ) {
          count++;
          matches.push(element2);
        }
      }
      if (count > 1 && element.head.toLowerCase() != sentence.toLowerCase()) {
        newArr.push({ ...element, count: count, mathes: matches });
      }
    }
    // console.log(newArr)
    let finalArr = [];
    //sorting result array (newArr) into final array (finalArr)
    for (let index = 0; index < newArr.length; index++) {
      const element = newArr[index];
      let large = 0;
      for (let index2 = 0; index2 < newArr.length; index2++) {
        const element = newArr[index2];
        if (!finalArr.includes(element)) {
          if (element.count >= large) {
            large = element.count;
            finalArr.push(element);
            //stopping when result has 20 entries
            if (finalArr.length >= 15) {
              console.log("----up to 15 entries----");
              console.log(finalArr);
              return finalArr;
            }
          }
        }
      }
    }
    console.log("******  and return ****");
    console.log(finalArr);
    return finalArr;
  }
  function setheadcheck(q) {
    let prev = head;
    let setheadcheck = true;

    if (prev != q?.card?.head) {
      // sethead(q?.card?.head)
      sethead(q?.card?.head);
    } else {
      setheadcheck = false;
    }
    console.log("-----------------");
    console.log(setheadcheck);
    return setheadcheck ? q?.card?.head + "bbb" : "a";
  }
  return (
    <div className="" style={{ position: "relative" }} id="parentContainer">
      <div class=" A-input   pt-1  pb-3">
        <h2 className="mt-5 mb-0">{t("Victoi Help Center")}</h2>
        {/* {search == "" && <> */}
        <p className=" mt-0 mb-5">{t("FAQ's, Guides & Support")}</p>
        {/* </>} */}

        <div class="input-group my-3 mx-auto">
          {suggest && search != "" && (
            <div className="suggestion f00">
              {limitArray(qabody, 5).map((q) =>
                returnWithColor(q.q.head) != "" ? (
                  <div className="d-flex align-items-center ">
                    <img src={pointer} className="mx-2 " />{" "}
                    <div
                      onClick={() => {
                        setsuggest(false);
                        setsearch(q.q.head.trim());
                        setrelatedkeywords(
                          q.q.head
                            .trim()
                            .substr(0, Math.floor(q.q.head.length / 4))
                        );
                      }}
                      dangerouslySetInnerHTML={{
                        __html: returnWithColor(q.q.head),
                      }}
                      className="item px-2 my-1 "
                    ></div>
                  </div>
                ) : (
                  ","
                )
              )}
              {qabody.length == 0 && (
                <div className="nothing">
                  {siteLanguage == "ENGLISH"
                    ? "No item matches your search"
                    : "Aucun article ne correspond à votre recherche"}
                </div>
              )}
            </div>
          )}
          <input
            type="text"
            onSelect={() => setsuggest(true)}
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setsuggest(true);
            }}
            class="form-control"
            placeholder={
              siteLanguage == "ENGLISH"
                ? "Type question here"
                : "Entrez votre question ici"
            }
          />
          {search != "" && (
            <span
              onClick={() => {
                setsearch("");
                setsuggest(true);
              }}
              class="input-group-text"
            >
              <Clear style={{ color: "var(--green)" }} />
            </span>
          )}
          <span class="input-group-text">
            <img src={searchicon} alt="search icon" />
          </span>
        </div>
      </div>
      <div
        className="smallCardContainer  row  px-4 "
        style={{ position: "relative" }}
      >
        {!suggest && (
          <div
            className="floating f00"
            onClick={() => {
              setsearch("");
              window.scrollTo(0, 0);
              setsuggest(true);
            }}
          >
            <div className="floaticon float-left">
              <ArrowBack />
            </div>{" "}
            <div className="floatdiv">
              {siteLanguage == "ENGLISH" ? "Go back" : "Retourner"}
            </div>
          </div>
        )}
        {/* {cards.filter((card) => card.text.includes(search)).map((card) => */}
        {suggest &&
          cards.map((card) => (
            <Fade up>
              <div
                className="col  d-flex justify-content-center   p-0  mx-auto my-2 "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setrelatedkeywords("");
                  setsearch(card.head);
                  setsuggest(false);
                  window.scrollTo(0, 0);
                }}
              >
                <div className=" m-1  ">
                  <SmallCard2
                    Img1={card.img}
                    icon={card?.icon}
                    head1={card.head.replace(":", "")}
                    // text1={card.text}
                    text1={""}
                  />
                </div>
              </div>
            </Fade>
          ))}
      </div>

      <div class=" A-popular-topics f1 ">
        <div className="row f1 my-3">
          {!suggest &&
            search != "" &&
            qabody.map((q) => {
              return (
                <>
                  {q.headercheck && (
                    <h3
                      className="mb-5"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setrelatedkeywords("");
                        setsearch(q?.q?.card?.head);
                        setsuggest(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      {q.q?.card?.head}
                    </h3>
                  )}
                  <Fade bottom>
                    <div className="col  m-2 d-flex align-items-start justify-content-center">
                      <Expandible
                        reset={reset}
                        setreset={setreset}
                        search={search}
                        icon={pointer}
                        header={q.q.head}
                        body={q.q.body}
                      />
                    </div>
                  </Fade>
                </>
              );
            })}

          {/* {relatedkeywords != "" && !suggest && qa.filter((f) => (f.head.includes(relatedkeywords) && !f.head.includes(search))).length > 0 && <h2>Related Topics</h2>}


{relatedkeywords != "" && !suggest && qa.filter((f) => (f.head.includes(relatedkeywords) && !(f.head.includes(search)))).map((q) => <Fade bottom>
    <div className='col  m-4 d-flex align-items-start justify-content-start'>
        <Expandible reset={reset} setreset={setreset} icon={pointer} header={q.head} body={q.body} />
    </div></Fade>
)} */}
          {relatedkeywords != "" &&
            !suggest &&
            advancedSearch(qa, search).length > 0 && (
              <h2>
                {siteLanguage == "ENGLISH"
                  ? "Related Topics"
                  : "Rubriques connexes"}
              </h2>
            )}

          {relatedkeywords != "" &&
            !suggest &&
            advancedSearch(qa, search).map((q) => (
              <Fade bottom>
                <div className="col  m-2 d-flex align-items-start justify-content-start">
                  <Expandible
                    reset={reset}
                    setreset={setreset}
                    icon={pointer}
                    header={q.head}
                    body={q.body}
                  />
                </div>
              </Fade>
            ))}
        </div>
        {search == "" && (
          <h2>
            {siteLanguage == "ENGLISH" ? "Popular Topics" : "Sujets populaires"}
          </h2>
        )}
        {/* {search == "" && <h2>Popular Topics</h2>} */}
        <div className="row f1 my-1">
          {search == "" &&
            popular
              .filter((q) => {
                return search == "" || true;
              })
              .map((q) => {
                return (
                  <Fade bottom>
                    <div className="col mx-auto m-2 d-flex align-items-start justify-content-center">
                      <Expandible
                        reset={reset}
                        setreset={setreset}
                        icon={pointer}
                        header={q.head}
                        body={q.body}
                      />
                    </div>
                  </Fade>
                );
              })}
          {/* {search=="" && <h2>QA</h2>} */}
        </div>
      </div>
    </div>
  );
}

export default Support;
