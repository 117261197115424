import React, { useEffect, useState } from 'react'
import classes from '../KYCcomponent/docs.module.css'

export default function Cookie({ siteLanguage }) {
      const [i, seti] = useState()
      const English = () => (
            <div className={classes.container + ' mx-auto'}>
                  <h2 className='text-center'>                 <b> Cookie Policy for Victoi</b>
                  </h2>
                  <div className='mx-4 f1 my-5'>
                        Last Updated: 22/12/2023
                  </div>
                  <div className='mx-4 f1 my-5'>

                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Introduction
                        </div>
                        <div className='f00 my-2'>

                              Victoi respects your privacy and is committed to protecting your personal data. This Cookie Policy explains how Victoi ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website at www.victoi.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                        </div>
                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              What are cookies?
                        </div>
                        <div className='f00 my-2'>
                              Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.                   </div>

                        <div className='f00 my-2'>
                              Cookies set by the website owner (in this case, Victoi) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                        </div>
                  </div>





                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Why do we use cookies?                         </div>
                        <div className='f00 my-2'>
                              We use first party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Website. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.
                        </div>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Types of cookies used on our Website:                         </div>

                        <ol>

                              <li className='my-4 f00' >

                                    <b>Essential Cookies:</b> These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.

                              </li>
                              <li className='my-4 f00' >

                                    <b>Performance and Functionality Cookies:</b> These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.                               </li>
                              <li className='my-4 f00' >

                                    <b>Analytics and Customization Cookies:</b> These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Advertising Cookies:</b> These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Social Networking Cookies:</b> These cookies are used to enable you to share pages and content that you find interesting on our Website through third-party social networking and other websites. These cookies may also be used for advertising purposes too.
                              </li>

                        </ol>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              How often will you update this Cookie Policy?                         </div>

                        <div className='f00 my-2'>

                              We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
                        </div>




                  </div>



                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Where can I get further information?
                        </div>

                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className='f00 my-2'>
                              If you have any questions about our use of cookies or other technologies, please email us at <a href='/support'>support@victoi.com.</a>
                        </div>
                  </div>





            </div>
      )
      const French = () => (
            <div className={classes.container + ' mx-auto'}>
                  <h2 className='text-center'>                 <b> Politique de Cookies </b>
                  </h2>
                  <div className='mx-4 f1 my-5'>
                        Dernière mise à jour : 22/12/2023
                  </div>
                  <div className='mx-4 f1 my-5'>

                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Introduction
                        </div>
                        <div className='f00 my-2'>

                              Chez Victoi, nous respectons votre vie privée et nous nous engageons à protéger vos données personnelles. Cette politique de cookies explique comment Victoi (« nous », « notre » ou « nos ») utilise des cookies et des technologies similaires lorsque vous visitez notre site web www.victoi.com (« le Site »). Elle décrit ces technologies et pourquoi nous les utilisons, ainsi que vos droits pour contrôler notre utilisation de ces dernières.
                        </div>
                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Qu'est-ce qu'un cookie ?
                        </div>
                        <div className='f00 my-2'>
                              Un cookie est un petit fichier de données placé sur votre ordinateur ou appareil mobile lors de la visite d'un site web. Les cookies sont largement utilisés pour permettre aux sites web de fonctionner, ou de fonctionner plus efficacement, et pour fournir des informations aux propriétaires du site.                          </div>

                        <div className='f00 my-2'>
                              Les cookies établis par le propriétaire du site (dans ce cas, Victoi) sont appelés « cookies de première partie ». Les cookies établis par des tiers autres que le propriétaire du site sont appelés « cookies tiers ». Les cookies tiers permettent de fournir des fonctionnalités ou des fonctionnalités tierces sur ou via le site web (e.g., publicité, contenu interactif, analyse). Les tiers qui placent ces cookies tiers peuvent reconnaître votre appareil lorsqu'il visite le site en question et aussi lorsqu'il visite certains autres sites.
                        </div>
                  </div>





                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Pourquoi utilisons-nous des cookies ?                                                 </div>
                        <div className='f00 my-2'>
                              Nous utilisons des cookies de première partie et tiers pour plusieurs raisons. Certains cookies sont nécessaires pour des raisons techniques afin que notre Site fonctionne, et nous les appelons « essentiels » ou « strictement nécessaires ». D'autres cookies nous permettent de suivre et cibler les intérêts de nos utilisateurs pour améliorer l'expérience sur notre Site. Des tiers placent des cookies sur notre Site pour la publicité, l'analyse et d'autres fins. Ceci est expliqué plus en détail ci-dessous.                   </div>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Types de cookies utilisés sur notre Site :                                     </div>

                        <ol>

                              <li className='my-4 f00' >

                                    <b>Cookies Essentiels :</b> Ces cookies sont nécessaires pour fournir des services disponibles via notre Site et pour utiliser certaines de ses fonctionnalités, comme l'accès à des zones sécurisées.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Cookies de Performance et de Fonctionnalité :</b> Ces cookies améliorent la performance et la fonctionnalité de notre Site, mais ne sont pas essentiels. Cependant, sans ces cookies, certaines fonctionnalités pourraient ne pas être disponibles.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Cookies d'Analyse et de Personnalisation :</b> Ces cookies collectent des informations utilisées soit sous forme agrégée pour nous aider à comprendre comment notre Site est utilisé, soit pour nous aider à personnaliser notre Site pour vous.                         </li>
                              <li className='my-4 f00' >

                                    <b>Cookies Publicitaires :</b> Ces cookies sont utilisés pour rendre les publicités plus pertinentes pour vous. Ils servent à éviter que la même publicité réapparaisse en continu, à garantir l'affichage correct des publicités et, dans certains cas, à sélectionner des publicités basées sur vos intérêts.                         </li>
                              <li className='my-4 f00' >

                                    <b>Cookies de Réseaux Sociaux :</b> Ces cookies vous permettent de partager des pages et du contenu intéressants sur notre Site via des réseaux sociaux tiers. Ils peuvent aussi être utilisés à des fins publicitaires.                         </li>

                        </ol>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Comment souvent mettrons-nous à jour cette Politique de Cookies ?                         </div>

                        <div className='f00 my-2'>

                              Nous pouvons mettre à jour cette Politique de Cookies de temps à autre pour refléter, par exemple, des changements dans les cookies que nous utilisons ou pour d'autres raisons opérationnelles, légales ou réglementaires. Nous vous invitons donc à consulter régulièrement cette Politique de Cookies pour rester informé de notre utilisation des cookies et des technologies associées.                   </div>




                  </div>



                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Où puis-je obtenir plus d'informations ?                   </div>

                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className='f00 my-2'>Si vous avez des questions sur notre utilisation des cookies ou d'autres technologies, n'hésitez pas à nous contacter par e-mail à <a href='/support'>support@victoi.com.</a>
                        </div>
                  </div>





            </div>
      )
      useEffect(() => {
            // hello world href
            seti(siteLanguage == "ENGLISH" ? <English /> : <French />)
      }, [siteLanguage])

      return ( i )
}
