import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../Assets/css/A-navbar-css.css";
import "../Assets/css/menu.css";
import card from "../Assets/icons/A-victoi-card.png";
import {Link} from "react-router-dom";
import crypto from "../Assets/icons/A-invest-crypto.png";
import stock from "../Assets/icons/A-invest-stocks.png";
import bills from "../Assets/icons/A-pay-bills.png";
import transfer from "../Assets/icons/A-transfers-payments.png";
import {Box, Grid, Typography} from "@mui/material";
import Logo from "../Assets/Logos/FCG_LG.png";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor:'#050719',


        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },'&.hover':{
            color:"#ffffff",
        }

    },
}));


export  const Products=({state})=> {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [col,setCol]=useState(" ");
    const [status,colStatus] = useState(true)
    const [check,steCheck] = useState(true)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        colStatus(false);
        if (!status){
            setCol("#8fff00");
        }else {
            setCol("#ffffff")
        }

    };

    const handleClose = () => {
        colStatus(true)
        setAnchorEl(null);

    };

    return (
        <div style={{backgroundColor: "#050719"}}>
            <Box sx={{ display: { xs: "none", sm: "inline-block"}}} >
               <Button  style={{backgroundColor:"transparent",border:'none',color:col,fontWeight:'bold'}}
                       id="demo-customized-button"
                       aria-controls={open ? 'demo-customized-menu' : undefined}
                       aria-haspopup="true"
                       aria-expanded={open ? 'true' : undefined}
                       variant="contained"
                       disableElevation
                       onClick={handleClick}
                       endIcon={<KeyboardArrowDownIcon />}
                        className={'btnOff'}

               >
                   <Typography fontWeight={'bold'} sx={{ display: { xs: "none", sm: "block"}}}>Products</Typography>


               </Button>
           </Box>



            <StyledMenu


                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Grid container rowSpacing={1} style={{paddingLeft:'10px'}} columnSpacing={{ xs: 1, sm: 2, md: 3}}>



                    <Grid item xs={12} sm={6}>
                        <Box style={{gap:'30px'}} sx={{ display: { xs: "block", sm: "none",display:'flex',flexDirection:'row',marginTop: '10px',marginBottom:'10px'}}}>
                            <Link to="/" className="navbar-brand" href="#"><img style={{marginRight:'290px'}} src={Logo} width="50px" /></Link>
                            <span style={{color:"#8fff00",fontWeight:'bolder',fontSize:'30px'}} onClick={handleClose}><CloseIcon/></span>
                        </Box>

                        <h3 style={{color:'#ffffff',fontFamily:'Roboto',fontWeight:'bold'}}>Payment</h3>
                        <MenuItem onClick={handleClose} disableRipple  style={{backgroundColor: "#050719"}}>
                            <Link to="/TransferAndPayment" style={{textDecoration: 'none'}}>
                                <a className="dropdown-item A-dropdown-item" href="#">
                                    <div className="row" style={{gap:'10px'}}>
                                        <div className="col-sm-2">
                                            <img src={transfer}alt="transfers and payments" className="A-dropdown-icons"/>
                                        </div>
                                        <div className="col">
                                            <h5>Transfers & Payments</h5>
                                            Easily Send, receive, Pay & Get paid
                                        </div>
                                    </div>
                                </a></Link>

                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple  style={{backgroundColor: "#050719"}}>
                            <Link to="/VictoiCard" style={{textDecoration: 'none'}}>
                                <a className="dropdown-item A-dropdown-item" href="#">
                                    <div className="row" style={{gap:'10px'}}>
                                        <div className="col-sm-2">
                                            <img src={card} alt="Victoi card" className="A-dropdown-icons"/>
                                        </div>
                                        <div className="col">
                                            <h5>Victoi Card</h5>
                                            Pay on millions websites worldwide
                                        </div>
                                    </div>
                                </a></Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple style={{backgroundColor: "#050719"}}>
                            <Link to="/PayBills" style={{textDecoration: 'none'}}>
                                <a className="dropdown-item A-dropdown-item" href="#">
                                    <div className="row" style={{gap:'5px'}}>
                                        <div className="col-sm-2">
                                            <img src={bills} alt="Pay bills" className="A-dropdown-icons"/>
                                        </div>
                                        <div className="col">
                                            <h5>Pay Bills</h5>
                                            Buy Airtime, Data & Pay Bills with ease
                                        </div>
                                    </div>
                                </a></Link>
                        </MenuItem>

                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <h3 style={{color:'#ffffff',fontFamily:'Roboto',fontWeight:'bold'}}>InvestMent</h3>
                        <MenuItem onClick={handleClose} disableRipple style={{backgroundColor: "#050719"}}>
                            <Link to="/InvestStocks" style={{textDecoration: 'none'}}>
                                <a className="dropdown-item A-dropdown-item" href="#">
                                    <div className="row" style={{gap:'20px'}}>
                                        <div className="col-sm-2">
                                            <img src={stock} alt="Invest stocks" class="A-dropdown-icons"/>
                                        </div>
                                        <div className="col">
                                            <h5>Invest-Stocks</h5>
                                            Start investing with $1
                                        </div>
                                    </div>
                                </a></Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple style={{backgroundColor: "#050719"}}>
                            <Link to="/InvestCrypto" style={{textDecoration: 'none'}}>
                                <a className="dropdown-item A-dropdown-item" style={{textDecoration:"none"}} href="#">

                                    <div className="row" style={{gap:'10px'}}>
                                        <div className="col-sm-2">
                                            <img src={crypto} alt="Invest crypto" className="A-dropdown-icons"/>
                                        </div>

                                        <div className="col">
                                            <h5>Invest-Crypto</h5>
                                            Buy/Sell Bitcoin & Stable-coins
                                        </div>
                                    </div>

                                </a></Link>
                        </MenuItem>
                        <Box style={{alignContent:"center"}} className={'btnNav'} sx={{ display: { xs: "block", sm: "none", paddingLeft:'27%',paddingBottom: "10%",paddingTop: "10%" }}}>
                            <Box  style={{display:'flex', flexDirection:'column',width:'60%',marginTop:'15px',gap:'15px'}}>
                                <Button style={{border:'2px solid #8fff00', color:'#8fff00',fontWeight:'bold'}}><Typography>Download App</Typography></Button>
                                <Button style={{ color:'#ffffff',fontWeight:'bold'}}>Our Fees</Button>
                            </Box>
                        </Box>


                    </Grid>
                </Grid>






            </StyledMenu>
        </div>
    );
}