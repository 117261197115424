import React, {useEffect} from 'react'
import Header from '../Components/Header';
import Card from '../Components/Card';
import SmallCard from '../Components/SmallCard';
import bit from "../Assets/icons/BSC.png";
import send from "../Assets/icons/SR.png";
import trust from '../Assets/icons/TP2.png';


import LongText from '../Components/LongText';
import PageCards from "../Components/PageCards";
import crypto from "../Assets/img/crypto.png";
import PageText from '../Components/PageText';
import Footer from "../Components/Footer";
import Footer2 from "../Components/Footer2";
import { useTranslation } from 'react-i18next';

function InvestCrypto() {
  const {t} = useTranslation()
  return (
    <>
      <br/>
    <PageCards
    img={crypto}
    headtext={t("investCrypto_1ststc_texttiltle")}
    text={t("investCrypto_1ststc_textbody")}
    />
    <PageText
    headtext={t("investCrypto_2sdstc_texttiltle")}
    text={t("investCrypto_2sdstc_textbody")}/>
      <SmallCard
    Img1={bit}
    Img2={send}
    Img3={trust}
    head1={t("investCrypto_1stsmbx_texttiltle")}
    head2={t("investCrypto_2cdsmbx_texttiltle")}
    head3={t("investCrypto_3rtsmbx_texttiltle")}
    text1={t("investCrypto_1stsmbx_textbody")}
    text2={t("investCrypto_2cdsmbx_textbody")}
    text3={t("investCrypto_3rtsmbx_textbody")} />

    </>
  )
}

export default InvestCrypto
