import React from 'react'
import stock from "../Assets/img/stock.png"
import "../Assets/css/rightimage.css"
import {Typography} from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Fade from 'react-reveal/Fade';

import {motion} from "framer-motion";


function CardTwo({headtext1, headtext2, text, img}) {
  return (
   <>
   
    <div className="main">
        <div className="container rimg  ">
            <div className="text1  " style={{marginTop:'-100px'}}>
                <Fade left>

                <h2 style={{fontWeight:'bold'}}   className="headtext">
                    {headtext1} <br/> {headtext2}
                </h2>

                <p  className="maintext myMainText">
                   {text} 
                </p>
                    <p className="linkbt">
                         - Learn More
                    </p>
                </Fade>
            </div>

            <div

                className="image1"  style={{marginTop:'-100px'}}>
                <Fade right>
                    <img src={img} alt="Victoi card"/>
                </Fade>

            </div>
             
        </div>


    </div>
   </>
  )
}

export default CardTwo
