import React from 'react'
import "../Assets/css/smallCards.css";
import Fade from 'react-reveal/Fade';
import {Typography} from "@mui/material";


function SmallCard(props) {
  return (
  <div style={{backgroundColor: "var(--bg-second)", width: "100%"}}>
    <div className="container cardsss justify-content-center  ">
        {/*WRAPPING ALL IN A ROW*/}
        <div className="row row-cols-4 w-100 justify-content-center align-items-center ">

            <div className="smcard f2">
                <Fade top>
                    <img src={props.Img1} alt="dummy" />
                </Fade>

                <Typography variant={'h6'} style={{fontWeight:"bold"}}  className="bigtext">{props.head1}</Typography>
                <p className="info">{props.text1}</p>
            </div>
            <div className="smcard f2">
                <Fade top>
                    <img src={props.Img2} alt="dummy" />
                </Fade>
                <Typography variant={'h5'} style={{fontWeight:"bold"}}  className="bigtext">{props.head2}</Typography>
                <p className="info">{props.text2}</p>
            </div>
            <div className="smcard f2">
                <Fade top>
                    <img src={props.Img3} alt="dummy" />
                </Fade>
                <h5 style={{fontWeight:"bold"}} className="bigtext">{props.head3}</h5>
                <p className="info">{props.text3}</p>
            </div>
        </div>
    </div>
  </div>
  )
}

export default SmallCard
