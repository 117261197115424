import React from 'react';
import "../Assets/css/Victoi.css";
import { NavLink } from 'react-router-dom';
import transfer from "../Assets/icons/TP.png";
import bankcard from "../Assets/icons/VC.png";
import paybill from "../Assets/icons/PB.png";
import investStock from "../Assets/icons/IS.png";
import investCrypto from "../Assets/icons/IC.png";
import Logo from "../Assets/Logos/FCG_LG.png"
import 'bootstrap/dist/css/bootstrap.css'
import {Link} from "react-router-dom";
import "../Assets/css/header.css"
import {DropDown} from "./DropDown";
import {CustomizedMenus} from "./menu";
import Button from "@mui/material/Button";
import {Products} from "./Products";
import { Typography } from '@mui/material';
import mount from "../Assets/icons/mount.png"
import text from "../Assets/icons/text.png"
import MegaDropDown from "./MegaDropDown";
import lines from "../Assets/icons/lines.png"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';



function Header() {
    const {t} = useTranslation()

    // -------- added -------
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [specialOpen,setSpecialOpen] = React.useState(true)


    const ToggleSpecialOpen = ()=>{
        setOpen(!open);
        setSpecialOpen(!specialOpen);
    }
    // -------- /added -------

    // const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
//141135
  return (
    <>
    <nav className="navbar navbar-expand-lg fixed-top nav-color" style={{borderBottom: "1px solid var(--green)"}}>
        <div className="container-fluid controle  " >
            <Link to="/" className="navbar-brand  p-0" href="#">
                {/* <img  src={mount} width="50px" /> */}
            <img src={text} width="180px" /></Link>
            <button  className="navbar-toggler  toggler-color  h-100 mt-1 mr-2 " type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                {/*<CustomizedMenus/>*/}
                <MegaDropDown switchState={true} open={open} setOpen={ToggleSpecialOpen}  sm={{ display: "none"}}/>

            </button>
            { specialOpen &&
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav nav-item-style" >
                    <li className="nav-item " >
                        <div  className={'container-fluid '} >
                            <MegaDropDown switchState={false} open={open} setOpen={setOpen}  sm={{ display: "none"}}/>
                        </div>
                        {/*mega dropdown is indipendent from hambuger button*/}
                    </li>


                    {/*<Button className="nav-link " sx={{ display: { xs: "none", sm: "block"}}}  style={{backgroundColor:'transparent', fontWeight:"bolder", fontStyle:'normal', color:"#ffffff",marginRight:'20px'}} ><span className={'btnOff'}><Link to={"/OurFees"} style={{textDecoration:"none",color:"green",border: "1px solid green"}} >Our Fees</Link></span></Button>*/}


                    <Button className="nav-link bold" sx={{ display: { xs: "none", sm: "block"}}}  style={{backgroundColor:'transparent', fontWeight:"bolder", fontStyle:'normal', color:"#ffffff",marginRight:'10px'}} ><Link to={"/OurFees"} style={{textDecoration:"none",color:"white"}} ><Typography fontWeight={'bold'} >{t("our_story")}</Typography></Link></Button>
                    {/* <Button className="nav-link " sx={{ display: { xs: "none", sm: "block"}}}  style={{backgroundColor:'transparent', fontWeight:"bolder", fontStyle:'normal', color:"#ffffff",marginRight:'10px'}} ><Link to={"/blog"} style={{textDecoration:"none",color:"white"}} >{t("BLog")}</Link></Button> */}


                    <li className="nav-item">
                        <Link to="/Contact" style={{textDecoration:"none",color:"#050719"}}> <Button sx={{ display: { xs: "none", sm: "block"}}} className={'btn btn-color nav-link nav-item-color'} style={{backgroundColor:'var(--green)', color:'#050719',fontWeight:'bold',marginLeft:'50px'}} >{t("request_early_access")}</Button></Link>
                    </li>
                </ul>
            </div>}
        </div>
    </nav>
    {/* this is the html code for the dropdown of the header */}
    {/*    ------- STRONGLY BELEIVE THIS IS USELESS ---*/}
    {/*    <div className="modal" id="myModal" >*/}
    {/*        <div className="modal-dialog modal-xl modal-position">*/}
    {/*          <div className="modal-content modal-styles">*/}
    {/*            <div className="modal-head1">*/}
    {/*                <h3>Products</h3>*/}
    {/*            </div>*/}
    {/*            <div className="modal-body">*/}
    {/*              <div className="row">*/}
    {/*                <div className="col-lg-6">*/}
    {/*                    <h3>Investing</h3>*/}
    {/*                    <table className="modal-table table-borderless">*/}
    {/*                        <tbody>*/}
    {/*                            <Link to="/TransferAndPayment">*/}
    {/*                            <tr>*/}

    {/*                                    /!* <td><img className={'headerCon'} src={} alt={''} /></td> *!/*/}
    {/*                                <td>*/}
    {/*                                    <h4>Transfers & Payments</h4>*/}
    {/*                                    Easily Send, receive, Pay & Get paid*/}
    {/*                                </td>*/}

    {/*                            </tr>*/}
    {/*                            </Link>*/}
    {/*                            <tr>*/}
    {/*                                <Link to="/VictoiCard">*/}
    {/*                                    <td><img src={bankcard} className={'headerCon'}  alt={''}/></td>*/}
    {/*                                <td>*/}
    {/*                                    <h4>Victoi Card</h4>*/}
    {/*                                    Pay on millions websites worldwide*/}
    {/*                                </td>*/}
    {/*                                </Link>*/}
    {/*                            </tr>*/}
    {/*                            <tr>*/}
    {/*                                <Link to="/PayBills">*/}
    {/*                                    <td><img src={paybill} className={'headerCon'} alt={""} /></td>*/}
    {/*                                <td>*/}
    {/*                                    <h4>Transfers & Payments</h4>*/}
    {/*                                    Buy Airtime, Data & Pay Bills with ease*/}
    {/*                                </td>*/}
    {/*                                </Link>*/}
    {/*                            </tr>*/}
    {/*                        </tbody>*/}
    {/*                    </table>*/}
    {/*                </div>*/}
    {/*                <div className="col-lg-6 modal-head">*/}
    {/*                    <Typography variant="h3">Payment</Typography>*/}
    {/*                    <table className="table table-borderless">*/}
    {/*                        <tbody>*/}
    {/*                            <Link exact="true" to="/InvestStocks">*/}
    {/*                            <tr>*/}
    {/*                                <td><img src={investStock} className={'headerCon'} alt={""} /></td>*/}
    {/*                                <td>*/}
    {/*                                    <h4>Invest-Stocks</h4>*/}
    {/*                                    Start investing with $1*/}
    {/*                                </td>*/}
    {/*                            </tr>*/}
    {/*                            </Link>*/}
    {/*                            <tr>*/}
    {/*                                <Link to="/InvestCrypto">*/}
    {/*                                    <td><img src={investCrypto} className={'headerCon'} alt={""}/></td>*/}
    {/*                                <td>*/}
    {/*                                    <h4>Invest-Crpto</h4>*/}
    {/*                                    Buy / Sell Bitcoin & Stable-coins*/}
    {/*                                </td>*/}
    {/*                                </Link>*/}
    {/*                            </tr>*/}
    {/*                        </tbody>*/}
    {/*                    </table>*/}
    {/*                </div>*/}
    {/*                <div className="modal-foot col-sm-5">*/}
    {/*                    <button type="button" className="btn modal-btn">*/}
    {/*                        DOWNLOAD APP*/}
    {/*                    </button><br />*/}
    {/*                    <p ><a href="#" className="justify-content-center modal-link-style">Our Fees</a></p>*/}
    {/*                </div>*/}
    {/*              </div>*/}
    {/*            </div>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    </>     
  )
}

export default Header
