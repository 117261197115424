import "../Assets/css/A-modal.css";
import stock from "../Assets/icons/IS.png";
import crypto from "../Assets/icons/IC.png";
import bills from "../Assets/icons/PB.png";
import victoicard from "../Assets/icons/VC.png";
import transfers from "../Assets/icons/TP.png";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import { t } from "i18next";
import classes from './A-modal.module.css'

export default function AppleModal({ close }) {
    return (
        <div className="A-modal-container container-fluid f1" onClick={close}>
            {/* DESKTOP CODE BEGINS HERE */}
            <div className="container-fluid A-desktop-modal" style={{}}>
                <div className="A-main-modal-close   " style={{ textAlign: "right", marginTop: "25px", marginRight: "50px" }} onClick={close}><Cancel sx={{ fontSize: 40, color: "var(--green)" }} /></div>
                <div className="A-main-modal-heading ">{t("header_section1_products")}</div>
                <div className="row A-row ">
                    <div className="col-sm-7 A-col  ">
                        <div className="row A-heading   A-subrow1">{t("headerModal_headSection_payment")} </div>
                        <div className="row A-subrow1 A-subrow1">
                            <div className="col-sm-3 A-subcol1">
                                <img src={transfers} alt="Transfers and paymetn" className="col1-img" />
                            </div>
                            <div className="col-sm-9">
                                <Link to={"/TransferAndPayment"} style={{ textDecoration: "none" }}><h3>{t("headerModal_transfersSection_transfersPaymentsTitle")}</h3>
                                    <p>{t("headerModal_headerModal_transfersPaymentsBody")}</p></Link>
                            </div>
                        </div>
                        <div className="row A-subrow1">
                            <div className="col-sm-3 A-subcol1">
                                <img src={victoicard} alt="Victoi Card" className="col1-img" />
                            </div>
                            <div className="col-sm-9">
                                <Link to={"/VictoiCard"} style={{ textDecoration: "none" }}>
                                    <h3>{t("headerModal_cardSection_cardTitle")}</h3>
                                    <p>{t("headerModal_cardSection_cardBody")}</p></Link>
                            </div>
                        </div>
                        <div className="row A-subrow1">
                            <div className="col-sm-3 A-subcol1">
                                <img src={bills} alt="Pay bills" className="col1-img" />
                            </div>
                            <div className="col-sm-9">
                                <Link to={"/PayBills"} style={{ textDecoration: "none" }}> <h3>{t("headerModal_payBillsSection_billsTitle")}</h3>
                                    <p>{t("headerModal_payBillsSection_billsTitle")}</p></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5 A-col">
                        <div className="row A-heading A-subrow1 A-col2-top-margin-a" style={{ marginLeft: "-50px" }}>{t("headerModal_headSection_investing")}</div>
                        <div className="row A-subrow1 A-subrow1 A-col2-top-margin-b">
                            <div className="col-sm-3 A-subcol1 A-subcol1-margin">
                                <img src={stock} alt="Invest stocks" className="col2-img" />
                            </div>
                            <div className="col-sm-9 A-txt-gap">
                                <Link to={"/InvestStocks"} style={{ textDecoration: "none" }}>
                                    <h3>{t("headerModal_investStocks_stocksTitle")}</h3>
                                    <p>{t("headerModal_investStocks_stocksBody")}</p></Link>
                            </div>
                        </div>
                        <div className="row A-subrow1 A-col2-top-margin-c">
                            <div className="col-sm-3 A-subcol1">
                                <img src={crypto} alt="Invest stocks" className="col2-img" />
                            </div>
                            <div className="col-sm-9">
                                <Link to={"/InvestCrypto"} style={{ textDecoration: "none" }}>
                                    <h3>{t("headerModal_investCrypto_cryptoTitle")}</h3>
                                    <p>{t("headerModal_investStocks_stocksBody")}</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="A-modal-dwn-btn">
                    <button type="button"><Link to={"/Contact"}>Download App</Link></button>
                </div>
                <div className="A-modal-ourfees-lnk bold">
                    <Link to={"/OurFees"} style={{ textDecoration: "none", color: "#ffffff" }}>{t("our_story")}</Link>
                </div>
            </div>

            {/* MOBILE CODES BEGIN HERE */}

            <div className="A-mobile-modal container-fluid   " style={{ display: "none" }} >
                <div className="A-main-modal-close" style={{ textAlign: "right", marginTop: "25px", marginRight: "20px" }} onClick={close}><Cancel sx={{ fontSize: 35 }} /></div>
                <div className="A-main-modal-heading ">{t("header_section1_products")}</div>
                <div className="A-col1  ">
                    <div className="A-col1-row A-heading">
                        {t("headerModal_headSection_payment")}
                    </div>
                    <div className="A-col1-row ">
                        <div className="A-icon-ctn">
                            <img src={transfers} alt="Transfers and payment" />
                        </div>
                        <div className="A-txt-ctn">
                            {/*<h3>Transfers & Payments</h3>*/}

                            <Link to={"/TransferAndPayment"} style={{ textDecoration: "none" }}><h3>{t("headerModal_transfersSection_transfersPaymentsTitle")}</h3>
                                <p>{t("headerModal_headerModal_transfersPaymentsBody")}</p></Link>
                            {/*<p>Easily Send, receive, Pay & Get Paid</p>*/}
                        </div>
                    </div>
                    <div className="A-col1-row">
                        <div className="A-icon-ctn">
                            <img src={victoicard} alt="Victoi Card" />
                        </div>
                        <div className="A-txt-ctn">
                            {/*<h3>Victoi Card</h3>*/}
                            {/*<p>Pay on millions of websites worldwide</p>*/}

                            <Link to={"/VictoiCard"} style={{ textDecoration: "none" }}>
                                <h3>{t("headerModal_cardSection_cardTitle")}</h3>
                                <p>{t("headerModal_cardSection_cardBody")}</p></Link>
                        </div>
                    </div>
                    <div className="A-col1-row">
                        <div className="A-icon-ctn">
                            <img src={bills} alt="Pay bills" />
                        </div>
                        <div className="A-txt-ctn">
                            <Link to={"/PayBills"} style={{ textDecoration: "none" }}> <h3>{t("headerModal_payBillsSection_billsTitle")}</h3>
                                <p>{t("headerModal_payBillsSection_billsBody")}</p></Link>
                            {/*<h3>Pay Bills</h3>*/}
                            {/*<p>Buy Airtime, Data & Pay Bills with ease</p>*/}
                        </div>
                    </div>
                </div>
                <div className="A-col1 ">
                    <div className="A-col1-row A-heading">
                        {t("headerModal_headSection_investing")}
                    </div>
                    <div className="A-col1-row">
                        <div className="A-icon-ctn">
                            <img src={stock} alt="Transfers and paymetn" />
                        </div>
                        <div className="A-txt-ctn">
                            {/*<h3>Invest-Stocks</h3>*/}
                            {/*<p>Start investing with $1</p>*/}
                            <Link to={"/InvestStocks"} style={{ textDecoration: "none" }}>
                                <h3>{t("headerModal_investStocks_stocksTitle")}</h3>
                                <p>{t("headerModal_investStocks_stocksBody")}</p></Link>
                        </div>
                    </div>
                    <div className="A-col1-row">
                        <div className="A-icon-ctn">
                            <img src={crypto} alt="Victoi Card" />
                        </div>
                        <div className="A-txt-ctn">
                            {/*<h3>Invest-Crypto</h3>*/}
                            {/*<p>Buy/Sell Bitcoin & USDT</p>*/}
                            <Link to={"/InvestCrypto"} style={{ textDecoration: "none" }}>
                                <h3>{t("headerModal_investCrypto_cryptoTitle")}</h3>
                                <p>{t("headerModal_investCrypto_cryptoBody")}</p></Link>
                        </div>
                    </div>
                </div>
                <div className="A-modal-dwn-btn my-3 mt-4">
                    <button type="button" style={{ width: "80%", height: "5vh", textAlign: "center", marginBottom: "3px" }}><Link to={"/Contact"} style={{ textDecoration: "none", color: "#050719", fontSize: "14px", fontWeight: "bold" }}>{t("request_early_access")}</Link></button>
                </div>
                <div className="A-modal-ourfees-lnk">
                    <Link to={"OurFees"} style={{ textDecoration: "none", color: "lightgray", fontSize: "1.2em", marginTop: "-2px" }}>{t("our_story")}</Link>
                </div>
            </div>
            {/** MOBILE DESIGN STARTS HERE */}
            <div className={classes.container + ' p-3'}>
                <div className={classes.close+" "}>
                    <div className="w-100 d-flex   ">
                        <nav className=" bold f2 d-flex align-items-end p-0" style={{ borderBottom: "7px solid blue", width: "max-content" }}>
                        {t("header_section1_products")}
                    </nav>
                        <Cancel className="m-0 ms-auto  h6 " style={{ color: "var(--green)" }} />
                    </div>
                </div>
                {/* <div className={classes.head + "  my-2"}>
                    <nav className="  d-flex align-items-end p-0" style={{ borderBottom: "7px solid blue", width: "max-content" }}>
                        {t("header_section1_products")}
                    </nav>
                </div> */}
                <div className={classes.set1 + " bold ps-2 "}>
                    <div className=" f3 bold">
                        {t("headerModal_headSection_payment")}
                    </div>
                    <div className="d-flex px-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={transfers} alt="Transfers and payment" />
                        </div>
                        <div className="ms-3">
                            <Link to={"/TransferAndPayment"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_transfersSection_transfersPaymentsTitle")}
                                </div>
                                <div>
                                    {t("headerModal_headerModal_transfersPaymentsBody")}
                                </div></Link>
                        </div>
                    </div>
                    <div className="d-flex ps-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={victoicard} alt="Victoi Card" />
                        </div>
                        <div className="ms-3">


                            <Link to={"/VictoiCard"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_cardSection_cardTitle")}
                                </div>
                                <div>
                                    {t("headerModal_cardSection_cardBody")}
                                </div></Link>
                        </div>
                    </div>
                    <div className="d-flex ps-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={bills} alt="Pay bills" />
                        </div>
                        <div className="ms-3">


                            <Link to={"/PayBills"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_payBillsSection_billsTitle")}
                                </div>
                                <div>
                                    {t("headerModal_payBillsSection_billsBody")}
                                </div></Link>
                        </div>
                    </div>
                </div>
                <div className={classes['set2'] + " px-3"}>
                    <div className=" f3 text-bold bold">
                        {t("headerModal_headSection_investing")}
                    </div>
                    {/* <div className="d-flex px-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={stock} alt="Invest in Stock" />
                        </div>
                        <div className="ms-3">


                            <Link to={"/InvestStocks"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_investStocks_stocksTitle")}
                                </div>
                                <div>
                                    {t("headerModal_investStocks_stocksBody")}
                                </div></Link>
                        </div>
                    </div> */}
                    <div className="d-flex ps-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={stock} alt="Invest in Stock" />
                            {/* <img src={stock} alt="Invest in Stock" /> */}
                        </div>
                        <div className="ms-3">


                            <Link to={"/InvestCrypto"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_investStocks_stocksTitle")}
                                </div>
                                <div>
                                    {t("headerModal_investStocks_stocksBody")}
                                </div></Link>
                        </div>
                    </div>
                    <div className="d-flex ps-2 green align-items-center w-100" >
                        <div className={classes.img}>

                            <img src={crypto} alt="Victoi Card" />
                            {/* <img src={stock} alt="Invest in Stock" /> */}
                        </div>
                        <div className="ms-3">


                            <Link to={"/InvestCrypto"} style={{ textDecoration: "none" }}>
                                <div className="fw-bold hts">
                                    {t("headerModal_investCrypto_cryptoTitle")}
                                </div>
                                <div>
                                    {t("headerModal_investCrypto_cryptoBody")}
                                </div></Link>
                        </div>
                    </div>
                </div>
                <div className={classes.buttons + " d-flex flex-column justify-content-evenly  w-100"}>
                    <div className="vbtn mx-auto text-center ">
                        {t("request_early_access")}
                    </div>
                    <div className="mx-auto bold " style={{fontSize:"1.3em"}}>
                        {t("our_story")}
                    </div>
                </div>
            </div>

        </div>
    )
}