import { ClassNames } from '@emotion/react'
import classes from '../KYCcomponent/docs.module.css'
import React, { useState } from 'react'
import { useEffect } from 'react'


export default function Terms({ siteLanguage }) {
      const [i, seti] = useState()

      const English = () => (

            <div className={classes.container + ' mx-auto'}>
                  <h2 className='text-center mb-3'>                 <b>Terms and Conditions</b>
                  </h2>

                  <div className='f00 mt-5 mb-3'>
                        This document was last updated on 22/12/2023.
                  </div>
                  <div>
                        Welcome to Victoi! These terms and conditions outline the rules and regulations for the use of Victoi's website and services. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Victoi if you do not agree to take all of the terms and conditions stated on this page.
                  </div>


                  {/* <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}></div>
                              <div className='f00 my-2'></div>
                           
                        </div>  */}
                  {/* <li className='my-4 f00' >

                        </li> */}
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Limited License
                        </div>
                        <div className='f00 my-2'></div>

                        Victoi grants you a limited license to access and make personal use of this website. You are not allowed to download or modify any portion of the website without prior written consent from Victoi. This license does not grant you the permission to use Victoi for any purpose other than that for which is intended.

                  </div>




                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Description of the Service.
                        </div>
                        <div className='f00 my-2'> Victoi offers a range of services designed to cater to the financial needs of individuals and businesses. Here is a detailed explanation of each service:

                        </div>
                        <ul>
                              <li className='my-4 f00' >

                                    <b>Money Transfer:</b> Victoi enables seamless and instant money transfers  locally and internationally. Users can send and receive funds quickly, with reasonable transaction fees and competitive exchange rates.

                              </li>  <li className='my-4 f00' >

                                    <b>Fractional Stock Trading:</b> Victoi allows users to invest in stocks of public listed companies with minimal capital. Through fractional stock trading, users can buy and sell small portions of high-value stocks, making investing in the stock market more accessible and affordable.

                              </li>
                              <li className='my-4 f00' >

                                    <b>Currency Exchange:</b> Victoi provides a user-friendly platform for currency exchange, allowing users to convert between different currencies at competitive rates. This service is especially beneficial for travelers and businesses engaged in international trade.

                              </li>
                              <li className='my-4 f00' >

                                    <b>Crypto Trading:</b> Victoi supports cryptocurrency trading, enabling users to buy, sell, and hold popular cryptocurrencies like Bitcoin, Ethereum, and more. With secure wallets and real-time market data, users can actively participate in the growing cryptocurrency market.

                              </li>
                              <li className='my-4 f00' >

                                    <b> Virtual Debit Cards:</b> Victoi offers virtual debit cards that can be used for online purchases and transactions. These cards provide an added layer of security and convenience, allowing users to make payments without sharing their actual card details.

                              </li>

                        </ul>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Who can use these services and how can they use them?
                        </div>
                        <div className='f00 my-2'>

                              Victoi's services are available to both individuals and businesses. Individuals can use Victoi's online and mobile banking services to manage their personal accounts, perform transactions, and access various financial tools.


                        </div>  <div className='f00 my-2'>

                              Businesses can benefit from Victoi's payment processing solutions to streamline their payment collection processes, manage cash flow, and provide convenient payment options to their customers.

                        </div>

                        <div className='f00 my-2'>

                              To use Victoi's services, individuals and businesses need to create an account with Victoi by providing the required information and completing the necessary verification processes. Once registered, they can access the services through Victoi's website, mobile app, or by reaching out to Victoi's customer support team for assistance.

                        </div>




                  </div>



                  {/* <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}></div>
                              <div className='f00 my-2'></div>
                           
                        </div>  */}
                  {/* <li className='my-4 f00' >

                        </li> */}
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Indemnity

                        </div>
                        <div className='f00 my-2'>

                              The following indemnity clause is designed to protect the fintech company, Victoi, from any claims, losses, damages, liabilities, costs, and expenses that may arise due to the use of its services by individuals or businesses.

                        </div>

                        {/* </div> */}
                        <li className='my-4 f00' >

                              <b> Indemnification:</b> By using Victoi's services, individuals and businesses agree to indemnify and hold Victoi, its directors, officers, employees, and agents harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorney fees) arising out of or related to the use of Victoi's services, including but not limited to:
                              <ol>
                                    <li className='my-4 f00' >
                                          Any unauthorized access to or use of the individual's or business's Victoi account.

                                    </li>

                                    <li className='my-4 f00' >

                                          Any breach of the terms and conditions or any applicable laws and regulations.

                                    </li>
                                    <li className='my-4 f00' >

                                          Any negligence, fraud, or misconduct by the individual or business.

                                    </li>
                                    <li className='my-4 f00' >
                                          Any violation of third-party rights, including intellectual property rights

                                    </li>

                                    <li className='my-4 f00' >

                                          Any financial or investment decisions made by the individual or business based on information provided by Victoi's services.

                                    </li>
                              </ol>

                        </li>

                        {/* <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}></div>
                              <div className='f00 my-2'></div>
                           
                        </div>  */}
                        {/* <li className='my-4 f00' >

                        </li> */}
                        <li className='my-4 f00' >

                              <b>Defense and Settlement:</b> Victoi reserves the right to assume the defense and control of any matter subject to indemnification by the individual or business. The individual or business agrees to cooperate with Victoi in asserting any available defenses. Victoi will not settle any claim without the individual's or business's prior written consent, which shall not be unreasonably withheld.

                        </li>
                        <li className='my-4 f00' >

                              <b> Limitation of Liability:</b> In no event shall Victoi, its directors, officers, employees, or agents be liable to the individual or business for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with the use of Victoi's services. Victoi's liability, if any, shall be limited to the amount paid by the individual or business for the use of Victoi's services.

                        </li>
                        <li className='my-4 f00' >

                              <b>Class Action Waiver:</b> You agree that any disputes between you and Victoi shall be resolved on an individual basis and not in any class, consolidated, or representative action. You and Victoi both waive any right to participate in a class action or class-wide arbitration. If for any reason a claim proceeds in court rather than in arbitration, you and Victoi agree that such claim shall be filed and litigated exclusively in the united states, and you consent to the personal jurisdiction of such courts.

                        </li>
                        <li className='my-4 f00' >

                              <b>Severability:</b> If any provision of this indemnity clause is found to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not be affected or impaired.

                        </li>



                        By creating an account and using Victoi's services, individuals and businesses acknowledge and agree to the terms of this indemnity clause. It is recommended that individuals and businesses review this clause carefully and seek legal advice if necessary, before using Victoi's services.
                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>

                              Intellectual Property Rights

                        </div>
                        <div className='f00 my-2'>
                              All copyrights, trademarks, patents, and other intellectual property rights in and on our website and all content and software located on the site shall remain the sole property of Victoi or its licensors. The use of our trademarks, content, and intellectual property is prohibited without the express written consent from Victoi.
                        </div>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Restrictions</div>
                        <div className='f00 my-2'>
                              You are specifically restricted from all of the following:
                        </div>
                        <ul>
                              <li className='my-4 f00' >

                                    Publishing any website material in any other media

                              </li>
                              <li className='my-4 f00' >

                                    Selling, sublicensing, and/or otherwise commercializing any website material

                              </li>
                              <li className='my-4 f00' >

                                    Publicly performing and/or showing any website material

                              </li>
                              <li className='my-4 f00' >

                                    Using this website in any way that is or may be damaging to this website.

                              </li>
                              <li className='my-4 f00' >

                                    Using this website in any way that impacts user access to this website.

                              </li>
                              <li className='my-4 f00' >

                                    Using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity.

                              </li>
                        </ul>
                  </div>
                  {/* <li className='my-4 f00' >

                        </li> */}


                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Liability Limitation
                        </div>
                        <div className='f00 my-2'>
                              In no event shall Victoi, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise. Victoi, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.

                        </div>

                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Violation of Terms
                        </div>
                        <div className='f00 my-2'>
                              At Victoi, we take the adherence to our terms and conditions seriously. We expect all users of our services to comply with the agreed-upon terms to ensure a safe and secure environment for everyone involved. In the event of a violation of our terms, we reserve the right to take appropriate action, which may include but is not limited to:
                        </div>
                        <ul>
                              <li className='my-4 f00' >
                                    Temporary or permanent suspension of account privileges.

                              </li>
                              <li className='my-4 f00' >
                                    Termination of the user's account and access to our services.

                              </li>
                              <li className='my-4 f00' >
                                    Legal action, if necessary, to protect our rights and the rights of other users.

                              </li>
                        </ul>
                        <div className='f00 my-2'>

                              Examples of violations include, but are not limited to:

                        </div>
                        <ul>

                              <li className='my-4 f00' >
                                    Providing false or misleading information during the account registration process.

                              </li>
                              <li className='my-4 f00' >
                                    Engaging in fraudulent or illegal activities.

                              </li>
                              <li className='my-4 f00' >
                                    Unauthorized use or distribution of Victoi's intellectual property.

                              </li>
                              <li className='my-4 f00' >
                                    Breaching the security of our platform or attempting to gain unauthorized access.

                              </li>
                              <li className='my-4 f00' >
                                    Harassment, abuse, or inappropriate behavior towards other users or Victoi staff.

                              </li>

                              <li className='my-4 f00' >
                              </li>
                        </ul>
                        <div>
                              By using Victoi's services you agree to abide by our terms and conditions. Violations will be dealt with according to the severity and may result in immediate termination of your account without prior notice. We reserve the right to modify or update our terms as needed to ensure the safety and integrity of our platform.

                        </div>
                  </div>




                  {/* <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}></div>
                              <div className='f00 my-2'></div>
                           
                        </div>  */}
                  {/* <li className='my-4 f00' >

                        </li> */}
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              User Responsibility
                        </div>
                        <div className='f00 my-2'>

                              As a user of Victoi, you are responsible for your actions and the consequences they may have. You agree not to engage in any conduct that may be considered unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.

                        </div>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Age Restriction</div>
                        <div className='f00 my-2'>

                              {"The services provided by Victoi (the \"Company\") are intended for use by individuals who are at least 18 years old or the legal age of majority in their jurisdiction, whichever is older. By accessing or using the Company's services, you represent and warrant that you meet the age requirement."}
                        </div>
                        <div className='f00 my-2'>

                              If the Company becomes aware that a user is under the specified age requirement, the Company reserves the right to suspend or terminate their account and restrict their access to the services. The Company may also take appropriate legal action, if necessary, to enforce compliance with this age restriction.

                        </div>

                        <div className='f00 my-2'>

                              By agreeing to these terms and conditions, you acknowledge and understand that it is your responsibility to ensure that you meet the age requirement before accessing or using the Company's services. The Company shall not be held liable for any damages or losses incurred due to a user's misrepresentation of their age or violation of this age restriction clause.
                        </div>

                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Accuracy and Availability
                        </div>
                        <div className='f00 my-2'>

                              While we strive to provide accurate and up-to-date information on our website, we make no warranties or representations of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.

                        </div>

                  </div>

                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Third-Party Links
                        </div>
                        <div className='f00 my-2'>
                              Our website may contain links to third-party websites or services that are not owned or controlled by Victoi. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Victoi shall not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

                        </div>

                  </div>
                  <div className='mx-4 f1 my-5'>
                        <div className={classes.heavy + ' f1 my-2 bold '}>
                              Conclusion
                        </div>
                        <div className='f00 my-2'>
                              By using Victoi's website, you agree to these terms and conditions. If you do not agree with any part of these terms, please do not use our website. We reserve the right to revise these terms and conditions at any time without notice. It is your responsibility to review this page periodically for updates or changes. Your continued use of the website following the posting of any changes constitutes acceptance of those changes.
                        </div>
                        <div className='f00 my-2'>
                              If you have any questions about these terms and conditions, please contact us at  <a href='/support'>support@victoi.com.</a>

                        </div>

                  </div>





            </div>

      )
      const French = () => {
            return (


                  <div className={classes.container + ' mx-auto'}>
                        <h2 className='text-center'>                <b> Termes et conditions</b>
                        </h2>

                        <div className='f00 mt-5 mb-3'>
                              Ce document a été mis à jour pour la dernière fois le 22/12/2023.
                        </div>
                        <div>
                              Bienvenue chez Victoi ! Les présentes conditions générales décrivent les règles et règlements régissant l'utilisation du site Web et des services de Victoi. En accédant à ce site Web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser Victoi si vous n'acceptez pas de prendre toutes les conditions générales énoncées sur cette page.                   </div>



                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Licence limitée                         </div>
                              <div className='f00 my-2'></div>

                              Victoi vous accorde une licence limitée pour accéder à ce site Web et en faire un usage personnel. Vous n'êtes pas autorisé à télécharger ou modifier toute partie du site Web sans le consentement écrit préalable de Victoi. Cette licence ne vous accorde pas la permission d'utiliser Victoi à d'autres fins que celles auxquelles elles sont destinées.
                        </div>




                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Description of the Service.
                              </div>
                              <div className='f00 my-2'>
                                    Victoi offre une gamme de services conçus pour répondre aux besoins financiers des particuliers et des entreprises. Voici une explication détaillée de chaque service :
                              </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b>Transfert d'argent :</b>Victoi permet des transferts d'argent fluides et instantanés au niveau local et international. Les utilisateurs peuvent envoyer et recevoir des fonds rapidement, avec des frais de transaction et des taux de change compétitifs.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Trading d'actions fractionnées :</b> Victoi permet aux utilisateurs d'investir dans les actions des entreprises cotées en bourse avec un capital minimal. Grâce à la négociation de fractions d'actions, les utilisateurs peuvent acheter et vendre de petites portions d'actions de grande valeur, ce qui rend l'investissement dans le marché boursier plus accessible et abordable.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>

                                                Change  de devises :</b> Victoi fournit une plate-forme conviviale pour l'échange de devises, permettant aux utilisateurs de convertir entre différentes devises à des taux compétitifs. Ce service est particulièrement avantageux pour les voyageurs et les entreprises engagées dans le commerce international.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>

                                                Crypto Trading :</b> Victoi prend en charge le trading de crypto-monnaie, permettant aux utilisateurs d'acheter, de vendre et de détenir des crypto-monnaies populaires telles que Bitcoin, Ethereum, etc. Avec des portefeuilles sécurisés et des données de marché en temps réel, les utilisateurs peuvent participer activement au marché croissant des crypto-monnaies.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>

                                                Cartes de débit virtuelles :</b>  Victoi propose des cartes de débit virtuelles qui peuvent être utilisées pour les achats et les transactions en ligne. Ces cartes offrent une couche supplémentaire de sécurité et de commodité, permettant aux utilisateurs d'effectuer des paiements sans partager les détails de leur carte.
                                    </li>

                              </ul>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Qui peut utiliser ces services et comment peut-il les utiliser ?                               </div>
                              <div className='f00 my-2'>

                                    Les services de Victoi sont accessibles aux particuliers et aux entreprises. Les particuliers peuvent utiliser les services bancaires en ligne et mobiles de Victoi pour gérer leurs comptes personnels, effectuer des transactions et accéder à divers outils financiers.

                              </div>  <div className='f00 my-2'>

                                    Les entreprises peuvent bénéficier des solutions de traitement des paiements de Victoi pour rationaliser leurs processus de recouvrement des paiements, gérer les flux de trésorerie et fournir des options de paiement pratiques à leurs clients.
                              </div>

                              <div className='f00 my-2'>

                                    Pour utiliser les services de Victoi, les particuliers et les entreprises doivent créer un compte auprès de Victoi en fournissant les informations requises et en complétant les processus de vérification nécessaires. Une fois inscrits, ils peuvent accéder aux services via le site Web, l'application mobile de Victoi ou en contactant l'équipe de soutien à la clientèle de Victoi pour obtenir de l'aide.
                              </div>




                        </div>


                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    Indemnité

                              </div>
                              <div className='f00 my-2'>

                                    La clause d'indemnisation suivante est conçue pour protéger la société fintech, Victoi, contre toutes réclamations, pertes, dommages, responsabilités, coûts et dépenses pouvant survenir en raison de l'utilisation de ses services par des particuliers ou des entreprises.
                              </div>

                              {/* </div> */}
                              <li className='my-4 f00' >

                                    <b>Indemnisation :</b> En utilisant les services de Victoi, les particuliers et les entreprises acceptent d'indemniser et de dégager Victoi, ses administrateurs, dirigeants, employés et mandataires de toute réclamation, perte, dommage, responsabilité, coût et dépense (y compris les honoraires d'avocat) découlant de ou liés à l'utilisation des services de Victoi, y compris, mais sans s'y limiter :                                     <ol>


                                          <li className='my-4 f00' >

                                                Tout accès ou utilisation non autorisé du compte Victoi de la personne ou de l'entreprise.
                                          </li>
                                          <li className='my-4 f00' >

                                                Toute violation des termes et conditions ou de toute loi et réglementation applicable.
                                          </li>
                                          <li className='my-4 f00' >
                                                Toute négligence, fraude ou inconduite de la part de la personne ou de l'entreprise.
                                          </li>

                                          <li className='my-4 f00' >

                                                Toute violation des droits de tiers, y compris les droits de propriété intellectuelle
                                          </li> <li className='my-4 f00' >
                                                Toute décision financière ou d'investissement prise par la personne ou l'entreprise sur la base des informations fournies par les services de Victoi.
                                          </li>
                                    </ol>

                              </li>


                              <li className='my-4 f00' >

                                    <b>Défense et règlement :</b> Victoi se réserve le droit d'assumer la défense et le contrôle de toute affaire sujette à indemnisation par l'individu ou l'entreprise. La personne ou l'entreprise accepte de coopérer avec Victoi pour faire valoir toutes les défenses disponibles. Victoi ne réglera aucune réclamation sans le consentement écrit préalable de la personne ou de l'entreprise, qui ne sera pas refusé sans motif raisonnable.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Limitation de responsabilité :</b> En aucun cas, Victoi,  ses administrateurs, dirigeants, employés ou agents ne peuvent être tenus responsables envers la personne ou l'entreprise de tout dommage direct, indirect, accessoire, spécial, consécutif ou exemplaire découlant de ou en relation avec l'utilisation des services de Victoi. La responsabilité de Victoi, le cas échéant, sera limitée au montant payé par la personne ou l'entreprise pour l'utilisation des services de Victoi.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Renonciation à l'action collective :</b> Vous et Victoi convenez que tout litige entre vous et Victoi sera résolu individuellement et non dans le cadre d'une action collective, consolidée ou représentative. Vous et Victoi renoncez tous deux au droit de participer à une action collective ou à une procédure d'arbitrage collective. Si, pour quelque raison que ce soit, une réclamation est portée devant un tribunal au lieu d'être soumise à un arbitrage, vous et Victoi convenez que ladite réclamation sera déposée et traitée exclusivement devant les tribunaux au Etas Unis, et vous consentez à la compétence personnelle de ces tribunaux.
                              </li>
                              <li className='my-4 f00' >

                                    <b>Divisibilité :</b> Si une disposition de la présente clause d'indemnisation est jugée invalide, illégale ou inapplicable, la validité, la légalité et l'applicabilité des dispositions restantes ne seront ni affectées ni compromises.
                              </li>



                              En créant un compte et en utilisant les services de Victoi, les particuliers et les entreprises reconnaissent et acceptent les termes de cette clause d'indemnisation. Il est recommandé aux particuliers et aux entreprises de lire attentivement cette clause et de demander un avis juridique si nécessaire, avant d'utiliser les services de Victoi.                         </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    Droits de propriété intellectuelle
                              </div>
                              <div className='f00 my-2'>
                                    Tous les droits d'auteur, marques de commerce, brevets et autres droits de propriété intellectuelle dans et sur notre site Web et tout le contenu et les logiciels situés sur le site restent la propriété exclusive de Victoi ou de ses concédants de licence. L'utilisation de nos marques de commerce, de notre contenu et de notre propriété intellectuelle est interdite sans le consentement écrit exprès de Victoi.                               </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Restrictions
                              </div>
                              <div className='f00 my-2'>
                                    Vous êtes spécifiquement limité à tous les éléments suivants :                               </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          Publication de tout matériel du site Web dans tout autre média
                                    </li>
                                    <li className='my-4 f00' >

                                          Vendre, sous-licencier et/ou commercialiser de toute autre manière tout matériel du site Web
                                    </li>
                                    <li className='my-4 f00' >

                                          Exécuter publiquement et / ou montrer tout matériel du site Web
                                    </li>
                                    <li className='my-4 f00' >

                                          Utiliser ce site Web d'une manière qui est ou peut être dommageable à ce site Web.
                                    </li>
                                    <li className='my-4 f00' >

                                          Utiliser ce site Web de quelque manière que ce soit qui a un impact sur l'accès des utilisateurs à ce site Web.
                                    </li>
                                    <li className='my-4 f00' >

                                          L'utilisation de ce site Web contrairement aux lois et règlements applicables, ou de quelque manière que ce soit peut causer des dommages au site Web, ou à toute personne ou entité commerciale.
                                    </li>
                              </ul>
                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Limitation de responsabilité                               </div>
                              <div className='f00 my-2'>
                                    En aucun cas, Victoi, ni aucun de ses dirigeants, administrateurs et employés, ne peut être tenu responsable de quoi que ce soit découlant de ou lié de quelque manière que ce soit à votre utilisation de ce site Web, que cette responsabilité soit contractuelle, délictuelle ou autre. Victoi, y compris ses dirigeants, administrateurs et employés, ne peut être tenu responsable de toute responsabilité indirecte, consécutive ou spéciale découlant de ou liée de quelque manière que ce soit à votre utilisation de ce site Web.
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Violation des conditions                                </div>
                              <div className='f00 my-2'>
                                    Chez Victoi, nous prenons au sérieux le respect de nos conditions générales. Nous attendons de tous les utilisateurs de nos services qu'ils se conforment aux conditions convenues afin d'assurer un environnement sûr et sécurisé pour toutes les personnes impliquées. En cas de violation de nos conditions, nous nous réservons le droit de prendre les mesures appropriées, qui peuvent inclure, mais sans s'y limiter :                               </div>
                              <ul>
                                    <li className='my-4 f00' >
                                          Suspension temporaire ou permanente des privilèges de compte.
                                    </li>
                                    <li className='my-4 f00' >
                                          Résiliation du compte de l'utilisateur et accès à nos services.
                                    </li>
                                    <li className='my-4 f00' >
                                          Action en justice, si nécessaire, pour protéger nos droits et les droits des autres utilisateurs.
                                    </li>
                              </ul>
                              <div className='f00 my-2'>

                                    Voici des exemples de violations :
                              </div>
                              <ul>

                                    <li className='my-4 f00' >
                                          Fournir des informations fausses ou trompeuses lors du processus d'enregistrement du compte.
                                    </li>
                                    <li className='my-4 f00' >
                                          Se livrer à des activités frauduleuses ou illégales.
                                    </li>
                                    <li className='my-4 f00' >
                                          Utilisation ou distribution non autorisée de la propriété intellectuelle de Victoi.                                     </li>
                                    <li className='my-4 f00' >
                                          Violer la sécurité de notre plate-forme ou tenter d'obtenir un accès non autorisé.
                                    </li>
                                    <li className='my-4 f00' >
                                          Harcèlement, abus ou comportement inapproprié envers d'autres utilisateurs ou le personnel de Victoi.
                                    </li>

                                    <li className='my-4 f00' >
                                    </li>
                              </ul>
                              <div>
                                    En utilisant les services de Victoi, vous acceptez de respecter nos termes et conditions. Les violations seront traitées en fonction de la gravité et peuvent entraîner la résiliation immédiate de votre compte sans préavis. Nous nous réservons le droit de modifier ou de mettre à jour nos conditions si nécessaire pour assurer la sécurité et l'intégrité de notre plateforme.
                              </div>
                        </div>




                        {/* <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}></div>
                              <div className='f00 my-2'></div>
                           
                        </div>  */}
                        {/* <li className='my-4 f00' >

                        </li> */}
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Responsabilité de l'utilisateur                               </div>
                              <div className='f00 my-2'>

                                    En tant qu'utilisateur de Victoi, vous êtes responsable de vos actes et des conséquences qu'ils peuvent avoir. Vous acceptez de ne pas vous livrer à une conduite qui pourrait être considérée comme illégale, nuisible, menaçante, abusive, harcelante, diffamatoire, vulgaire, obscène ou autrement répréhensible.
                              </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Age Restriction
                              </div>
                              <div className='f00 my-2'>

                                    Les services fournis par Victoi (la « Société ») sont destinés à être utilisés par des personnes âgées d'au moins 18 ans ou ayant atteint l'âge légal de la majorité dans leur juridiction, selon l'âge le plus élevé. En accédant ou en utilisant les services de la Société, vous déclarez et garantissez que vous répondez à l'exigence d'âge.                               </div>
                              <div className='f00 my-2'>

                                    Si la Société apprend qu'un utilisateur n'a pas l'âge requis, la Société se réserve le droit de suspendre ou de résilier son compte et de restreindre son accès aux services. La Société peut également prendre les mesures légales appropriées, si nécessaire, pour faire respecter cette restriction d'âge.
                              </div>

                              <div className='f00 my-2'>

                                    En acceptant ces termes et conditions, vous reconnaissez et comprenez qu'il est de votre responsabilité de vous assurer que vous remplissez l'âge requis avant d'accéder ou d'utiliser les services de la Société. La Société ne sera pas tenue responsable des dommages ou pertes subis en raison d'une fausse déclaration d'un utilisateur sur son âge ou d'une violation de cette clause de restriction d'âge.                               </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Exactitude et disponibilité                               </div>
                              <div className='f00 my-2'>

                                    Bien que nous nous efforcions de fournir des informations exactes et à jour sur notre site Web, nous ne faisons aucune garantie ou représentation d'aucune sorte, expresse ou implicite, quant à l'exhaustivité, l'exactitude, la fiabilité, la pertinence ou la disponibilité en ce qui concerne le site Web ou les informations, produits, services ou graphiques connexes contenus sur le site Web à quelque fin que ce soit.
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Liens de tiers                               </div>
                              <div className='f00 my-2'>
                                    Notre site Web peut contenir des liens vers des sites Web ou des services de tiers qui ne sont pas détenus ou contrôlés par Victoi. Nous n'avons aucun contrôle et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques des sites Web ou services tiers. Vous reconnaissez et acceptez que Victoi ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou prétendument causé par ou en relation avec l'utilisation ou la confiance accordée à un tel contenu, biens ou services disponibles sur ou via ces sites Web ou services.
                              </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Conclusion
                              </div>
                              <div className='f00 my-2'>
                                    En utilisant le site Web de Victoi, vous acceptez ces termes et conditions. Si vous n'êtes pas d'accord avec une partie de ces conditions, veuillez ne pas utiliser notre site Web. Nous nous réservons le droit de réviser ces termes et conditions à tout moment sans préavis. Il est de votre responsabilité de consulter cette page périodiquement pour les mises à jour ou les changements. Votre utilisation continue du site Web après l'affichage de tout changement constitue une acceptation de ces changements.                               </div>
                              <div className='f00 my-2'>
                                    Si vous avez des questions concernant ces termes et conditions, veuillez nous contacter à contact@victoi.com.  <a href='/support'>support@victoi.com.</a>
                              </div>

                        </div>





                  </div>


            )
      }
      useEffect(() => {
            seti(siteLanguage == "ENGLISH" ? <English /> : <French />)
      }, [siteLanguage])

      return (
            i
      )
}
