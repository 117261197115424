
import React, {useEffect} from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import LongText from '../Components/LongText';
import SmallCard from '../Components/SmallCard';
import victoircard from "../Assets/img/VC.png"
import img1 from "../Assets/icons/MPO.png"
import img2 from "../Assets/icons/BTU.png"
import img3 from "../Assets/icons/S.png"
import CardTwo from '../Components/CardTwo';
import {CardThree} from "../Components/CardThree";
import stock from "../Assets/img/stock.png";
import bill from "../Assets/img/bills.png"
import FirstCard from '../Components/FirstCard';
import {CardFour} from "../Components/CardFour";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify, success } from '../Components/toaster/toaster';
import { Toaster } from 'react-hot-toast';



function Home({siteLanguage,setSitelanguage}) {
  const {t} = useTranslation()
  useEffect(()=>{
  // success()
  // notify()
  },[])

  return (
    <>
       
        <FirstCard />
        <LongText />
        {/* <CardTwo
          headtext1="Stock Trading ="
          headtext2="Financial Freedom"
          text="It is no secret that he wealthy have always operated under a different set of rules. That is why they continue to amass wealth. With fractional share investment on Victoi App, anyone can buy shares of Nike, Apple, Amazon, Tesla and thousand of publicly traded companies for as little as $1 in any currency."
          img={stock}/> */}
        <CardFour
            headtext1={t("homepage_section3_textTitle1")}
            headtext2={t("homepage_section3_textTitle2")}
            text={t("homepage_section4_textBody1")}
            img={stock}
            switchPlace={false}
            page={"/InvestStocks"}
            />
        {/* <CardThree /> */}
        <CardFour 
            headtext1={t("homepage_section4_textTitle1")}
            headtext2={t("homepage_section4_textTitle2")}
            text={t("homepage_section4_textBody3")}
           img={victoircard}
           switchPlace={true}
           special={true}
            page={"/VictoiCard"}
            />
       

        {/* <CardTwo
        headtext1= "Tap to"
        headtext2="Pay bills & more"
        text="Instantly pay bills, buy mobile airtime and data bundles from your favorite provider in one place with just a few taps. Victoi makes life easy. just enjoy."
        img={bill}/> */}
        <CardFour
        headtext1= {t("homepage_section5_textTitle1")}
        headtext2={t("homepage_section5_textTitle2")}
        text={t("homepage_section5_textBody")}
        
         img={bill}
         switchPlace={false}
         page={"/PayBills"}
         />
        <SmallCard 
        Img1={img1} 
        Img2={img2} 
        Img3={img3} 
        head1={t("homepage_section6_Box1textTitle")}
        head2={t("homepage_section7_Box2textTitle")}
        head3={t("homepage_section8_Box3textTitle")}
        text1={t("homepage_section6_Box1textBody")}
        text2={t("homepage_section7_Box2textBody")}
        text3={t("homepage_section8_Box3textBody")} />
        <Toaster/>
    
{/* <button onClick={()=>{setSitelanguage("spannish")}}>Hello</button> */}
    </>
  )
}

export default Home
