

import "../Pages/Extrapages/extrapageCss/Support.css"
import search from "../Pages/Extrapages/icons/search.png"

export const Search=()=>{
    return(
        <>
        <div class="container A-input" >
        <div class="input-group">
                    <input type="text" class="form-control" placeholder="Type question here" />
                    <span class="input-group-text"><img src={search} alt="search icon" /></span>
                </div>
                </div>
        </>
    )
}