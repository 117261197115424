import Header from "./Header";
import Footer from "./Footer";
import gift from "../Assets/img/7.gif"
import "../Assets/css/empty.css"
import Footer3 from "./Footer3";
import React, {useEffect} from "react";
import android from "../Assets/img/down.png";
import Button from "@mui/material/Button";
export const Error404=()=>{
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return(
        <>
            <div className={'container d-flex flex-column justify-content-center empty-top'} style={{marginTop:'50px'}}>
                <div className={"col-sm-6"}>
                    <img src={gift} width={'78%'} alt={"gift"}/>

                </div>

                <h4 style={{fontWeight:'bold',fontSize:"1.5em",marginBottom:"10px"}}>COMING SOON.....</h4>
                <Button style={{border:"1px solid #8fff00",color:"#8fff00",marginTop:"20px",fontWeight:"bold"}}>Go To HomePage</Button>

            </div>
        </>

    )


}