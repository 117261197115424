import loadingIcon from '../../Assets/Gif Animation/5.gif'
import classes from "./loader.module.css"

function LoaderComponent(){
      return(
            <div className={classes.container}>
                  <div className={classes.loading}><img src={loadingIcon} alt='loading' /></div>
            </div>
      )
}
export default LoaderComponent