import React, {useEffect} from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'

function TermsConditions() {

  return (
    <>
      <Header />
        {/* <div className="Container" style={{display: "flex", border: "1px solid red", justifyContent: "center", alignItems:"center"}}>
            <h1 style={{color: "white"}}>COMING SOON</h1>
        </div> */}
      {/* <Footer /> */}
      <div>
        coming soon. 
      </div>
    </>
  )
}

export default TermsConditions
