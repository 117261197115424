import React, {useEffect} from 'react'
import Header from '../Components/Header'
import Card from '../Components/Card'
import SmallCard from '../Components/SmallCard'
import Footer from '../Components/Footer';
import transfer from "../Assets/icons/TP.png";
import noFees from "../Assets/icons/ZF.png";
import instant from "../Assets/icons/IT.png";
import track from "../Assets/icons/TT.png";
import LongText from '../Components/LongText';
import { CardThree } from '../Components/CardThree';
import PageCards from '../Components/PageCards';
import trans from "../Assets/img/trans.png";
import PageText from '../Components/PageText';
import Footer2 from "../Components/Footer2";
import Footer3 from "../Components/Footer3";
import { useTranslation } from 'react-i18next';



function TransferAndPayment() {
  const {t} = useTranslation()

  return (
    <>
    <PageCards

    img={trans}

      headtext={t("transferandpayment_transferandpayment_texttitle")}
    text={t("productpage_section1_textBody")} />
     <PageText
    headtext={t("transferandpayment_2sdsection_texttitle")}
    text={t("transferandpayment_2sdsection_textbody")}/>

    <SmallCard 
        Img1={noFees} 
        Img2={instant} 
        Img3={track} 
        head1={t("transferandpayment_1stsmallbox_texttitle")}
        head2={t("transferandpayment_2sdsmallbox_texttitle")}
        head3={t("transferandpayment_3rdsmallbox_texttitle")}
        text1={t("transferandpayment_1stsmallbox_textbody")}
        text2={t("transferandpayment_2sdsmallbox_textbody")}
        text3={t("transferandpayment_3rdsmallbox_textbody")}/>
    
    </>
  )
}

export default TransferAndPayment
