import React, {useEffect} from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Card from '../Components/Card';
import SmallCard from '../Components/SmallCard';
import bills from "../Assets/icons/PB.png";
import skip from "../Assets/icons/STL.png";
import hastle from "../Assets/icons/HF.png";
import wallet from "../Assets/icons/SM.png";
import "../Assets/css/Victoi.css";
import LongText from '../Components/LongText';
import PageCards from '../Components/PageCards';
import bill from "../Assets/img/bills.png";
import PageText from '../Components/PageText';
import Footer3 from "../Components/Footer3";
import { useTranslation } from 'react-i18next';

function PayBills() {
  const {t} = useTranslation()

  return (
    <>
      <br/>
    <PageCards 
    img={bill}
    headtext={t("paybill_1stsection_texttitle")}
    text={t("paybill_1stsection_textbody")}
    />
    <PageText 
    headtext={t("paybill_2sdsection_texttiltle")}
    text={t("productpage_section3.1_textBody")}/>
    <SmallCard 
    Img1={skip} 
    Img2={hastle} 
    Img3={wallet} 
    head1={t("productpage_section3.3_1stsmallbox")}
    head2={t("paybill_2cdsmallbox_texttiltle")}
    head3={t("paybill_3rdsmallbox_texttitle")}
    text1={t("productpage_section3.4_1stsmallboxbody")}
    text2={t("paybill_2Cdsmallbox_textbody")}
    text3={t("productpage_section4.2_3rtsmallboxbody")} />
   
  </>
  )
}

export default PayBills
