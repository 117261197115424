import { useState } from 'react'
import classes from '../KYCcomponent/docs.module.css'
import React from 'react'
import { useEffect } from 'react'

export default function Privacy({ siteLanguage }) {
      const [i, seti] = useState()

      const English = () => {
            return (
                  <div className={classes.container + ' mx-auto'}>
                        <h2 className='text-center'>                 <b> Privacy Policy</b>
                        </h2>
                        <div className='mx-4 f1 my-5'>
                              Last Updated: 22/12/2023
                        </div>
                        <div className='mx-4 f1 my-5'>Victoi Inc {"(\"Victoi,\" \"we,\" \"us,\" or \"our\")"} is committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website at www.victoi.com (the "Website") or use our services. By accessing our website or using our services, you consent to the practices described in this Privacy Policy. At Victoi, we prioritize the privacy and security of our clients' personal information. As a fintech company, we understand the importance of adopting robust data security measures to comply with evolving regulatory standards. We are committed to protecting the sensitive personal data of our clients and ensuring that they have control over their information to achieve this, we implement strong encryption methods, secure data storage practices, and access controls to prevent unauthorized access to customer information. By embedding security into our design phase and regularly testing and auditing our systems, we aim to reduce vulnerabilities and safeguard the stability of our platform. We believe in transparency and trust, and we continuously strive to improve our privacy practices to ensure the utmost protection of your personal information. </div>


                        {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}>{data?.head}</div>
                                    <div className='f00 my-2' dangerouslySetInnerHTML={{ __html: data?.body }}></div>
                                    data.links && <>
                                          <ul className='f00 mx-3'>
                                                {data.links.map((link) => <li className='my-4 f00' dangerouslySetInnerHTML={{ __html: link.body }}></li>)
                                          </ul>
                                    </>
                              </div> */}

                        {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}></div>
                                    <div className='f00 my-2'></div>
                                 
                              </div>  */}
                        {/* <li className='my-4 f00' >
      
                              </li> */}
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Objective
                              </div>
                              <div className='f00 my-2'></div>

                              The objective of Victoi's privacy policy is to ensure the highest level of privacy protection for our users. We are committed to safeguarding the personal information entrusted to us and maintaining the confidentiality, integrity, and availability of this data. Our focus is on implementing robust encryption measures to protect against unauthorized access and ensuring strict access controls are in place to limit data access to authorized personnel only. We conduct rigorous system testing and auditing to identify and address any vulnerabilities, and we continuously strive to improve our privacy practices to adapt to evolving threats and industry standards. By adhering to these objectives, we aim to provide our users with peace of mind and confidence in the security of their personal information.
                        </div>




                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Our Privacy Principles
                              </div>
                              <div className='f00 my-2'> The privacy policy outlined by Victoi has several key objectives, all aimed at ensuring the privacy and security of clients' personal information:

                              </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b>Compliance with regulatory standards:</b>  Victoi acknowledges the importance of adhering to evolving data protection regulations. By implementing robust data security measures, the company aims to meet and exceed the requirements outlined by regulatory bodies.

                                    </li>  <li className='my-4 f00' >

                                          <b>Protection of sensitive personal data:</b> Victoi understands the sensitivity of the personal information provided by clients. The privacy policy aims to safeguard this data from unauthorized access, ensuring that it remains confidential and secure.

                                    </li>
                                    <li className='my-4 f00' >

                                          <b>User control and transparency:</b> Victoi believes in empowering its clients by providing them with control over their personal information. The privacy policy aims to inform users about the data collected, how it is used, and the options available to manage their information.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Encryption and secure data storage:</b> To maintain the integrity of client data, Victoi employs strong encryption methods and secure data storage practices. These measures prevent unauthorized access and protect information from potential breaches.

                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Access controls and user authentication:</b> Victoi implements stringent access controls to prevent unauthorized access to client information. By employing user authentication methods, the privacy policy aims to ensure that only authorized individuals can access sensitive data.

                                    </li>
                                    <li className='my-4 f00' >

                                          <b>System testing and auditing:</b> Victoi recognizes the importance of regularly testing and auditing its systems to identify and address vulnerabilities. By proactively monitoring and assessing security measures, the company aims to enhance the stability and security of its platform.

                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Continuous improvement:</b> Victoi is committed to continuously improving its privacy practices. By regularly reviewing and updating its privacy policy, the company aims to adapt to changing regulations and technological advancements, providing clients with the highest level of privacy protection.

                                    </li>

                              </ul>
                              <div className='f00 my-2'>

                                    Overall, the objectives of Victoi's privacy policy revolve around maintaining the trust and confidence of its clients by prioritizing the privacy and security of their personal information.

                              </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Information We Collect
                              </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b>Personal Information:</b> We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, Payment Information and other contact details when you register for an account, subscribe to our newsletter, or contact us through our website.

                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Usage Information:</b> We may collect information about your use of our website and services, including your browser type, and operating system. We may also collect information about your interactions with our website, such as the pages you visit and the links you click.

                                    </li>
                              </ul>
                              <div className='f00 my-2'>

                                    This Usage Data may include information such as your computer’s Internet Protocol address {"(e.g., IP address)"}, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

                              </div>
                              <div className='f00 my-2'>

                                    When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
                              </div>

                              {/* <div className='f00 my-2'>
      
                                    To use Victoi's services, individuals and businesses need to create an account with Victoi by providing the required information and completing the necessary verification processes. Once registered, they can access the services through Victoi's website, mobile app, or by reaching out to Victoi's customer support team for assistance.
      
                              </div> */}




                        </div>



                        {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}></div>
                                    <div className='f00 my-2'></div>
                                 
                              </div>  */}
                        {/* <li className='my-4 f00' >
      
                              </li> */}
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    How We Use Your Information

                              </div>
                              <ul>

                              </ul>
                              <li className='my-4 f00' >


                                    <b>Providing Services:</b> To provide our services to you, process transactions, and respond to your inquiries. The collected data helps Victoi manage client accounts, including processing transactions, verifying identity, and providing customer support.

                              </li><li className='my-4 f00' >


                                    <b>Analytics:</b> To analyze and improve our website and services, including understanding user preferences and trends.

                              </li>


                              <li className='my-4 f00' >

                                    <b>Communication And Marketing: </b>To communicate with you about our services, updates, and promotional offers. Victoi may use client data to communicate important updates, provide relevant financial information, and deliver targeted marketing campaigns, subject to the client's consent and preferences.
                              </li>
                              <li className='my-4 f00' >
                                    <b> Risk Assessment and Fraud Prevention: </b>Victoi uses data to assess and manage risks associated with financial transactions, detect, and prevent fraudulent activities, and ensure the security of its services.
                              </li>
                              <li className='my-4 f00' >

                                    <b>  Legal Compliance: </b>To comply with applicable laws and regulations. Victoi may use the data to comply with applicable laws and regulations, including anti-money laundering{" (AML) and know-your-customer (KYC) "}requirements.
                              </li>
                              {/* </div> */}
                              {/* <li className='my-4 f00' >
      
                                    <b> Indemnification:</b> By using Victoi's services, individuals and businesses agree to indemnify and hold Victoi, its directors, officers, employees, and agents harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorney fees) arising out of or related to the use of Victoi's services, including but not limited to:
                                    <ul>
                                          <li className='my-4 f00' >
                                                Any unauthorized access to or use of the individual's or business's Victoi account.
      
                                          </li>
      
                                          <li className='my-4 f00' >
      
                                                Any breach of the terms and conditions or any applicable laws and regulations.
      
                                          </li>
                                          <li className='my-4 f00' >
      
                                                Any negligence, fraud, or misconduct by the individual or business.
      
                                          </li>
                                          <li className='my-4 f00' >
                                                Any violation of third-party rights, including intellectual property rights
      
                                          </li>
      
                                          <li className='my-4 f00' >
      
                                                Any financial or investment decisions made by the individual or business based on information provided by Victoi's services.
      
                                          </li>
                                    </ul>
      
                              </li> */}

                              {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}></div>
                                    <div className='f00 my-2'></div>
                                 
                              </div>  */}
                              {/* <li className='my-4 f00' >
      
                              </li> */}
                              {/* <li className='my-4 f00' >
      
                                    <b>Defense and Settlement:</b> Victoi reserves the right to assume the defense and control of any matter subject to indemnification by the individual or business. The individual or business agrees to cooperate with Victoi in asserting any available defenses. Victoi will not settle any claim without the individual's or business's prior written consent, which shall not be unreasonably withheld.
      
                              </li>
                              <li className='my-4 f00' >
      
                                    <b> Limitation of Liability:</b> In no event shall Victoi, its directors, officers, employees, or agents be liable to the individual or business for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with the use of Victoi's services. Victoi's liability, if any, shall be limited to the amount paid by the individual or business for the use of Victoi's services.
      
                              </li>
                              <li className='my-4 f00' >
      
                                    <b>Class Action Waiver:</b> You agree that any disputes between you and Victoi shall be resolved on an individual basis and not in any class, consolidated, or representative action. You and Victoi both waive any right to participate in a class action or class-wide arbitration. If for any reason a claim proceeds in court rather than in arbitration, you and Victoi agree that such claim shall be filed and litigated exclusively in the united states, and you consent to the personal jurisdiction of such courts.
      
                              </li>
                              <li className='my-4 f00' >
      
                                    <b>Severability:</b> If any provision of this indemnity clause is found to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not be affected or impaired.
      
                              </li>
      
      
      
                              By creating an account and using Victoi's services, individuals and businesses acknowledge and agree to the terms of this indemnity clause. It is recommended that individuals and businesses review this clause carefully and seek legal advice if necessary, before using Victoi's services.
                        */}</div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    How We Share Your Information
                              </div>
                              <div className='f00 my-2'>
                                    We may share your personal information with.
                              </div>
                              <ul>
                                    <li className='my-4 f00' >
                                          <b> Service Providers:</b> We may share information with third-party service providers who assist us in providing and improving our services.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Legal Requirements:</b> We may disclose your information when required by law, legal process, or government authorities.                               </li>
                                    <li className='my-4 f00' >
                                          <b>Business Transfers:</b> In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.                               </li>
                              </ul>
                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Location Data
                              </div>
                              <div className='f00 my-2'>
                                    We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.
                              </div>
                              <div className='f00 my-2'>
                                    You can enable or disable location services when you use our Service at any time by way of your device settings.                         </div>
                              {/* <ul>
                                    <li className='my-4 f00' >
      
                                          Publishing any website material in any other media
      
                                    </li>
                                    <li className='my-4 f00' >
      
                                          Selling, sublicensing, and/or otherwise commercializing any website material
      
                                    </li>
                                    <li className='my-4 f00' >
      
                                          Publicly performing and/or showing any website material
      
                                    </li>
                                    <li className='my-4 f00' >
      
                                          Using this website in any way that is or may be damaging to this website.
      
                                    </li>
                                    <li className='my-4 f00' >
      
                                          Using this website in any way that impacts user access to this website.
      
                                    </li>
                                    <li className='my-4 f00' >
      
                                          Using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity.
      
                                    </li>
                              </ul> */}
                        </div>
                        {/* <li className='my-4 f00' >
      
                              </li> */}


                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Data Security                         </div>
                              <div className='f00 my-2'>
                                    We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Circumstances Under Which We May Disclose Your Data                         </div>
                              {/* <div className='f00 my-2'>
                                    At Victoi, we take the adherence to our terms and conditions seriously. We expect all users of our services to comply with the agreed-upon terms to ensure a safe and secure environment for everyone involved. In the event of a violation of our terms, we reserve the right to take appropriate action, which may include but is not limited to:
                              </div> */}
                              <ul>
                                    <li className='my-4 f00' >
                                          <b>Service Providers and Partners:</b> Victoi may disclose personal data to third-party service providers and partners who assist in providing and improving our services. For example, when processing financial transactions, we may share relevant client information with payment processors.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Legal Requirements:</b> Victoi may disclose personal data when required to do so by applicable laws, regulations, legal processes, or government authorities. This includes situations where we are compelled to respond to subpoenas, court orders, or other legal requests.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Business Transfers:</b> In the event of a merger, acquisition, or sale of all or a portion of Victoi's assets, client personal data may be transferred to the acquiring entity. In such cases, we will take reasonable steps to ensure the security and confidentiality of the transferred data.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>With Client Consent:</b> Victoi may disclose personal data when clients have provided explicit consent for such disclosure. For example, if a client requests a third-party financial integration and authorizes the sharing of their data for this purpose, we will comply with their request.                               </li>
                                    <li className='my-4 f00' >
                                          <b>Protection of Rights:</b> Victoi may disclose personal data to protect our rights, privacy, safety, or property, as well as those of our clients or others. This includes sharing information to prevent fraud, enforce agreements, or respond to security threats.                               </li>
                                    <li className='my-4 f00' >
                                          <b>Emergency Situations:</b> In situations involving imminent danger or emergency, where personal data disclosure is necessary to protect an individual's life, health, or safety, Victoi may share relevant information as required.                               </li>
                              </ul>
                              <div className='f00 my-2'>

                                    It is essential to note that Victoi Inc will always take appropriate measures to safeguard the privacy and security of client personal data when disclosed under these circumstances. Victoi will also comply with applicable data protection laws and regulations when sharing personal information with third parties or authorities. Clients should review Victoi's Privacy Policy for more details on data disclosure practices.
                              </div>
                              {/* <ul>
      
                                    <li className='my-4 f00' >
                                          Providing false or misleading information during the account registration process.
      
                                    </li>
                                    <li className='my-4 f00' >
                                          Engaging in fraudulent or illegal activities.
      
                                    </li>
                                    <li className='my-4 f00' >
                                          Unauthorized use or distribution of Victoi's intellectual property.
      
                                    </li>
                                    <li className='my-4 f00' >
                                          Breaching the security of our platform or attempting to gain unauthorized access.
      
                                    </li>
                                    <li className='my-4 f00' >
                                          Harassment, abuse, or inappropriate behavior towards other users or Victoi staff.
      
                                    </li>
      
                                    <li className='my-4 f00' >
                                    </li>
                              </ul> */}
                              <div>
                                    By using Victoi's services you agree to abide by our terms and conditions. Violations will be dealt with according to the severity and may result in immediate termination of your account without prior notice. We reserve the right to modify or update our terms as needed to ensure the safety and integrity of our platform.

                              </div>
                        </div>




                        {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}></div>
                                    <div className='f00 my-2'></div>
                                 
                              </div>  */}
                        {/* <li className='my-4 f00' >
      
                              </li> */}
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Your Choices                         </div>
                              <div className='f00 my-2'>

                                    You have choices regarding how your personal information is used:
                              </div>
                              <ul>

                                    <li className='my-4 f00' >
                                          <b>Access and Correction:</b> You can access and update your personal information through your account settings.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b> Marketing Communications:</b> You can opt out of receiving marketing communications from us by following the instructions provided in our emails.
                                    </li>

                              </ul>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Legal basis for processing customer data                               </div>
                              <div className='f00 my-2'>

                                    {"At Victoi, we take your privacy very seriously. That is why we only process your data in accordance with the law. "}
                              </div>
                              <div className='f00 my-2'>

                                    The legal basis that we rely on to process your data will depend on the specific purpose of the processing. However, the most common legal bases for processing customer data are:
                              </div>

                              <ul>

                                    <li className='my-4 f00' >
                                          <b>Consent:</b> We may ask you for your consent to process your data for certain purposes, such as to send you marketing emails or to use your data to improve our products or services.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b> Contract:</b>We may need to process your data in order to fulfill a contract with you, such as to process a payment or to deliver a product or service.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b> Legitimate interest:</b>We may have a legitimate interest in processing your data for certain purposes, such as to prevent fraud or to personalize our marketing messages.
                                    </li>

                              </ul>
                              <div className='f00 my-2'>

                                    We will always be clear and upfront about how we collect and use your data, and we will give you control over your data. For example, you can opt out of receiving marketing communications from us at any time, and you can request access to, correct, and delete your data at any time.                         </div>
                              <div className='f00 my-2'>

                                    If you have any questions about how we process your data, please do not hesitate to contact us at <a href='/support'>support@victoi.com.</a>
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Changes to this Privacy Policy                         </div>
                              <div className='f00 my-2'>

                                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated Privacy Policy will be posted on our website, and the date of the last update will be indicated at the top of the policy.
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Contact Us                         </div>
                              <div className='f00 my-2'>
                                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href='/support'>support@victoi.com.</a>
                              </div>

                        </div>





                  </div>
            )
      }
      const French = () => {
            return (
                  <div className={classes.container + ' mx-auto'}>
                        <h2 className='text-center'>                 <b> Politique de confidentialité </b>
                        </h2>
                        <div className='mx-4 f1 my-5'>
                              Dernière mise à jour : 22/12/2023
                        </div>
                        <div className='mx-4 f1 my-5'>
                              Victoi Inc (« Victoi », « nous », « notre » ou « nos ») s'engage à protéger votre vie privée et vos informations personnelles. Cette politique de confidentialité explique comment nous recueillons, utilisons, divulguons et protégeons vos informations personnelles. lorsque vous visitez notre site Web à www.victoi.com (le « site Web ») ou utilisez nos services. En accédant à notre site Web ou en utilisant nos services, vous consentez aux pratiques décrites dans la présente politique de confidentialité. Chez Victoi, nous accordons la priorité à la confidentialité et à la sécurité des informations personnelles de nos clients. En tant qu'entreprise fintech, nous comprenons l'importance d'adopter des mesures de sécurité des données robustes pour nous conformer à l'évolution des normes réglementaires. Nous nous engageons à protéger les données personnelles sensibles de nos clients et à nous assurer qu'ils ont le contrôle de leurs informations pour y parvenir, nous mettons en œuvre des méthodes de cryptage fortes, des pratiques de stockage de données sécurisées et des contrôles d'accès pour empêcher tout accès non autorisé aux informations des clients. En intégrant la sécurité dans notre phase de conception et en testant et auditant régulièrement nos systèmes, nous visons à réduire les vulnérabilités et à préserver la stabilité de notre plate-forme. Nous croyons en la transparence et la confiance, et nous nous efforçons continuellement d'améliorer nos pratiques de confidentialité afin d'assurer la plus grande protection de vos informations personnelles.
                        </div>


                        {/* <div className='mx-4 f1 my-5'>
                                    <div className={classes.heavy + ' f1 my-2 bold '}></div>
                                    <div className='f00 my-2'></div>
                                 
                              </div>  */}
                        {/* <li className='my-4 f00' >
      
                              </li> */}
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Objectif                               </div>
                              <div className='f00 my-2'></div>

                              L'objectif de la politique de confidentialité de Victoi est d'assurer le plus haut niveau de protection de la vie privée de nos utilisateurs. Nous nous engageons à protéger les informations personnelles qui nous sont confiées et à maintenir la confidentialité, l'intégrité et la disponibilité de ces données. Nous nous concentrons sur la mise en œuvre de mesures de cryptage robustes pour protéger contre les accès non autorisés et nous assurons que des contrôles d'accès stricts sont en place pour limiter l'accès aux données au personnel autorisé uniquement. Nous effectuons des tests et des audits rigoureux des systèmes pour identifier et corriger toute vulnérabilité, et nous nous efforçons continuellement d'améliorer nos pratiques de confidentialité pour nous adapter à l'évolution des menaces et des normes de l'industrie. En adhérant à ces objectifs, nous visons à fournir à nos utilisateurs la tranquillité d'esprit et la confiance dans la sécurité de leurs informations personnelles.                         </div>




                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Nos principes de confidentialité                               </div>
                              <div className='f00 my-2'>
                                    La politique de confidentialité décrite par Victoi a plusieurs objectifs clés, tous visant à assurer la confidentialité et la sécurité des renseignements personnels des clients :
                              </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b>Respect des normes réglementaires :</b> Victoi reconnaît l'importance de respecter l'évolution des réglementations en matière de protection des données. En mettant en œuvre des mesures robustes de sécurité des données, l'entreprise vise à respecter et à dépasser les exigences définies par les organismes de réglementation.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Protection des données personnelles sensibles :</b> Victoi comprend la sensibilité des informations personnelles fournies par les clients. La politique de confidentialité vise à protéger ces données contre tout accès non autorisé, en veillant à ce qu'elles restent confidentielles et sécurisées.                                     </li>
                                    <li className='my-4 f00' >

                                          <b>Contrôle des utilisateurs et transparence :</b> Victoi croit en l'autonomisation de ses clients en leur donnant le contrôle de leurs renseignements personnels. La politique de confidentialité vise à informer les utilisateurs sur les données collectées, leur utilisation et les options disponibles pour gérer leurs informations.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Chiffrement et stockage sécurisé des données :</b>  Pour maintenir l'intégrité des données des clients, Victoi utilise des méthodes de cryptage solides et des pratiques de stockage de données sécurisées. Ces mesures empêchent l'accès non autorisé et protègent les renseignements contre les atteintes potentielles.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Contrôles d'accès et authentification des utilisateurs :</b> Victoi met en œuvre des contrôles d'accès rigoureux pour empêcher tout accès non autorisé aux renseignements des clients. En utilisant des méthodes d'authentification des utilisateurs, la politique de confidentialité vise à garantir que seules les personnes autorisées peuvent accéder aux données sensibles.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Tests et audits des systèmes :</b>  Victoi reconnaît l'importance de tester et d'auditer régulièrement ses systèmes afin d'identifier et de corriger les vulnérabilités. En surveillant et en évaluant de manière proactive les mesures de sécurité, l'entreprise vise à améliorer la stabilité et la sécurité de sa plate-forme.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Amélioration continue :</b> Victoi s'engage à améliorer continuellement ses pratiques en matière de protection de la vie privée. En révisant et en mettant à jour régulièrement sa politique de confidentialité, l'entreprise vise à s'adapter à l'évolution de la réglementation et aux progrès technologiques, offrant aux clients le plus haut niveau de protection de la vie privée.                                     </li>

                              </ul>
                              <div className='f00 my-2'>

                                    Dans l'ensemble, les objectifs de la politique de confidentialité de Victoi tournent autour du maintien de la confiance de ses clients en accordant la priorité à la confidentialité et à la sécurité de leurs renseignements personnels.                               </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Informations que nous recueillons                               </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b> Informations personnelles :</b> Nous pouvons collecter des informations personnelles que vous nous fournissez volontairement, telles que votre nom, votre adresse e-mail, votre numéro de téléphone, vos informations de paiement et d'autres coordonnées lorsque vous vous inscrivez pour un compte, vous abonnez à notre newsletter ou nous contactez via notre site Web.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Informations d'utilisation :</b>  Nous pouvons collecter des informations sur votre utilisation de notre site Web et de nos services, y compris votre type de navigateur et votre système d'exploitation. Nous pouvons également collecter des informations sur vos interactions avec notre site Web, telles que les pages que vous visitez et les liens sur lesquels vous cliquez.
                                    </li>
                              </ul>
                              <div className='f00 my-2'>

                                    Ces données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre ordinateur (par exemple, l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.                               </div>

                        </div>
                        <div className='f00 my-2'>

                              Lorsque vous accédez au Service avec un appareil, ces Données d'utilisation peuvent inclure des informations telles que le type d'appareil que vous utilisez, l'identifiant unique de votre appareil, l'adresse IP de votre appareil, le système d'exploitation de votre appareil, le type de navigateur Internet que vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    Comment nous utilisons vos informations
                              </div>

                              <div className='f00 my-2'>
                                    Victoi Inc, utilise les données qu'elle recueille auprès de ses clients à diverses fins commerciales légitimes. Voici quelques façons dont Victoi peut utiliser les données collectées :                               </div>
                              <ul>

                                    <li className='my-4 f00' >


                                          <b> Fournir des services   :</b> Pour vous fournir nos services, traiter les transactions et répondre à vos demandes. Les données recueillies aident Victoi à gérer les comptes clients, y compris le traitement des transactions, la vérification de l'identité et le soutien à la clientèle.
                                    </li>
                                    <li className='my-4 f00' >

                                          <b>Personnalisation :</b> Pour personnaliser votre expérience sur notre site Web et offrir du contenu et des services adaptés à vos intérêts. Victoi peut utiliser les données pour personnaliser et améliorer ses services, comme l'adaptation des recommandations financières ou la fourniture d'expériences personnalisées en fonction des objectifs et des préférences financières du client.
                                    </li>
                                    <li className='my-4 f00' >


                                          <b>Analyse :</b> Pour analyser et améliorer notre site Web et nos services, y compris la compréhension des préférences et des tendances des utilisateurs.
                                    </li>


                                    <li className='my-4 f00' >

                                          <b>Communication et marketing :</b> Pour communiquer avec vous au sujet de nos services, mises à jour et offres promotionnelles. Victoi peut utiliser les données des clients pour communiquer des mises à jour importantes, fournir des informations financières pertinentes et livrer des campagnes de marketing ciblées, sous réserve du consentement et des préférences du client.                               </li>
                                    <li className='my-4 f00' >
                                          <b>Évaluation des risques et prévention de la fraude :</b> Victoi utilise les données pour évaluer et gérer les risques associés aux transactions financières, détecter et prévenir les activités frauduleuses et assurer la sécurité de ses services.                               </li>
                                    <li className='my-4 f00' >

                                          <b>Conformité légale :</b> Pour se conformer aux lois et règlements applicables. Victoi peut utiliser les données pour se conformer aux lois et réglementations applicables, y compris les exigences en matière de lutte contre le blanchiment d'argent (AML) et de connaissance du client (KYC).                               </li>
                              </ul>
                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>

                                    Comment nous partageons vos informations                               </div>
                              <div className='f00 my-2'>
                                    Nous pouvons partager vos informations personnelles avec.                               </div>
                              <ul>
                                    <li className='my-4 f00' >

                                          <b>Fournisseurs de services :</b> Nous pouvons partager des informations avec des fournisseurs de services tiers qui nous aident à fournir et à améliorer nos services.
                                    </li>

                                    <li className='my-4 f00' >
                                          <b>Exigences légales :</b> Nous pouvons divulguer vos informations lorsque la loi, une procédure judiciaire ou les autorités gouvernementales l'exigent.
                                    </li>
                                    <li className='my-4 f00' >
                                          <b>Transferts d'entreprise :</b> En cas de fusion, d'acquisition ou de vente de tout ou partie de nos actifs, vos renseignements personnels peuvent être transférés à l'entité acquéreuse.

                                    </li>
                              </ul>
                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Données de localisation                               </div>
                              <div className='f00 my-2'>
                                    Nous pouvons utiliser et stocker des informations sur votre emplacement si vous nous en donnez la permission (« Données de localisation »). Nous utilisons ces données pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service.                               </div>
                              <div className='f00 my-2'>
                                    Vous pouvez activer ou désactiver les services de localisation lorsque vous utilisez notre Service à tout moment via les paramètres de votre appareil.                                                      </div>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Conservation et sécurité des données                                                     </div>
                              <div className='f00 my-2'>
                                    Chez Victoi, nous accordons la priorité à la sécurité et à la confidentialité des données de nos clients. Nous comprenons l'importance de protéger les informations sensibles et de maintenir la conformité avec les lois applicables en matière de protection des données. Cette clause de conservation et de sécurité des données souligne notre engagement à protéger les données des clients tout au long de leur cycle de vie. En mettant en œuvre des mesures de sécurité robustes, en adhérant aux meilleures pratiques et en nous associant à des fournisseurs de services de confiance, nous nous efforçons d'assurer la confidentialité, l'intégrité et la disponibilité des données qui nous sont confiées. Veuillez consulter la clause suivante pour comprendre comment nous gérons la conservation des données et maintenons un environnement sécurisé pour nos clients.
                              </div>
                              <ul>
                                    <li className='my-4 f00' >
                                          <b>Sécurité des données:</b>
                                          Nous ne conserverons les données des clients que le temps nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées ou si nécessaire pour nous conformer aux obligations légales. En cas de résiliation du compte du client ou d'expiration de la période de conservation applicable, nous supprimerons ou anonymiserons en toute sécurité toutes les données client conformément aux lois et réglementations applicables.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Fournisseurs de services tiers :</b> Nous pouvons engager des fournisseurs de services tiers pour nous aider à fournir ses services. Nous veillerons à ce que ces fournisseurs offrent des garanties suffisantes en ce qui concerne la sécurité et la confidentialité des données des clients. Nous conclurons des accords de protection des données appropriés avec ces fournisseurs de services, décrivant leurs obligations en matière de protection des données des clients et de respect des lois pertinentes en matière de protection des données.                                     </li>
                                    <li className='my-4 f00' >
                                          <b> Notification  de violation :</b> En cas de violation de données ou d'accès non autorisé aux données client, nous informerons rapidement les clients concernés, les autorités de réglementation et toute autre partie prenante concernée conformément aux lois et réglementations applicables. Nous prendrons des mesures correctives immédiates pour atténuer les effets de la violation, enquêterons sur l'incident et mettrons en œuvre des mesures pour prévenir de futurs incidents.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Conformité aux lois applicables :</b> Nous nous conformerons à toutes les lois, réglementations et normes industrielles applicables en matière de protection des données et de confidentialité régissant la collecte, l'utilisation, la conservation et la sécurité des données des clients. Nous surveillerons régulièrement les changements dans les lois pertinentes et mettrons à jour ses pratiques en conséquence pour assurer une conformité continue.                                           </li>
                              </ul>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className='f00 my-2'>
                                    Circonstances dans lesquelles nous pouvons divulguer vos données
                              </div>
                              <ul>
                                    <li className='my-4 f00' >
                                          <b>Fournisseurs de services et partenaires :</b>  Victoi peut divulguer des données personnelles à des fournisseurs de services tiers et à des partenaires qui aident à fournir et à améliorer nos services. Par exemple, lors du traitement des transactions financières, nous pouvons partager des informations client pertinentes avec des processeurs de paiement.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Exigences légales :</b> Victoi peut divulguer des données personnelles lorsque les lois, règlements, procédures judiciaires ou autorités gouvernementales applicables l'exigent. Cela inclut les situations où nous sommes obligés de répondre à des citations à comparaître, des ordonnances judiciaires ou d'autres demandes légales.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Transferts d'entreprise :</b> En cas de fusion, d'acquisition ou de vente de tout ou partie des actifs de Victoi, les données personnelles du client peuvent être transférées à l'entité acquéreuse. Dans de tels cas, nous prendrons des mesures raisonnables pour assurer la sécurité et la confidentialité des données transférées.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Avec le consentement du client :</b> Victoi peut divulguer des données personnelles lorsque les clients ont donné leur consentement explicite pour une telle divulgation. Par exemple, si un client demande une intégration financière tierce et autorise le partage de ses données à cette fin, nous nous conformerons à sa demande.                                            </li>
                                    <li className='my-4 f00' >
                                          <b>Protection des droits :</b> Victoi peut divulguer des données personnelles pour protéger nos droits, notre vie privée, notre sécurité ou nos biens, ainsi que ceux de nos clients ou d'autres personnes. Cela inclut le partage d'informations pour prévenir la fraude, faire respecter les accords ou répondre aux menaces de sécurité.                                             </li>
                                    <li className='my-4 f00' >
                                          <b>Situations d'urgence :</b> Dans les situations impliquant un danger imminent ou une urgence, où la divulgation de données personnelles est nécessaire pour protéger la vie, la santé ou la sécurité d'une personne, Victoi peut partager des informations pertinentes au besoin.                                             </li>
                              </ul>
                              <div className='f00 my-2'>

                                    Il est essentiel de noter que Victoi Inc prendra toujours les mesures appropriées pour protéger la confidentialité et la sécurité des données personnelles des clients lorsqu'elles sont divulguées dans ces circonstances. Victoi se conformera également aux lois et règlements applicables en matière de protection des données lors du partage de renseignements personnels avec des tiers ou des autorités. Les clients devraient consulter la politique de confidentialité de Victoi pour plus de détails sur les pratiques de divulgation des données.                               </div>

                             
                        </div>



                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Vos choix                                                        </div>
                              <div className='f00 my-2'>

                                    Vous avez le choix quant à la façon dont vos renseignements personnels sont utilisés :                               </div>
                              <ul>

                                    <li className='my-4 f00' >
                                          <b>Accès et correction :</b> Vous pouvez accéder à vos informations personnelles et les mettre à jour via les paramètres de votre compte.
                                    </li>

                                    <li className='my-4 f00' >
                                          <b>Communications marketing :</b> Vous pouvez choisir de ne plus recevoir de communications marketing de notre part en suivant les instructions fournies dans nos e-mails.

                                    </li>
                              </ul>

                        </div>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Base juridique pour le traitement des données client                                                           </div>
                              <div className='f00 my-2'>

                                    Chez Victoi, nous prenons votre vie privée très au sérieux. C'est pourquoi nous ne traitons vos données que conformément à la loi.                               </div>
                              <div className='f00 my-2'>

                                    La base juridique sur laquelle nous nous appuyons pour traiter vos données dépendra de la finalité spécifique du traitement. Cependant, les bases juridiques les plus courantes pour le traitement des données des clients sont les suivantes :                               </div>

                              <ul>

                                    <li className='my-4 f00' >
                                          <b> Consentement :</b> Nous pouvons vous demander votre consentement pour traiter vos données à certaines fins, telles que vous envoyer des e-mails marketing ou utiliser vos données pour améliorer nos produits ou services.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Contrat :</b> Nous pouvons avoir besoin de traiter vos données afin d'exécuter un contrat avec vous, par exemple pour traiter un paiement ou pour livrer un produit ou un service.                                     </li>
                                    <li className='my-4 f00' >
                                          <b>Intérêt légitime :</b> Nous pouvons avoir un intérêt légitime à traiter vos données à certaines fins, telles que la prévention de la fraude ou la personnalisation de nos messages marketing.                                     </li>

                              </ul>
                              <div className='f00 my-2'>

                                    Nous serons toujours clairs et francs sur la façon dont nous recueillons et utilisons vos données, et nous vous donnerons le contrôle de vos données. Par exemple, vous pouvez choisir de ne plus recevoir de communications marketing de notre part à tout moment, et vous pouvez demander l'accès, la correction et la suppression de vos données à tout moment.                                      </div>
                              <div className='f00 my-2'>
                                    Si vous avez des questions sur la manière dont nous traitons vos données, n'hésitez pas à nous contacter à <a href='/support'>support@victoi.com.</a>
                              </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Modifications de la présente politique de confidentialité
                              </div>
                              <div className='f00 my-2'>

                                    Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter des changements dans nos pratiques ou pour d'autres raisons opérationnelles, juridiques ou réglementaires. La politique de confidentialité mise à jour sera affichée sur notre site Web et la date de la dernière mise à jour sera indiquée en haut de la politique.                               </div>

                        </div>

                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>
                                    Contactez-nous
                              </div>
                              <div className='f00 my-2'>
                                    Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité ou nos pratiques en matière de données, veuillez nous contacter à   <a href='/support'>support@victoi.com.</a>
                              </div>

                        </div>





                  </div>
            )
      }
      useEffect(() => {
            seti(siteLanguage == "ENGLISH" ? English : French)
      }, [siteLanguage])
      return (
            i
      )
}
