import React from 'react'
import '../Assets/css/video.css.css'

export const  Videos=()=> {
  return (
      <>
      <div className="A-first-investment1" style={{marginTop:'50px'}}>
          <iframe width="853" height="480" src="https://www.youtube.com/embed/1YyAzVmP9xQ"
                  title="Cryptocurrency In 5 Minutes | Cryptocurrency Explained | What Is Cryptocurrency? | Simplilearn"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen className="car-racing1"></iframe>
          <h3>MY FIRST INVESTMENT</h3>
          <p>We've put together information to help you make your first investment</p>
      </div>
    <div className="A-first-investment2" style={{height:'30vh',overflow:'hidden'}}>
        <p className="A-left-heading">More videos</p>
        <p className="A-right-heading">View All</p>
        <div style={{padding:'10px',marginTop:'20px',alignItems:'center',marginLeft:'46px'}}>
            <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                    title="How does a blockchain work - Simply Explained" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                    title="How does a blockchain work - Simply Explained" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                    title="How does a blockchain work - Simply Explained" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
            <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                    title="How does a blockchain work - Simply Explained" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </div>
    </div>
    <div className="A-mobile-first-investment2">
        <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                title="How does a blockchain work - Simply Explained" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        <hr/>
        <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                title="How does a blockchain work - Simply Explained" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        <hr/>
        <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                title="How does a blockchain work - Simply Explained" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        <hr/>
        <iframe width="853" height="480" src="https://www.youtube.com/embed/SSo_EIwHSd4"
                title="How does a blockchain work - Simply Explained" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        <p className="A-view-more-videos">View More Videos</p>
    </div>
      </>
  )
}

export default Videos
