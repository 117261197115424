import classes from './docs.module.css'

import React, { useEffect, useState } from 'react'

export default function Kyc({ siteLanguage }) {
      const [data,setdata]=useState([])
      const disclaimerenglishdata = [
            {
                  head: "Disclaimer",
                  body: "<b>Victoi Inc (\"Victoi,\" \"we,\" \"us,\" or \"our\") </b>provides financial services and information for informational purposes only. As a leading fintech platform, we strive to offer valuable tools and resources. However, it is important to understand the scope and limitations of our services as outlined in this disclaimer. By accessing and using Victoi's services, users acknowledge and agree to the terms and conditions detailed herein. "
            },
            {
                  head: "Use of APIs ",
                  body: "Victoi leverages Application Programming Interfaces (APIs) to enhance our service offerings. While these APIs facilitate access to a range of financial services, it is crucial to note that their use does not imply our endorsement or guarantee of the services provided by third-party entities. We are not responsible for the content, accuracy, or reliability of third-party services accessed through our platform."
            },
            {
                  head: "No Investment Advice ",
                  body: "The content available on our website, including but not limited to financial data, analyses, and recommendations, is provided for general informational purposes. It should not be construed as personalized investment advice. Investment decisions made based on our content are solely at the user's discretion and risk. "
            },
            {
                  head: "User Responsibility",
                  body: "Users bear the responsibility for conducting independent research and due diligence prior to engaging in any financial transactions or investments based on the information provided by Victoi. We strongly encourage consulting with qualified financial advisors for personalized advice before making any investment decisions."
            },
            {
                  head: "No Liability ",
                  body: "Victoi Inc disclaims all liability for any reliance on the information available on our website or for actions taken based on such information. We are not liable for any financial losses or damages incurred as a result of using our services or relying on the information we provide."
            },
            {
                  head: "Consultation with Professionals ",
                  body: "We advise our users to seek guidance from licensed financial, legal, or other relevant professionals when considering significant financial decisions."
            },
            {
                  head: "Changes to Information and Services ",
                  body: "Our website's content and services may undergo changes without prior notice. We do not guarantee the accuracy, completeness, or timeliness of the information provided. "
            },
            {
                  head: "Errors and Omissions Disclaimer",
                  body: "Victoi Inc endeavors to ensure the reliability of the information presented on our website and mobile app, but we cannot guarantee its absolute accuracy or completeness. We provide this information \"as is,\" without any express or implied warranties, including warranties of performance, merchantability, or fitness for a particular purpose. Victoi Inc will not be liable for any decisions made or actions taken in reliance on this information, nor for any consequential damages arising therefrom."
            },
            {
                  head: "Contact Us",
                  body: "Victoi is committed to safeguarding user data in compliance with applicable data protection laws. However, the security of data transmitted over the internet cannot be entirely guaranteed. Users are encouraged to familiarize themselves with our Privacy Policy for further information on our data protection practices."
            },
            {
                  body: "For inquiries or concerns regarding this disclaimer or our services, please contact us at <a href='/contact'> contact@victoi.com.</a>"
            }

      ]
      const englishdata = [
            {
                  head: "Know Your Customer (KYC) ",
                  body: "At Victoi, we are dedicated to adhering to the highest standards of legal and regulatory compliance, particularly concerning Know Your Customer (KYC) norms. Our commitment extends to ensuring the integrity of our financial transactions and maintaining the trust of our clients.",
                  links: [
                        {
                              head: "Identity Verification",
                              body: "<b>Identity Verification:</b> To establish a relationship with Victoi, all customers must undergo a thorough identity verification process. This process requires customers to provide valid and current identification documents such as a National ID Card, Driver’s License, Resident Permit, or Passport. In certain cases, we may also require proof of address, such as utility bills or bank statements."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Reporting Obligations:</b> Any suspicious activities will be reported to the appropriate legal authorities as per the mandates of US and international AML regulations. This is a critical part of our role in preventing illicit financial activities."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Customer Cooperation:</b> We expect full cooperation from our customers in our AML efforts. This may include requests for additional documentation or information pertaining to certain transactions."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Service Refusal and Suspension:</b> Victoi reserves the right to refuse or suspend services to any customer if there is a reasonable suspicion of illegal or fraudulent activities. "
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Record Keeping:</b> In compliance with AML regulations, we maintain detailed records of all customer transactions and verifications. These records are kept secure and confidential, in accordance with our privacy policy and legal requirements."
                        },
                  ]
            },
            {
                  head: "Anti-Money Laundering (AML) ",
                  body: "Victoi is unwavering in its commitment to combatting money laundering, terrorist financing, and other illegal financial activities. ",
                  links: [
                        {
                              body: "<b>Transaction Monitoring:</b> We closely monitor all customer transactions for signs of suspicious activity. This includes analyzing transaction patterns, investigating unusual or large transactions, and identifying transactions that do not conform to the customer’s typical business profile. "
                        },
                        {
                              body: "<b>Reporting Obligations:</b> Any suspicious activities will be reported to the appropriate legal authorities as per the mandates of US and international AML regulations. This is a critical part of our role in preventing illicit financial activities. "
                        },
                        {
                              body: "<b>Customer Cooperation:</b> We expect full cooperation from our customers in our AML efforts. This may include requests for additional documentation or information pertaining to certain transactions."
                        },
                        {
                              body: "<b>Service Refusal and Suspension:</b> Victoi reserves the right to refuse or suspend services to any customer if there is a reasonable suspicion of illegal or fraudulent activities."
                        },
                        {
                              body: "<b>Record Keeping:</b> In compliance with AML regulations, we maintain detailed records of all customer transactions and verifications. These records are kept secure and confidential, in accordance with our privacy policy and legal requirements. "
                        }
                  ]
            },
            {
                  head: "Your Role in Compliance",
                  body: "Our customers play a crucial role in maintaining the integrity of our financial ecosystem. We encourage you to remain vigilant and report any suspicious activities or concerns regarding financial transactions. Together, we can ensure a secure, transparent, and compliant financial environment.<br/>For more information or to report a concern, please contact our compliance team at compliance@victoi.com."
            },
            {
                  head: "Continual Improvement",
                  body: "Victoi is committed to regularly reviewing and updating its KYC and AML policies to align with evolving regulations and best practices in the industry. We strive to set the standard for compliance and security in financial services, ensuring a safe and trustworthy platform for all our users."
            }
      ]
      const frenchdata = [
            {
                  head: "Connaissance du Client (CDD) ",
                  body: "Chez Victoi, nous nous engageons à respecter les normes les plus élevées en matière de conformité légale et réglementaire, en particulier concernant les normes de Connaissance du Client (CDD). Notre engagement vise à assurer l'intégrité de nos transactions financières et à maintenir la confiance de nos clients.",
                  links: [
                        {
                              head: "Identity Verification",
                              body: "<b>Vérification de l'Identité:</b> Pour établir une relation avec Victoi, tous les clients doivent passer par un processus rigoureux de vérification d'identité. Ce processus exige des clients de fournir des documents d'identification valides et actuels tels qu'une carte d'identité nationale, un permis de conduire, un permis de séjour ou un passeport. Dans certains cas, nous pourrions également demander un justificatif de domicile, comme des factures de services publics ou des relevés bancaires."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Diligence Raisonnable du Client :</b> Dans le cadre de notre diligence raisonnable, nous collecterons des informations concernant la nature de vos activités commerciales, la source de vos fonds et votre historique financier. Ceci est essentiel pour comprendre le profil du client et surveiller toute activité qui s'écarte du modèle attendu."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Surveillance Continue :</b> Nous surveillons en continu les comptes des clients pour assurer la conformité avec les réglementations CDD. Cela inclut la revue des transactions pour les irrégularités et la mise à jour périodique des informations des clients."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Vérification par des Tiers :</b> Pour renforcer la précision de notre processus de vérification, nous pouvons utiliser des services tiers fiables."
                        },
                        {
                              head: "Identity Verification",
                              body: "<b>Responsabilité et Conséquences :</b> Les clients sont responsables de fournir des informations précises et complètes. Le manquement à fournir de telles informations, ou la fourniture d'informations trompeuses, peut entraîner le refus de service ou la résiliation des services existants."
                        },
                  ]
            },
            {
                  head:"Lutte contre le Blanchiment d'Argent et le Financement du Terrorisme (LCB-FT) ",
                  body:"Victoi est résolue dans son engagement à combattre le blanchiment d'argent, le financement du terrorisme et d'autres activités financières illégales. ",
                  links:[
                        {
                              body:"<b>Surveillance des Transactions :</b> Nous surveillons de près toutes les transactions des clients pour détecter les signes d'activité suspecte. Cela inclut l'analyse des modèles de transactions, l'investigation sur les transactions inhabituelles ou importantes, et l'identification des transactions qui ne correspondent pas au profil commercial habituel du client. "
                        },
                        {
                              body:"<b>Obligations de Déclaration :</b> Toute activité suspecte sera signalée aux autorités légales compétentes conformément aux mandats des réglementations LCB-FT américaines et internationales. C'est un élément crucial de notre rôle dans la prévention des activités financières illicites. "
                        },
                        {
                              body:"<b>Coopération du Client :</b> Nous attendons une coopération totale de nos clients dans nos efforts LCB-FT. Cela peut inclure des demandes de documentation supplémentaire ou d'informations relatives à certaines transactions. "
                        },
                        {
                              body:"<b>Refus et Suspension de Service :</b> Victoi se réserve le droit de refuser ou de suspendre les services à tout client s'il existe un soupçon raisonnable d'activités illégales ou frauduleuses. "
                        },
                        {
                              body:"<b>Tenue des Dossiers :</b> Conformément aux réglementations LCB-FT, nous conservons des dossiers détaillés de toutes les transactions et vérifications des clients. Ces dossiers sont conservés de manière sécurisée et confidentielle, conformément à notre politique de confidentialité et aux exigences légales."
                        }
                  ]

            },
            {
                  head: "Votre Rôle dans la Conformité ",
                  body: "Nos clients jouent un rôle crucial dans le maintien de l'intégrité de notre écosystème financier. Nous vous encourageons à rester vigilants et à signaler toute activité suspecte ou préoccupations concernant les transactions financières. Ensemble, nous pouvons garantir un environnement financier sécurisé, transparent et conforme.<br/>Pour plus d'informations ou pour signaler une préoccupation, veuillez contacter notre équipe de conformité à compliance@victoi.com.  "
            },
            {
                  head: "Amélioration Continue",
                  body: "Victoi s'engage à réviser et mettre à jour "
            }
      ]
      useEffect(()=>{
            setdata(
                  siteLanguage=="ENGLISH"?englishdata:frenchdata
            )
      },[siteLanguage])
      return (
            <div className={classes.container + " mx-auto"}>
                  <h2 className='bold text-center'><b>{siteLanguage=="ENGLISH"?"KYC & AML Policy":"Politique de CVC et LBC"}</b></h2>

                  {data.map((data) =>
                        <div className='mx-4 f1 my-5'>
                              <div className={classes.heavy + ' f1 my-2 bold '}>{data?.head}</div>
                              <div className='f00 my-2' dangerouslySetInnerHTML={{ __html: data?.body }}></div>
                              {data.links && <>
                                    <ul className='f00 mx-3'>
                                          {data.links.map((link) => <li className='my-4 f00' dangerouslySetInnerHTML={{ __html: link.body }}></li>)}
                                    </ul>
                              </>}
                              {/* hell world href */}
                        </div>
                  )}
            </div>
      )
}
