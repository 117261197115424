import React from 'react'
import "../Assets/css/longtext.css"
import {Box, Container, Typography} from "@mui/material";
import {TypeAnimation} from "react-type-animation";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import TypewriterComponent from "typewriter-effect";
import font from "../Assets/fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf"
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';


function LongText() {
    const {t} = useTranslation()
  return (
    <>

    <div>
    <div className="main1">
        <div>
            <Container maxWidth="sx" className="p-text1">
                <Box className="specialText1" style={{marginBottom:'20px', marginTop:"2%",fontFamily:" 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
                    <h4  className={'headTextl f4'} style={{fontWeight:"bold"}} >

                        {t("homepage_section2_textTitle")}


                    </h4>
                    <h4   className={'sec-text f2'} >
                        <TypewriterComponent
                       
                            options={{
                                autoStart:true,
                                loop:true,
                                delay:40,
                                strings:[
                                    t("homepage_section2_changingText1"),
                                    t("homepage_section2_changingText2"),
                                    t("homepage_section2_changingText3")
                                ]
                            }}

                        />




                    </h4>
                </Box>
                <Typography variant={'p'} className="para f2">
                    {t("homepage_section2_textBody1")}
                </Typography>
                <br/>
                <br/>

                <p className="para f2">{t("homepage_section2_textBody2")}</p>
                <p className="longtextbtn">
                    <Link to={"/TransferAndPayment"} className='f1' style={{textDecoration:"none",color:"var(--green)"}}><HorizontalRuleIcon/> {t("homepage_section2_link")}</Link>
                </p>

            </Container>
            
            
          
           
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossorigin="anonymous"></script>
    </div>
    </>
  )
}




      

export default LongText
