import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Container, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import transfer from "../Assets/icons/TP.png";
import card from "../Assets/icons/VC.png";
import bills from "../Assets/icons/PB.png";
import stock from "../Assets/icons/IS.png";
import crypto from "../Assets/icons/IC.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import '../Assets/css/dropDown.css'
import Logo from "../Assets/Logos/FCG_LG.png";
import {useState} from "react";
import AppleModal from "./A-modal";
import lines from "../Assets/icons/lines.png";
import { Fullscreen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'relative',
    display:'flexRow',
    width: '100%',
    bgcolor: 'var(--bg-primary)',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    alignItems:'center'
};
const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function MegaDropDown({open,setOpen,switchState}) {
    const {t} = useTranslation()
    const [active, setActive] = useState(true);

    const handleOpen = () => {
        setActive(!open);
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false);
        setActive(!open);

    }

    const [btnState,setBtnState] = useState(true);

    let toggleActive = btnState ? ' active' : null;





    return (
        <div style={{marginRight:"10%"}}>
            {switchState ?

                (<Button onClick={setOpen}  className="navbar-toggler-icon toggler-color mx-3 " ><img src={lines} alt={'line'} width={'95%'}/></Button>):


            (<>
                <Box sx={{ display: { xs: "none", sm: "inline-block"}}}>
                <Button onClick={handleOpen} style={{color: active ? "var(--green)" : "white"}}  endIcon={<KeyboardArrowDownIcon />}><Typography fontWeight={'bold'} >{t("header_section1_products")} </Typography></Button>
            </Box>
            </>)
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}  className={'mgDrop'}  >
                   <AppleModal close={handleClose}/>
                  
                </Box>
            </Modal>
        </div>
    );
}