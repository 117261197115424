import React, {useEffect} from 'react';
import "./extrapageCss/learnPrev.css";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import '../../Assets/css/learn.css';
import '../../Assets/css/learning-center-css1.css'
import search from '../../Assets/icons/search.png';
import Footer2 from "../../Components/Footer2";
import Footer3 from "../../Components/Footer3";
import ReactPlayer from "react-player";
import ActualVideos from "../A-actual-videos";

function LearnPreview() {

  return (
    <>
       <ActualVideos/>
    {/* <Footer /> */}
    </>
  )
}

export default LearnPreview
//<Footer />