import React, { useEffect } from 'react'
import "./extrapageCss/press.css";
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import carRacing from '../../Assets/img/car-racing.jpg'
import classes from '../../Assets/css/press.module.css';
import bitcoin from "../Extrapages/icons/WhatsApp Image 2023-05-16 at 11.30.36 PM.jpeg"
import Footer3 from "../../Components/Footer3";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

function Pressm() {
    const Content = () => {
        return (
            <>
                <div className={classes.content}>
                    <div className={classes.contentImage}>
                        {/* <img src="
                        https://th.bing.com/th/id/R.28f8ec1a9f39aabddc28cf0eb09e5399?rik=YWF0lqqhC8OUdQ&riu=http%3a%2f%2fwww.pixelstalk.net%2fwp-content%2fuploads%2f2016%2f08%2fRandom-Wallpaper-by-RedXen.jpg&ehk=ZZXGmhf%2baefAFr31O02SCza15sc%2fIqBvwpNBCKuAjbg%3d&risl=&pid=ImgRaw&r=0
                        
                        
                        " /> */}
                        <img src={carRacing}/>
                    </div>
                    <div className={classes.contentDesc}>
                        <div className={classes.descHead}>11-30-2020</div>
                        <div className={classes.descBody}>
                            description about the video,
                            text here may sometimes be very long 
                            since it will container description about
                            video
                        </div>
                        <div className={classes.descFoot}>VICTOI CM
                        <TrendingFlatIcon style={{marginLeft:"6px",fontWeight:"bold",fontSize:"2.5em"}} />
                        </div>

                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.head}>
                    <div className={classes.headText}>
                        Press
                    </div>
                    <p>
                        stuff about us in the pass
                    </p>
                </div>
                {/* -------contents------- */}
                <div className={classes.contents}>

                    <div className={classes.wrapContents}>
                        <Content />
                        <hr />
                        <Content />
                        <hr />
                    </div>
                    <div className={classes.wrapContents}>
                        <Content />
                        <hr />
                        <Content />
                        <hr />
                    </div>
                    <div className={classes.wrapContents}>
                        <Content />
                        <hr />
                        <Content />
                        <hr />
                    </div>
                    <div className={classes.wrapContents}>
                        <Content />
                        <hr />
                        <Content />
                        <hr />
                    </div>
                </div>
                {/* -------/contents------- */}
                <div className={classes.viewMore}>
                    <nav>viewMore</nav>

                </div>
                <div className={classes.pressContact}>
                    <div className={classes.pressContactText}>
                        <span>
                            Press Contact: PRESS@VICTOI.COM
                        </span>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Pressm
