import * as React from 'react';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import img from '../../Assets/img/404.gif'
import icon from '../../Assets/icons/TT.png'
import { color } from 'framer-motion';
import ScrollToTop from "react-scroll-to-top";

import classes from './blogs.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRightAlt, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button } from '@mui/base';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Blog2({ loadblog, blogArray, blog, navigation }) {

      return (
            <div key={blog.id + blog.src} className='col' onClick={() => { if (navigation == true) { loadblog(blogArray, blog) } }}>
                  <div className={classes.bcontainer + ' mx-auto my-4   bcontainer'}>
                        <img src={blog.image} loading='lazy' />
                        <div className={classes.boverlay + "  px-2 f2 flex-column d-flex align-items-center justify-content-between "}>

                              <div className={classes.blogDesc + "  mt-3 w-100 f01"}
                                    dangerouslySetInnerHTML={{ __html: blog.title }}
                              />
                              <div className={classes.blogDate + "  w-100 text-left text-start my-3  text-muted bold f01 "}>{blog.date}</div>                              </div>
                  </div>
            </div>
      )
}
export default function Blogs({ setfinishedLoading, siteLanguage }) {
const {t,i18n} = useTranslation()
      const url = "https://victoi.com.eternalcompanyltd.com/";
      // const url = 'http://localhost/apiv/'
      const navigate = useNavigate()
      const [saved, setsaved] = useState([])
      const [allblogs, setallBlogs] = React.useState([])
      const [general, setgeneral] = React.useState([])
      const [page, setPage] = React.useState(1);
      const [blogperpage] = React.useState(8)
      const [loading, setloading] = React.useState(false)
      const [blogid, setblogid] = React.useState()
      let location = undefined
      if (useLocation()?.state) {
            location = useLocation().state.blogid
      }
      /** Takes all blogs as parameters
       * - splits into various languages
       * - chains blogs in their various languages
       * - returns chain of blogs (heavy object)
       */
      function loadblog(result, blg) {
            let nlocation = undefined
            if (location) {
                  nlocation = location
            } else if (blg) {
                  nlocation = blg?.id
            }
            else {
                  return
            }


            //splittin into languages
            let languages = []
            let fresult = []
            let global = result.filter((blog) => {
                  if (languages.includes(blog.language)) {
                        return false
                  }
                  else {
                        languages.push(blog.language)
                        return true
                  }
            })
            languages.map((language) => {
                  let i = 0
                  let temp = result.filter((blog) => blog.language == language)
                  temp.map((blog) => {
                        let prev = temp[i - 1] ? true : undefined
                        let next = temp[i + 1] ? true : undefined
                        fresult.push({ ...blog, next: next, prev: prev })
                        i++
                  }
                  )
            })
            console.log("----- LANGUAGES -----")
            console.log(languages)
            console.log(fresult)
            for (let i = 0; i < fresult.length; i++) {
                  const element = fresult[i];
                  if (Number(nlocation) == Number(element.id)) {
                        // let next = fresult[i+1]?fresult[i+1]:undefined
                        // let prev = fresult[i-1]?fresult[i-1]:undefined
                        // alert('about to navigate')
                        navigate(("/blog/blogpost" + "?blog=" + fresult[i].id), { replace: false, state: { ...fresult[i], position: i, blogs: fresult } })
                  }
                  console.log("------")
                  console.log(Number(nlocation))
                  console.log(Number(element.id))

            }
            // alert("blogid: " + nlocation)


      }
      // new logic 
      const [newblogs, setnewblogs] = React.useState([])
      const [viewmorebtn, setviewmorebtn] = React.useState(false)

      function viewmore() {
            let resultArray = []
            let diff = newblogs.lenght - allblogs.length
            let end = (newblogs.length + blogperpage)
            for (let index = 0; index < end; index++) {
                  if (index < allblogs.length) {

                        const element = allblogs[index];
                        resultArray.push(element)
                  }
                  // for (let i = 0; i < diff; i++) {
                  //       const element = array[i];

                  // }

            }
            setnewblogs(resultArray)
      }
      function initBlog() {

            let initArray = ([])
            for (let i = 0; i < blogperpage; i++) {
                  if (allblogs[i]) {
                        const element = allblogs[i];
                        initArray.push(element)
                  }

            }
            setnewblogs(initArray)
      }
      React.useEffect(() => {
            initBlog()
            // viewmore()
      }, [allblogs])




      const handleChange = (event, value) => {
            window.scrollTo(0, 0)
            // console.log(event.type)
            setPage(value);
      };
      const scrollToTop = () => {
            document.querySelector("body").scroll(0, 0)
            // console.log("SCROLL")
      }
      // const allblogs = [
      //       {
      //             id:1,image: "https://i.pinimg.com/474x/19/f8/40/19f840d39f916eae102733645a5c7c06.jpg", title: "blog title1 and this is a very long title and i don't know what's its going to behave like", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:2,image: "https://i.pinimg.com/474x/38/5e/d7/385ed712e43c1db062f3d62f0de0b2a8.jpg", title: "blog title2", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:3,
      //             image: "https://www.pinterest.com/pin/969470257268857464/", title: "blog title3", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:4,image: "https://i.pinimg.com/474x/3f/d7/6d/3fd76d777b1ae60a79ff62695db9f38f.jpg", title: "blog title4", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:5,image: "https://i.pinimg.com/474x/89/4f/15/894f156accbcbff8a5a406a1ba504bb8.jpg", title: "blog title5", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:6,image: "https://i.pinimg.com/474x/cf/5e/4a/cf5e4aaf8dbbed0c485fa18eee5d7ad9.jpg", title: "blog title6", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:7,image: "https://i.pinimg.com/474x/90/d0/52/90d052214e946148b789a241f92f7c85.jpg", title: "blog title7", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:8,image: "https://i.pinimg.com/474x/c1/f2/7a/c1f27ac59d8593cf793e62ca237d4628.jpg", title: "blog title8", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:9,image: "https://i.pinimg.com/474x/03/ab/f9/03abf96c701077b05f008905118451c3.jpg", title: "blog title9", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       },
      //       {
      //             id:10,image: img, title: "blog title10", description: "this is description for blogs", date:"12 July 2024", links: [
      //                   { name: "twiter", icon: icon, link: "bla bla bla" },
      //                   { name: "facebook", icon: icon, link: "bla bla bla" },
      //                   { name: "instagram", icon: icon, link: "bla bla bla" },
      //                   { name: "tictoc", icon: icon, link: "bla bla bla" }
      //             ]
      //       }
      // ]

      // get current post
      const indexOfLastBlog = page * blogperpage;
      const indexOfFirstBlog = indexOfLastBlog - blogperpage
      const currentBlogs = allblogs.slice(indexOfFirstBlog, indexOfLastBlog)
      const count = Math.ceil(allblogs.length / blogperpage)


      const BlogComponent = ({ blogArray, blog }) => {
            return (

                  <div key={blog.id + blog.src} className='col mx-3 d-flex align-items-center justify-content-center  ' onClick={() => loadblog(blogArray, blog)}  >
                        <div className={classes.blogsContainer + '  my-4  w-100  '}>

                              <div className={classes.blogContainer + " mx-auto border "}  >

                                    <div className={classes.blogImg + " mx-auto w-100 "}>
                                          <img src={blog.image} loading='lazy' />
                                          <div className={classes.imgOverlayContainer + " px-4"}>
                                                <div className={classes.imgOverlay + "  my-2"}>
                                                      <div className={classes.blogDesc + "  w-100 f"}
                                                            dangerouslySetInnerHTML={{ __html: blog.title }}
                                                      />
                                                      <div className={classes.blogDate + "   align-text-left f01 "}>{blog.date}</div>

                                                      {/* <div className={classes.blogBody + "  px-3 my-4"}> */}

                                                      {/* <div className={classes.blogDesc + "  f00"} dangerouslySetInnerHTML={{ __html: blog.description }} /> */}

                                                      {/* <div className={classes.viewmore + ' my-1 align-text-left w-50 f00 fw-bold'} style={{ color: "var(--green)", borderRadius: "25px" }} onClick={() => navigate("/blog/" + blog.id, { replace: false, state: {...blog,next:undefined,prev:undefined} })}>View more<ArrowRightAlt /></div> */}
                                                      {/* <div className={classes.viewmore + ' mx-auto w-50 my-2 fw-bold'} style={{ color: "var(--green)", textAlign: "center", border: " 1px solid var(--green)", borderRadius: "25px", padding: " 10px" }} onClick={() => navigate("/blog/" + blog.id, { replace: false, state: {...blog,next:undefined,prev:undefined} })}>View more<ArrowRightAlt /></div> */}

                                                      {/* </div> */}
                                                </div>
                                          </div>

                                    </div>

                              </div>
                        </div>
                  </div>
            )
      }


      React.useEffect(() => {
            // setrefresh(false)
            let formData = new FormData()
            formData.append('endpoint', 'blogs')
            // console.log(file)
            let data = new URLSearchParams(formData).toString()
            // console.log(data)
            // setfileForm(formData)
            setloading(true)
            setfinishedLoading(true)


            //TESTING
            /** load saved data */
            if (localStorage.getItem('blogs')) {
                  let result = JSON.parse(localStorage.getItem('blogs'))
                  setfinishedLoading(true)
                  console.log("loaded from storage")
                  if (Array.isArray(result)) {
                        loadblog(result)
                        let nblogs = []
                        for (let index = 0; index < result.length; index++) {
                              const element = result[index];
                              if (result[index]?.language.toUpperCase() == siteLanguage.toUpperCase()) {
                                    nblogs.push(result[index])
                              } else {
                                    // console.clear()
                                    // console.log(element)
                              }

                        }
                        setallBlogs(nblogs)
                        setgeneral(result)
                  }
                  console.log(result)
                  console.log(siteLanguage)

                  setloading(false)
                  console.log(1)
                  setTimeout(() => {
                        console.log(2)
                        setfinishedLoading(false)
                  }, 5000);
            }
            fetch(
                  url,
                  {
                        method: "post",
                        body: formData
                  }
            ).then(response => response.json())
                  .then(
                        result => {

                              setfinishedLoading(true)
                              if (Array.isArray(result)) {
                                    result.reverse()
                                    loadblog(result)
                                    let nblogs = []
                                    for (let index = 0; index < result.length; index++) {
                                          const element = result[index];
                                          if (result[index]?.language.toUpperCase() == siteLanguage.toUpperCase()) {
                                                nblogs.push(result[index])
                                          } else {
                                                // console.clear()
                                                // console.log(element)
                                          }

                                    }
                                    localStorage.setItem('blogs',JSON.stringify(nblogs))
                                    setallBlogs(nblogs)
                                    setgeneral(result)
                              }
                              console.log(result)
                              console.log(siteLanguage)

                              setloading(false)
                              console.log(1)
                              setTimeout(() => {
                                    console.log(2)
                                    setfinishedLoading(false)
                              }, 5000);
                              // setdescription('')
                              // // setfile('')
                              // settitle('')
                        }
                  ).catch((error) => {
                        setloading(false)
                        console.log(error)
                        setfinishedLoading(false)
                  })

      }, [siteLanguage])
      return (

            <div className={" container-fluid d-flex align-items-center flex-column mbc f1  w-100 h-100"} style={{ marginTop: "100px", backgroundColor: "var(--bg-second)" ,marginBottom:"30vw"}}>

                  {loading ? <div className='d-flex px-1 w-100 h1' style={{ paddingTop: "100px", paddingBottom: "200px" }}>
                        <div>Loading</div>
                        <div className='mx-2'></div>
                        <div className={classes.dot1 + ' px-2 '}>.</div>

                        <div className='mx-2'></div>
                        <div className={classes.dot2 + ' px-2 '}>.</div>
                        <div className='mx-2'></div>
                        <div className={classes.dot3 + ' px-2 '}>.</div>
                  </div>
                        :
                        <>
                              <div className=' w-100 ' >
                                    <div className={classes.backContainer + '  d-flex w-100   justify-content-center  row  mx-auto   align-items-start justify-content-center py-3 my-4'}>
                                          {/* <div style={{}} className='row  mx-5 border align-items-start justify-content-center'> */}

                                          {newblogs.length <= 0 ? <h2>{siteLanguage == "ENGLISH" ? "No Available Blogs" : "Aucun blog disponible"}</h2> :
                                                <>
                                                      {true && newblogs?.map((blog => <Blog2 navigation={true} loadblog={loadblog} blogArray={newblogs} blog={blog} />))}
                                                </>
                                          }
                                    </div>
                              </div>
                              {/* </div> */}

                              {
                                    allblogs.length > blogperpage && <>
                                          {allblogs.length > newblogs.length ? <div className={classes.pagination + " d-flex  my-5"} onClick={viewmore}>
                                                <div variant="text" style={{ cursor: "pointer"}} >{t("view more")} <ExpandMore /></div>
                                          </div>
                                                :

                                                <div className={classes.pagination + " d-flex my-5"} onClick={() => { initBlog(); window.scrollTo(0, 0) }}>
                                                      <div variant="text" style={{ cursor: "pointer" }} >{t("view less")} <ExpandLess /></div>
                                                </div>
                                          }
                                    </>}
                              <div className={classes.scrollbar}>

                                    <ScrollToTop smooth />
                              </div>
                        </>}
            </div>


      )
      return (

            <div className={" container-fluid d-flex align-items-center flex-column   f1  w-100 h-100"} style={{ marginTop: "100px", backgroundColor: "var(--bg-second)" }}>

                  {loading ? <div className='d-flex px-5 w-100 h1' style={{ paddingTop: "100px", paddingBottom: "200px" }}>
                        <div>Loading</div>
                        <div className='mx-2'></div>
                        <div className={classes.dot1 + ' px-2 '}>.</div>

                        <div className='mx-2'></div>
                        <div className={classes.dot2 + ' px-2 '}>.</div>
                        <div className='mx-2'></div>
                        <div className={classes.dot3 + ' px-2 '}>.</div>
                  </div>
                        :
                        <>
                              <div className='container-fluid w-100 px-5'>
                                    <div className={classes.backContainer + '  d-flex w-100  justify-content-center  row  mx-auto   align-items-start justify-content-center py-3 my-4'}>
                                          {/* <div style={{}} className='row  mx-5 border align-items-start justify-content-center'> */}

                                          {currentBlogs.length <= 0 ? <h2>{siteLanguage == "ENGLISH" ? "No Available Blogs" : "Aucun blog disponible"}</h2> :
                                                <>
                                                      {true && currentBlogs?.map((blog => <BlogComponent blogArray={currentBlogs} blog={blog} />))}
                                                </>
                                          }
                                    </div>
                              </div>
                              {/* </div> */}

                              <div className={classes.pagination}>
                                    <Pagination count={count} page={page} onChange={handleChange} className={classes.pagination} />
                              </div>
                              <div className={classes.scrollbar}>

                                    <ScrollToTop smooth />
                              </div>
                        </>}
            </div>


      )
}