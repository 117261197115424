import React, {useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Card from '../Components/Card';
import SmallCard from '../Components/SmallCard';
import global from "../Assets/icons/GC.png";
import lowfees from "../Assets/icons/LF.png";
import liquidity from "../Assets/icons/L.png";
import LongText from '../Components/LongText';
import PageCards from '../Components/PageCards';
import stock from "../Assets/img/stock.png";
import PageText from '../Components/PageText';
import Footer2 from "../Components/Footer2";
import Footer3 from "../Components/Footer3";
import { useTranslation } from 'react-i18next';

function InvestStocks() {
  const {t} = useTranslation()

  return (
  <>
    <PageCards 
    img={stock}
    headtext={t("investstocks_1stsection_texttitle")}
    text={t("investstocks_1stsection_textbody")}/>
    <PageText 
    headtext={t("investstocks_2sdsection_texttitle")}
    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum "
    />   
      <SmallCard 
    Img1={global} 
    Img2={lowfees} 
    Img3={liquidity} 
    head1={t("investstocks_1stsmallbox_texttiltle")}
    head2={t("investstocks_2sdsmallbox_texttiltle")}
    head3={t("investstocks_3rdsmallbox_texttitle")}
    text1={t("investstocks_1stsmallbox_textbody")}
    text2={t("investstocks_2sdsmallbox_textbody")}
    text3={t("investstocks_3rdsmallbox_textbody")} />
  </>
  )
}

export default InvestStocks
