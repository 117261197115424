import "../Assets/css/A-actual-videos.css";
import racing from "../Assets/img/car-racing.jpg"
import Footer from "../Components/Footer";

export default function ActualVideos() {
    return (
        <div>
        <div className="A-actual-videos-container" style={{marginTop:'5%'}}>
            <div className="container-fluid A-main-video-container">
                <div className="container-fluid">
                    <div className="A-main-video container-fluid">
                        <iframe className="A-car-racing" src="https://www.youtube.com/embed/SSo_EIwHSd4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="A-caption1" style={{ backgroundColor: "#070a24" }}>
                    <div className="A-caption-head">MY FIRST INVESTMENT</div>
                    <div className="A-caption-txt">
                        We've put together information to help you make your first investment
                    </div>
                </div>
                {/* <div className="A-view-options container-fluid">
                        <span className="opt1">View more videos</span>
                        <span className="opt2">View all</span>
                    </div> */}
            </div>
            <div className="A-thumbnail-collection container-fluid">
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                    <div className="A-thumbnail">
                        <img src={racing} alt="car racing" className="car-racing" />
                        <div className="A-caption">
                            <div className="A-caption-head">What is blockchain</div>
                            <div className="A-caption-txt">
                                A masterclass on blockchain
                            </div>
                            <div className="A-caption-duration">Duration goes here</div>
                        </div>
                    </div>
                {/*WHEN THE USER CLICKS THE LINK BELOW BELOW, IT SHOULD NOT CHANGE THE OVERFLOW VALUE OF THE
                ABOVE CONTAINER, IT SHOULD SIMPLY CHANGE THE HEIGHT OF THE CONTAINER TO 100%, HIDE THE "view more" 
                link, AND CHANGE THE DISPLAY OF THE "Back to top" button TO BLOCK */}

                {/* WHEN THE USER CLICKS THE BUTTON BELOW, IT SHOULD SIMPLY CHANGE THE HEIGHT OF THE CONTAINER BACK TO 60vw,
                , HIDE THE "Back to top" button, AND DISPLAY THE "view more" link AGAIN */}
                <button type="button">Back to top</button>
            </div>
            <div className="view-more">View more videos</div>

        </div>

            </div>
    )
}