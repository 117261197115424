import React from 'react';
//import "../Assets/css/Victoi.css";
import { NavLink, Link } from 'react-router-dom';
import "../Assets/css/footer.css"
import yoga from "../Assets/img/yoga.png";
import apple from "../Assets/img/applestore.png";
import android from "../Assets/img/down.png";
import youtube from "../Assets/icons/YT.png";
import gram from "../Assets/icons/IG.png";
import telegram from "../Assets/icons/TG.png";
import linkedIn from "../Assets/icons/LI.png";
import snapchat from "../Assets/icons/SC.png";
import tiktok from "../Assets/icons/TIK.png";
import whatsapp from "../Assets/icons/W.png";
import victoi from "../Assets/icons/text.png"
import facebook from "../Assets/icons/FB.png";
import twitter from "../Assets/icons/T.png";
import Fade from 'react-reveal/Fade';
import Button from "@mui/material/Button";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { Cookies } from 'react-cookie';
import { Language } from '@mui/icons-material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';


function Footer({ cookies, siteLanguage, toogleLanguageSelect, setSitelanguage, saveLanguage }) {
  const { t, i18n } = useTranslation()

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
  }
  function totop() {
    window.scrollTo(0, 0)
  }
  // useEffect(()=>{
  //   alert(siteLanguage)
  // },[siteLanguage])
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  //   console.log(event.target.value)
  // };

  // const { t } = useTranslation();

  return (
    <div className={"footerMain"}>
      {/*this is the blue section*/}

      <div className="  bluefootm ">
        <div className="bluepartm   ">
          <div className="blue-imgm " >
            <Fade top>
              <img className={'footerImg'} src={yoga} />
            </Fade>
          </div>

          <div className="bluetextm   ">
            <Fade top>

              <img className={'imgSpecial'} src={yoga} />
            </Fade>
            <div className="textext ">
              <h1 style={{ fontWeight: 'bold' }}>{t("footer_blueSection_blueTitle")}</h1>
              <p>{t("footer_blueSection_blueBody")}</p>
            </div>
            <div className="bluelogo">
              <Fade up>
                <Link to="/Empty" onClick={() => window.location.href = "/Contact"} style={{ textDecoration: "none", color: "#050719" }}> <Button className={'requestm btn btn-color nav-link nav-item-color f02'} style={{ backgroundColor: 'var(--green)', color: '#050719', border: "1px solid black", fontWeight: 'bold', marginRight: "10px" }} >{t("request_early_access")}</Button></Link>
                <Link to="/Empty" onClick={() => window.location.href = "/Contact"} style={{ textDecoration: "none", color: "#050719" }}> <Button className={'requestm btn btn-color nav-link nav-item-color f02'} style={{ backgroundColor: 'var(--green)', color: '#050719', fontWeight: 'bold' }} >{t("request_early_access")}</Button></Link>

              </Fade>
            </div>
          </div>
        </div>
      </div>
      {/*this is the social media section*/}
      <div className="container-fluid   mt-4  w-100 mx-0 px-0">
        <div className='row  mx-auto reduce ' style={{ width: '86%' }}>
          <div className='col   '>
            <div className="iconsContainer  me-1 pe-2  ">
              <div className="iconLogo">
                <img src={victoi} alt="facebook" />

                <div className="connect2 text-muted f01"><div className="special ">{t("footer_connectSection_connextText")}</div></div>
              </div>
              <div className="connect1 text-muted f00"><span className=''>{t("footer_connectSection_connextText")} </span>
              </div>

              <div className="iconSemicons ">
                <a href={t("footer_socialmedia_facebookLink")} className="mx-auto" target='_blank'><img src={facebook} alt="facebook" /></a>
                <a href={t("footer_socialmedia_instagramLink")} className="mx-auto" target='_blank' ><img src={gram} alt="instagram" /></a>
                {/* <a href={t("footer_socialmedia_twiterLink")} className="mx-auto" target='_blank'> <img src={twitter} alt="twitter" /></a> */}
                {/* <a href={t("footer_socialmedia_linkedInLink")} className="mx-auto" target='_blank'><img src={linkedIn} alt="linkedin" /></a> */}
                <a href={t("footer_socialmedia_youtubeLink")} className="mx-auto" target='_blank'><img src={youtube} alt="youtube" /></a>
                <a href={t("footer_socialmedia_tiktokLink")} className="mx-auto" target='_blank'> <img src={tiktok} alt="tiktok" /></a>
                <a href={t("footer_socialmedia_telegram")} className="mx-auto" target='_blank'> <img src={telegram} alt="telegram" /></a>
                <a href={t("footer_socialmedia_snapchatLink")} className="mx-auto" target='_blank'> <img src={snapchat} alt="snap chat" /></a>
                <a href={t("footer_socialmedia_whatsapLink")} className="mx-auto" target='_blank'> <img src={whatsapp} alt="whatsapp" /></a>
              </div>
            </div>
          </div>
          <div className='col-3  additional-links px-0 d-flex justify-contents-center  '>
            <div className='my-1 mx-3  rs1 mt-4'>

              <div className='bold my-2   f00' style={{ fontWeight: "900" }}>Support</div>
              <Link to={'/Error'} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_learn")}</div></Link>
              <Link to={'/support'} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_faq")}</div></Link>
              {/* <Link to={'/Error'} onClick={() => window.location.href = "/Error"} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_press")}</div></Link> */}
              <Link to={'/blog'} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("BLOG")}</div></Link>
              {/* <Link to={'/Error'} onClick={() => window.location.href = "/Error"} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_career")}</div></Link> */}
              {/* <Link to={'/Error'} onClick={() => window.location.href = "/Error"} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_termsConditions")}</div></Link> */}
              <Link to={'/Contact'} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("footer_lastSection_contact")}</div></Link>
            </div>
          </div>
          <div className='col-3 px-0 additional-links mx-3  d-flex justify-contents-center '>
            <div className='my-4 rs1'>

              <div className='my-2 f00 bold' style={{ fontWeight: "900" }}>{t("ressources")}</div>
              <Link to={'/resources'} onClick={totop} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("KYC & AML Policy")}</div></Link>
              {/* <div className="f01 wh text-muted my-2 ">KYC & AML Polycy</div>resources */}
              <Link to={'/disclaimer'} onClick={totop} style={{ textDecoration: 'none', color: 'white' }}><div onClick={totop} className="f01 wh text-muted  my-2 ">{t("Disclaimer")}</div></Link>
              {/* <div className='f01 wh text-muted my-2 '>Disclaimer</div> */}
            </div>
          </div>
        </div>


        {/*this is the link part of the footer*/}
        {/* <hr className=''  style={{ display: "block",marginBottom:"5px", border: "1px solid", width: "85%", left: "7.5%", position: "relative" }} /> */}
        <hr className='' style={{ display: "block", marginBottom: "5px", border: "1px solid", width: "100%", position: "relative" }} />

        <div className='d-flex text-muted mt-3 mb-0 rs1 copyRight mx-auto'>
          <div className='copyRight1 wh f01 col-6'>
            {t("© Copy right 2023. All right reserved.")}
          </div>
          <div className='controlt d-flex ms-auto f01  w-100  justify-content-between  mb-3'>
            <Link to={"TermsConditions"} style={{ textDecoration: 'none ' }} className='text-muted textControle' onClick={window.scrollTo(0, 0)}> <div className='me-2 wh' >
              {t("Terms & Conditions")}
            </div></Link>
            {/* <div className='me-2 wh' >
            </div> */}
            <Link to={"privacypolicy"} style={{ textDecoration: 'none ' }} className='text-muted textControle' onClick={window.scrollTo(0, 0)}> <div className='me-2 wh' >
              {t("Privacy Policy")}
            </div></Link>
            {/* <div className='me-2 wh' >
            </div> */}
            <Link to={"cookiepolicy"} style={{ textDecoration: 'none ' }} className='text-muted textControle' onClick={window.scrollTo(0, 0)}> <div className='me-2 wh' >

              {t("Cookie Policy")}
            </div></Link>

          </div>
        </div>
        <div className="A-footer-links   m-0 f01 row  p-0">
          <div className="A-select-language   col-sm-1 d-flex align-items-center f01 my-0" style={{ color: "var(--green)", marginRight: "5px" }} onClick={() => { window.scrollTo(0, 0); toogleLanguageSelect() }}>
            <div className='language-select my-3' style={{ border: "1px solid var(--green)", borderRadius: "4px", padding: "1px", display: "flex", minWidth: "100px", justifyContent: "center", alignItems: "center" }}><Language style={{ color: "var(--green)", marginRight: "5px" }} /> {siteLanguage == "FRENCH" ? "Français".toUpperCase() : siteLanguage}</div>


          </div>

        </div>
        <div className='mb-1 mx-auto' style={{ width: "85%" }}>

          <div className=' copyRight2 f01 mx-auto text-muted   mt-5' style={{ marginLeft: "12%", border: '1px solid green' }}>
            {t("© Copy right 2023. All right reserved.")}
          </div>
        </div>
        {/* <div className='w-75 ms-4 my-5'>
            c Copy right 2023. All right reserved.
          </div> */}
      </div>

    </div>
  )
}

export default Footer;
