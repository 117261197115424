import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import InvestCrypto from './Pages/InvestCrypto';
import InvestStocks from './Pages/InvestStocks';
import OurFees from './Pages/OurFees';
import PayBills from './Pages/PayBills';
import TransferAndPayment from './Pages/TransferAndPayment';
import VictoiCard from './Pages/VictoiCard';
import Vidoes from './Pages/Vidoes';
import Press from "./Pages/Extrapages/Press";
import Contact from "./Pages/Extrapages/Contact";
import Support from "./Pages/Extrapages/Support";
import VideoPlaying from "./Pages/Extrapages/VideoPlaying";
import LearnPreview from "./Pages/Extrapages/LearnPreview";
import 'bootstrap/dist/css/bootstrap.css'
import { DropDown } from "./Components/DropDown";
import { CustomizedMenus } from "./Components/menu";
import LongText from './Components/LongText';
import TermsConditions from './Pages/Extrapages/TermsConditions';
import { ErrorPage } from "./Components/empty";
import { ScrollTop, ScrollToTop } from "./Components/ScrollTop";
import { useEffect, useState } from "react";
import ActualVideos from "./Pages/A-actual-videos";
import { Error404 } from "./Components/404";
import Pressm from './Pages/Extrapages/Pressm';
import VideoThumbnails from './Pages/Extrapages/A-video-thumbnails';
import LearningCenter from './Pages/Extrapages/LearningCenter'; import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Footer from './Components/Footer';
import Header from './Components/Header';
import LoaderComponent from './Components/loaderComponent/Loader';
import languageData from './data/languageData.json'
import { useCookies } from 'react-cookie';
import SelectLanguage from './Components/selectLanguageComponent/selectLanguage';
import Blogs from './Components/blogs/blogs';
import Blog from './Components/blogs/blog/blog';
import twillo from './Pages/3cce88c18abb792f2fa6e465700822ee'
import Kyc from './Pages/Extrapages/KYCcomponent/kyc';
import Disclaimer from './Pages/Extrapages/disclaimerComponent/disclaimer';
import Terms from './Pages/Extrapages/termsComponent/terms';
import Privacy from './Pages/Extrapages/privacy/privacy';
import Cookie from './Pages/Extrapages/cookieComponent/cookie';
import { success } from './Components/toaster/toaster';


// -------- CONSTANTS --------//
const url = "https://victoi.com.eternalcompanyltd.com/"
// const url = "http://localhost/apiv"
// -------- /CONSTANTS --------//


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      "ENGLISH": {
        "translation": {
          "Welcome to React": "this is bla trnaslation",
          "JOIN THE REVOLUTION": "JOIN THE REVOLUTION",
          "request_early_access": "REQUEST EARLY ACCESS",
          "FREE": "FREE",
          "PAID": "PAID",
          "Up to": "Up to",
          "Others": "Others",
          "fees": "fees",
          "our_story": "Our Story",
          "Victoi Help Center": "Help Center",
          "FAQ's, Guides & Support": "FAQ's, Guides & Support",
          "ressources": "Resources",
          "© Copy right 2023. All right reserved.": "© Copyright 2023. All right reserved.",
          "Terms & Conditions": "Terms & Conditions",
          "Privacy Policy": "Privacy Policy",
          "Cookie Policy": "Cookie Policy",
          "KYC & AML Policy": "KYC & AML Policy",
          "Disclaimer": "Disclaimer",
          "view more": "View More",
          "view less": "View Less"

        }
      },
      "FRENCH": {
        "translation": {
          "Welcome to React": "FRENCH BLABLABLA",
          "request_early_access": "DEMANDEZ UN ACCÈS ANTICIPÉ",
          "FREE": "GRATUIT",
          "PAID": "PAYÉ",
          "Up to": "Jusqu'à",
          "Others": "Autres",
          "fees": "frais",
          "our_story": "Notre Histoire",
          "Victoi Help Center": "Centre d'aide",
          "FAQ's, Guides & Support": "FAQ, guides et assistance",
          "ressources": "Ressources",
          "© Copy right 2023. All right reserved.": "© Copyright 2023. Tous doits réservée.",
          "Terms & Conditions": "Conditions générales",
          "Privacy Policy": "Politique de Confidentialité",
          "Cookie Policy": "Politique des Cookies",
          "KYC & AML Policy": "Politique de CVC et LBC ",
          "Disclaimer": "Avertissement",
          "view more": "Voir Plus",
          "view less": "Voir Moins"
        }
      }
    },
    fallbackLng: "ENGLISH",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });





function App() {
  const [siteLanguage, setSitelanguage] = useState('ENGLISH')
  const [finishedLoading, setfinishedLoading] = useState(true)
  const [cookies, setCookie] = useCookies(['language']);
  const [firstInit, setfirstInit] = useState(false)

  //this is to detect when translation breaks 
  const [subjectText, setsubjectText] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setsubjectText(t("homepage_section4_textTitle1"))
      if (subjectText.includes("_")) {
        window.location.reload()

      }
    }, 10000);
  }, [siteLanguage])
  /**
   * 
   * @param {languageselect} language 
   */
  const toogleLanguageSelect = () => {
    setfirstInit(!firstInit)
  }

  const saveLanguage = (language) => {
    setCookie('language', language, { path: '/' });
  };

  const getSystemLanguage = () => {
    // console.log("language")
    // console.log(siteLanguage)
    // console.log(cookies)
    // gets the system language as en or fr and changes it to ENGLISH and FRENCH
    var language = 'ENGLISH'

    if (cookies.language) {
      return cookies.language
    }
    else {

      var usrlang = navigator.language
        || navigator.userLanguage;
      if (usrlang.includes("en-") || usrlang.includes("en")) {
        language = "ENGLISH"
      }
      else if (usrlang.includes("fr-") || usrlang.includes("fr")) {
        language = "FRENCH"

      }
      else {

      }
    }
    saveLanguage(language)

    return language
  }

  const loadResource = async (language) => {
    var data = new URLSearchParams({ GET: null, items: JSON.stringify(["textId", "defaultText"]), requirements: JSON.stringify(["`language`='" + language.toString() + "'"]) }).toString()
    console.log(data)

    fetch(url, { method: 'post', body: data, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then(res => { return res.json() })
      .then(data => {
        console.log(Object.fromEntries(languageData[language]));
        i18n.addResourceBundle(language.toUpperCase(), "translation", Object.fromEntries(data)

        );
        if (!isArray(data)) {

          i18n.addResourceBundle(language.toUpperCase(), "translation", Object.fromEntries(languageData[language]))
        }
        /** -------- THIS CODE INITIALIZES  THE BASE LANGUAGE OF THE SITE when rescources are loaded ----------- ****/
        i18n.changeLanguage(getSystemLanguage());
        setTimeout(() => {
          setsubjectText(t("homepage_section4_textTitle1"))
          if (subjectText.includes("_")) { window.location.reload() }
        }, 10000);
        setTimeout(() => {

          setfinishedLoading(false);
        }, 1100);
        if (!cookies.language) {
          // setTimeout(() => {
          // setfirstInit(true)
          // }, 3000);
        }
        /** -------- /THIS CODE INITIALIZES THE BASE LANGUAGE OF THE SITE when rescources are loaded ----------- ****/
      })
      .catch(error => {
        i18n.addResourceBundle(language.toUpperCase(), "translation", Object.fromEntries(languageData[language]))
        console.log(languageData[language])
        setfinishedLoading(false)
        setTimeout(() => {
          setsubjectText(t("homepage_section4_textTitle1"))
          if (subjectText.includes("_")) { window.location.reload() }
        }, 10000);
      })

  }


  const { t, i18n } = useTranslation()

  //  const changeLanguageHandler = (lang) =>
  //  {
  //    i18n.changeLanguage(lang)
  //  }


  useEffect(() => {
    loadResource("ENGLISH")
    loadResource("FRENCH")
    setSitelanguage(getSystemLanguage)
    i18n.changeLanguage(getSystemLanguage())
    i18n.addResourceBundle("ENGLISH".toUpperCase(), "translation", Object.fromEntries(languageData["ENGLISH"]))
    i18n.addResourceBundle("FRENCH".toUpperCase(), "translation", Object.fromEntries(languageData["FRENCH"]))

  

  }, [])


  return (





    <>
      <Header siteLanguage={siteLanguage} />
      <Routes>

        <Route path="/" element={<Home siteLanguage={siteLanguage} setSitelanguage={setSitelanguage} />} />
        <Route path="/Drop" element={<CustomizedMenus />} />
        <Route path="/InvestCrypto" element={<InvestCrypto />} />
        <Route path="/InvestStocks" element={<InvestStocks />} />
        <Route path="/OurFees" element={<ErrorPage />} />
        {/* <Route path="/OurFees" element={<OurFees />} /> */}
        <Route path="/PayBills" element={<PayBills />} />
        <Route path="/TransferAndPayment" element={<TransferAndPayment />} />
        <Route path="/VictoiCard" element={<VictoiCard />} />
        <Route path="/Videos" element={<Vidoes />} />
        <Route path="/VideoPlaying" element={<VideoPlaying />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Press" element={<Pressm />} />
        <Route path="/LearnPreview" element={< LearningCenter />} />
        <Route path="/LearnPreview2" element={< LearnPreview />} />
        <Route path="/Support" element={<Support siteLanguage={siteLanguage} />} />
        <Route path="/TermsConditions" element={<Terms siteLanguage={siteLanguage} />} />
        <Route path="/Error" element={<ErrorPage />} />
        <Route path='/test' element={<ActualVideos />} />
        <Route path='/resources' element={<Kyc siteLanguage={siteLanguage} />} />
        <Route path='/disclaimer' element={<Disclaimer siteLanguage={siteLanguage} />} />
        <Route path='/privacypolicy' element={<Privacy siteLanguage={siteLanguage} />} />
        <Route path='/cookiepolicy' element={<Cookie siteLanguage={siteLanguage} />} />
      

        <Route path='/blog' element={<Blogs setfinishedLoading={setfinishedLoading} siteLanguage={siteLanguage} />} />
        <Route path='/blog/:bogId' element={<Blog />} />
        <Route path='/test2' element={<VideoThumbnails />} />

        <Route path="/*" element={<Error404 />} />

      </Routes>
      {
        firstInit &&
        <SelectLanguage setfirstInit={setfirstInit} setSitelanguage={setSitelanguage} saveLanguage={saveLanguage} cookies={cookies} />
      }
      {finishedLoading && <LoaderComponent />}
      <Footer toogleLanguageSelect={toogleLanguageSelect} cookies={cookies} siteLanguage={siteLanguage} setSitelanguage={setSitelanguage} saveLanguage={saveLanguage} />
    </>

  );
}
export default App;
